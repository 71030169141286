
<div *ngIf="course.IsElearning" class=" heading-font"
     pTooltip="Du har tillgång till kursen {{course.OnDemandAccessDays}} dagar från bokningstillfället sedan går inte kursen att starta.">
    <i class="fa-solid fa-globe"></i> E-learning - giltighetstid ({{course.OnDemandAccessDays}} dagar)
</div>
<div *ngIf="!course.IsElearning && course.IsOnDemand" class="cursor-help heading-font"
     pTooltip="Du har tillgång till kursen {{course.OnDemandAccessDays}} dagar från bokningstillfället sedan går inte kursen att starta.">
    <i class="fa-solid fa-globe"></i>
    Inspelad

    <i class="fa-regular fa-circle-question"></i>
</div>

<ng-container *ngIf="!course.IsElearning && !course.IsOnDemand">
    <div class="heading-font" *ngIf="events[0]">
        <i class="fa-regular fa-calendar-alt fa-fw mr-1" *ngIf="showDateIcon"></i>
        {{events[0]?.StartDate | date:format}}
        <i class="fa fa-clock fa-fw" *ngIf="showTimeIcon"></i>
        <ng-container *ngIf="showTimeIcon">{{events[0]?.StartDate | date: 'HH:mm'}}</ng-container>
        <ng-container *ngIf="showEndTime && events[0]?.EndDate != ''">-{{ events[0]?.EndDate | date: 'HH:mm'}}</ng-container>
    </div>
    <div *ngIf="showMoreAvailable && events.length > 0" class="mt-2 heading-font cursor-pointer" [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">
        <i class="fa fa-circle-info mr-1"></i>
        Fler tillgängliga tider
    </div>
</ng-container>
