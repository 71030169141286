import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from "@shared/animations/animations";
import { BaseWizComponent } from "@shared/components/base-wiz/base-wiz.component";
import { UserInterface } from '@shared/interfaces/user-interface';

import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
	selector: 'dd-step5-user',
	templateUrl: './step5-user.component.html',
	styles: [],
})
export class Step5UserComponent extends BaseWizComponent implements OnInit, OnDestroy {
	approveGdpr: any;
	approveEsf: any;
	displayGdpr: boolean = false;
	displayEsf: boolean = false;
	requireEsf: boolean = true;

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, private auth: AuthenticationService) {
		super(companiesService, config, dialogService, route, auth);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.requireEsf = this.auth.projectSettings['requireESF'];
	}

	submitStep(form: NgForm) {
		super.baseUserSubmitStep(form).then((result: any) => {
			if(this.ref) this.ref.close()
		});
	}

	ngOnDestroy() {

	}
}
