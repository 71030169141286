import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '@app/pages/analyzes/_question_types/base-question.component';
import { AnalysisService } from '@shared/services/analysis.service';
import { take } from 'rxjs/operators';

@Component({
	selector: 'dd-type-multiple',
	templateUrl: './type-multiple.component.html',
	styles: [`
		::ng-deep .p-checkbox-label { margin-right: 20px;margin-left: 0px; }
		.checkbox-holder {  }
		.checkbox-holder:hover { color:#84914D; }
		::ng-deep .checkbox-holder:hover .p-checkbox-box { border-color: #67314e; }
	`]
})
export class TypeMultipleComponent extends BaseQuestionComponent implements OnInit, OnDestroy {
	/* Multiple */
	topValues: any[];
	subValues: any[];
	values: any[] = [];
	@Input() selectedValues: string[] = [];
	activeIndex: any = [0];
	selectedFreetext: any = {};

	constructor(public analysisService: AnalysisService) {
		super(analysisService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.addSub(this.analysisService.steppedForward.pipe(take(1)).subscribe(() => {
			this.selectedValues = [];
		}));
		this.addSub(this.analysisService.steppedBack.pipe(take(1)).subscribe(() => {
			this.selectedValues = [];
		}));

		Object.entries(this.typeData.sub_value).forEach(([key, value]) => {
			this.values.push({label: key, children: (value as string).split(';')});
		});

		let findAnswer = this.findAnswer(this.question.questionID);

		if(findAnswer) {
			Object.entries(findAnswer).forEach(([key, value]) => {
				(value as any).forEach((item:string, index:any) => {

					if(item.includes('Fritext:'))
						this.selectedFreetext[key] = item.replace('Fritext: ', '');
					else
						this.selectedValues.push(key+':'+item);

				});
				if((value as any).length > 0) {
					let find = this.values.find((top) => top.label == key);
					if(find)
						find.hasSubSelection = true;
				}
			});
		}

		this.validate();
		this.addSub(this.analysisService.collectAnswer.pipe(take(1)).subscribe(() => {

			let answer = this.getAnswers();

 			this.analysisService.returnAnswerSource.next(this._questionObject(this.question.questionID, answer));
		}));
	}

	getAnswers()
	{
		let answer = {};

		this.selectedValues.map((i) => {
			let value = i.split(':');
			if(typeof answer[value[0]] !== 'object')
				answer[value[0]] = [];

			answer[value[0]].push(value[1]);
		});

		Object.keys(this.selectedFreetext).forEach((key,index) => {
			if(!answer[key])
				answer[key] = [];

			if(this.selectedFreetext[key].trim() != "")
				answer[key].push('Fritext: '+this.selectedFreetext[key]);
		});

		return answer;
	}

	validate() {
		this.setValidQuestion(this.selectedValues.length > 0 || Object.keys(this.selectedFreetext).length > 0);
	}

	ngOnDestroy(): void {

	}

	addFreeText($event: any, label: string) {

		this.selectedFreetext[label] = $event;
		this.validate();
		/*if(this.selectedFreetext[label])
			this.selectedFreetext[label] = $event;
		else
			this.selectedFreetext.push*/
		//console.error(this.selectedFreetext);
	}
}
