import { Component, Input } from '@angular/core';
import { ddCourseUtils } from '@shared/services/course.utils';
import { CoursesService } from '@shared/services/courses.service';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'dd-cancelation-expired-dialog',
  templateUrl: './cancelation-expired-dialog.component.html',
  styles: [
  ]
})
export class CancelationExpiredDialog {
	@Input() course: any;
	@Input() canCancel: boolean;
	hours: any;
	constructor(private coursesService: CoursesService, private confirmationService: ConfirmationService, public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
	}

	ngOnInit(): void {
		if (this.config?.data && this.config.data.course) {
			this.course = this.config.data.course;
			this.canCancel = this.config.data.canCancel;

			let findType = ddCourseUtils._getCourseTypeConfig(this.course);
			this.hours = findType.canCancelTimeBefore.time;
			console.error(findType);
		}
	}
}
