import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FadeIn } from '@shared/animations/animations';
import { CourseInterface } from '@shared/interfaces/course';
import { CoursesService } from '@shared/services/courses.service';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
	selector: 'dd-list-type-grid',
	templateUrl: './list-type-grid.component.html',
	styles: [`
		.list-type-grid-header {
			height:180px;
			background-position-x: center;
			background-position-y: top;
			background-repeat-x: no-repeat;
			background-repeat-y: no-repeat;
			background-size: cover;
		}
		:host ::ng-deep p-card.p-element { min-width: 100% }
		:host ::ng-deep .p-card {
			overflow:hidden;height:100%;
		}
		:host ::ng-deep .p-card .p-card-body {
			height:100%;
			display:flex;
			justify-content:space-between;
			flex-direction:column;
		}
		:host ::ng-deep .p-card .p-card-content {
			padding:0;
		}
		::ng-deep .p-dataview .p-dataview-content {
			background: none;
		}
	`],
	animations: [FadeIn]
})
export class ListTypeGridComponent {
	@Input() courses: CourseInterface[] = [];
	@Input() isLoading: boolean = false;
	@ViewChild('dt') table: Table;
	@Output() getData = new EventEmitter<boolean>();

	@Input() selectedSorting: any;

	constructor(private coursesService: CoursesService, private filterService: FilterService) {

	}
	ngOnInit() {
		//this.courses
	}

	triggerGetData() {
		this.getData.emit(true);
	}
}
