import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionNeededInterface } from '@shared/components/action-needed/action-needed.interface';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-action-needed',
	templateUrl: './action-needed.component.html',
	styles: [`
		::ng-deep .action-needed-dialog {
			font-size: 0.875rem;
		}

		::ng-deep .action-needed-dialog .p-dialog-content {
			padding: 0;
			background:transparent;
		}

		::ng-deep .action-needed-dialog .action-icon {
			font-size: 1.5rem
		}

		::ng-deep .action-needed-dialog .action-title {
			font-size: 1.3rem;
			font-weight: 300;
			line-height: 1.2;
		}

		::ng-deep .action-needed-dialog .action-content {
		}

		::ng-deep .action-needed-dialog .action-holder {
			border-radius: 0.25rem;
		}

		::ng-deep .action-needed-dialog.green .action-holder {
			background: #F7F5F3;
			color: #323540;
			border-left: 5px solid #EE2763;
		}

		::ng-deep .action-needed-side {
			position: absolute;
			right: 20px;
			bottom: 20px
		}

		::ng-deep .p-button.p-button-link .p-button-label {
			color:#323540
		}

	`]
})
export class ActionNeededComponent implements OnInit, OnDestroy {

	showModal: boolean = true;

	dialogData: ActionNeededInterface;

	constructor(private _sanitizer: DomSanitizer, public ref: DynamicDialogRef, public config: DynamicDialogConfig,) {
		this.dialogData = this.config.data;

		this.config.header = this.dialogData.title;

		this.config.closable = false;

		let colorScheme = this.dialogData.color || 'green';
		let width = this.dialogData.width || '400px';

		this.config.width = width;

		if (this.dialogData.type == 'side') {
			this.config.width = '550px';
			this.config.styleClass = 'action-needed-dialog action-needed-side ' + colorScheme;
			this.config.modal = false;
			this.config.showHeader = false;

		} else if (this.dialogData.type == 'cover') {
			this.config.width = '550px';
			this.config.styleClass = 'action-needed-dialog action-needed-cover ' + colorScheme;
			this.config.modal = true;
			this.config.showHeader = false;
		}
	}

	ngOnInit(): void {
		console.error('Dialog init', this.config, this.ref)

	}

	buttonClicked($event: any) {
		if ($event == 'close') {
			this.ref.close();
		} else if ($event == '') {

		}
	}

	ngOnDestroy() {

	}
}
