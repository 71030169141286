import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseWizComponent } from "@shared/components/base-wiz/base-wiz.component";
import { DisabilityOptions } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from '@core/auth';

@Component({
	selector: 'dd-step4-user',
	templateUrl: './step4-user.component.html',
	styles: [],
})
export class Step4UserComponent extends BaseWizComponent implements OnInit {
	selectedValues: string[] = [];
	showOther: boolean = false;
	other: string = '';
	options = DisabilityOptions;

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService) {
		super(companiesService, config, dialogService, route, authService);
	}

	evaluateValues(ev: any) {
		let value = ev.originalEvent.target.defaultValue;
		if (this.selectedValues.findIndex((i) => i == 'Nej, jag har inget behov av anpassning') > 0) {
			if(ev.checked == true)
				this.selectedValues = ['Nej, jag har inget behov av anpassning'];

		} else {
			let findIndex = this.selectedValues.findIndex((i) => i == 'Nej, jag har inget behov av anpassning');
			if (findIndex > -1 && this.selectedValues.length > 1) this.selectedValues.splice(findIndex, 1);
		}

		if(this.selectedValues.findIndex((i) => i == 'Ja, annat') > -1)
			this.showOther = true;
		else {
			this.showOther = false;
			this.other = '';
		}
	}

	submitStep(form: NgForm) {
		if (!form.valid) return;

		form.value.disabilitydetails = this.selectedValues;

		super.baseUserSubmitStep(form).then((result: any) => {
			if (result.error == 0) {
				this.user.disabilitydetails = [];
				this.user.disabilitydetails['options'] = this.selectedValues;
				this.user.disabilitydetails['other'] = this.other;
				this.companiesService.user = this.user;
				if(this.ref) this.ref.close()
			}
		});
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.companiesService.fetchUser().then((result) => {
			this.user = result;
			this.selectedValues = this.user.disabilitydetails['options'];
			this.other = this.user.disabilitydetails['other'];
		});
	}
}
