import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

/**
 * Example:
  <div ddGtagEvent draggable="true" gTrackOn="dragstart" gAction="product_dragged" [gParams]="{ event_label: 'Something draggable' }">
      draggable
  </div>
 */
@Directive({
  selector: '[ddGtagEvent]'
})
export class GtagEventDirective  implements AfterViewInit {
	/** HTML DOM Event */
	//@Input() gTrackOn?: string = 'click';
	/** event name for ga4 event */
	//@Input() gAction?: string;
	/** param for ga4 event */
	//@Input() gParams?: object;

	@Input() track: {
		on?: string,
		action?: string
		params?: object
	};

	constructor(
		private renderer: Renderer2,
		private el: ElementRef
	) {}

	/**
	 *  AfterViewInit
	 */
	ngAfterViewInit() {
		try {
			if (!!this.track.on && !!this.track.action) {
				this.renderer.listen(this.el.nativeElement, this.track.on, () => {
					/*this.ga4service.event(this.action! || this.trackOn!, {
						...this.params
					});*/
					if (this.track.params == null) {
						gtag("event", this.track.action! || this.track.on!);
					}
					else {
						gtag("event", this.track.action! || this.track.on!, this.track.params);
					}
					console.error('ddGtagEvent', this.track.action, this.track.on, this.track.params)
				});
			}
		} catch (err) {
			throw err;
		}
	}
}
