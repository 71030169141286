import { Component } from '@angular/core';

@Component({
  selector: 'dd-communication-kit',
  templateUrl: './communication-kit.component.html',
  styles: [
  ]
})
export class CommunicationKitComponent {

}
