import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'gradeToPercent'
})
export class GradeToPercentPipe implements PipeTransform {

	percentages:any = {
		1: '0%',
		2: '20%',
		3: '40%',
		4: '60%',
		5: '80%',
		6: '100%'
	};

	transform(value: number): string {
		return this.percentages[value];
	}
}
