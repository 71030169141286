/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({name: 'sortBy'})
export class SortByPipe implements PipeTransform {

	transform(value: any[], order:any = '', column: string = '', subColumn: string = ''): any[] {
		if (!value || order === '' || !order) {
			return value;
		} // no array
		if (value.length <= 1) {
			return value;
		} // array with only one item
		if (!column || column === '') {
			if (order === 'asc') {
				return value.sort()
			} else {
				return value.sort().reverse();
			}
		} // sort 1d array

		if(typeof value[0][column] == 'string' || subColumn == '') {
			return orderBy(value, [column], [order]);
		} else {
			if(value[0][column][subColumn])
				return orderBy(value, [(c) => c[column][subColumn]], [order]);
			else
				return orderBy(value, [(c) => c[column][0][subColumn]], [order]);
		}

	}
}
