<p-toast position="bottom-right"></p-toast>

<div class="container-fluid login-container h-100">

    <div class="login-box flex justify-content-center">

        <div class="card-box bg-white text-center border-round-big p-5 shadow d-flex my-3">
            <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
                <div [hidden]="!error || isLoading" class="alert alert-danger">Fel användarnamn eller lösenord.</div>
                <div class="form-group text-left">
                    <label class="d-block infotext pb-0">
                        <span>Användarnamn</span>
                        <input
                                [placeholder]="''"
                                class="form-control"
                                formControlName="username"
                                required
                                type="text"
                        />
                        <small
                                [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                                class="text-danger-login"

                        >
                            Användarnamn krävs för inloggning
                        </small>
                    </label>
                    <label class="d-block infotext">
                        <span>Lösenord</span>
                        <input
                                [placeholder]="''"
                                autocomplete="current-password"
                                class="form-control"
                                formControlName="password"
                                required
                                type="password"
                        />
                        <span hidden>Lösenord</span>
                        <small
                                [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                                class="text-danger-login"

                        >
                            Lösenord krävs för inloggning
                        </small>
                    </label>
                </div>
                <button [disabled]="loginForm.invalid || isLoading" class="p-button-rounded p-button-primary" type="submit" pButton>
                    <i [hidden]="!isLoading" class="fas fa-cog fa-spin mr-2"></i>
                    <span *ngIf="!isLoading">Logga in</span>
                    <span *ngIf="isLoading">Loggar in..</span>
                </button>

            </form>
        </div>
    </div>

</div>
