/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'sortByAccessRole'})
export class SortByAccessRolePipe implements PipeTransform {

	//sortOrder: string[] = ['Behörighet B (se och boka kurser för sig själv)', 'Behörighet A (samtliga funktioner)', '', '- Välj -']
	sortOrder: number[] = [7680, 7681, 7834, null]

	transform(value: any[]): any[] {
		return value.sort((a, b) => {
			return this.sortOrder.indexOf(a.accessRoleFieldId) - this.sortOrder.indexOf(b.accessRoleFieldId);
		});
	}
}
