import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { ActionNeededComponent } from '@shared/components/action-needed/action-needed.component';
import { ActionNeededInterface } from '@shared/components/action-needed/action-needed.interface';
import { ApproveTermsNeededComponent } from '@shared/components/approve-terms-needed/approve-terms-needed.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
	providedIn: 'root'
})
export class ActionsService {

	@Output() openActionNeededModal = new EventEmitter<ActionNeededInterface | null>();
	ref: DynamicDialogRef;

	constructor(public dialogService: DialogService, private router: Router) {
		this.openActionNeededModal.subscribe((actionData: ActionNeededInterface) => {
			console.error('ActionNeededInterface data', actionData);
			if(actionData === null) {
				if(this.ref)
					this.ref.destroy();
				return;
			}

			setTimeout(() => {
				if(!this.router.url.includes('wizard') && !this.router.url.includes('enkat') && !this.router.url.includes('analyser')) {

					if(actionData.content == 'ApproveTermsNeededComponent') {
						this.ref = this.dialogService.open(ApproveTermsNeededComponent, {
							data: actionData
						});
					} else {
						this.ref = this.dialogService.open(ActionNeededComponent, {
							data: actionData
						});
					}

					this.ref.onClose.subscribe((result: any) => {
						console.error('dialog was closed in itself', result);
					});
				}
			}, 1000);
		});
	}
}
