import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CompaniesService } from '@shared/services/companies.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
	selector: 'dd-persons-project-tag',
	templateUrl: './persons-project-tag.component.html',
	styles: []
})
export class PersonsProjectTagComponent {
	@Input() personID: number;
	@Input() projects: { fields: any[], list: any[] };
	@Input() showAddButton: boolean = false;
	@Input() split: boolean = false;
	isLoading: boolean = false;
	@Input() invert: boolean = false; // revert colors
	@Input() classes: string = 'course-type-tag px-1 py-1';
	@Input() extraClasses: string = '';
	@Output() isLoadingEmitter = new EventEmitter<boolean>();
	@ViewChild('op') overlayPanel: OverlayPanel;

	allowedProjects: any[] = [
		{
			'CustomFieldId': 6356,
			'CustomFieldAlternativeId': 7665,
			'CustomFieldAlternativeValue': 'PerFekt'
		},
		{
			'CustomFieldId': 6356,
			'CustomFieldAlternativeId': 7666,
			'CustomFieldAlternativeValue': 'Reform '
		}
	];
	selectabeProjects: any[] = [];
	addToProjects: any[] = [];

	constructor(public companiesService: CompaniesService) {
	}

	ngOnInit(): void {

		this.addToProjects = [];
		if (this.invert) {
			this.classes += ' bg-primaryDarker';
		}
		if(!this.projects.fields)
			this.projects.fields = [];

		this.setSelectableProjects();
	}

	setSelectableProjects()
	{
		this.selectabeProjects = [];

		this.allowedProjects.forEach((allowed: any) => {
			if (!this.projects.fields || this.projects.fields.findIndex((p: any) => p.CustomFieldAlternativeId == allowed.CustomFieldAlternativeId) == -1) {
				this.selectabeProjects.push(allowed);
			}
		});
	}

	saveProjects() {

		let projects = [...this.projects.fields, ...this.addToProjects];
		console.error(projects);
		this.isLoadingEmitter.emit(true);
		this.isLoading = true;

		this.companiesService
			.saveUserProjects(
				this.personID,
				projects.map((p: any) => {
					return p.CustomFieldAlternativeId
				})
			).then((result: any) => {
				if (result.error == false) {
					this.projects.fields = projects;
				}
			}).finally(() => {
				this.setSelectableProjects();

				this.overlayPanel.hide();
				this.isLoadingEmitter.emit(false);
				this.isLoading = false;
			});
	}

	reset() {
		this.addToProjects = [];
	}
}
