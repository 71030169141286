import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { BaseWizComponent } from '@shared/components/base-wiz/base-wiz.component';
import { InvitesInterface } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-invite-step3',
	templateUrl: './invite-step3.component.html',
	styles: [],
	animations: [FadeIn],
})
export class InviteStep3Component implements OnInit, OnDestroy {
	inModal: boolean = false;
	isLoading: any;
	@Input() user: InvitesInterface;
	@Output() stepValidated:EventEmitter<void> = new EventEmitter();
	@Output() userFailed:EventEmitter<boolean> = new EventEmitter();
	customerProjects: any[];
	activeIndex: number = 0;
	lastIndex: number = 3;

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService, private router: Router) {

	}

	ngOnInit(): void {
		this.customerProjects = this.user.customer.projects.list;
		console.error(this.customerProjects);

		//if(!this.hasProject('Reform'))
			//this.lastIndex = 3;
	}

	submitStep(form: NgForm) {


		this.isLoading = true;

		this.companiesService.acceptInvite(this.user)
			.then(async (data: any) => {
				let result = data;
				console.error(data)
				if (result && !data.error) {
					this.authService.loginFromBankID(result);

					let browseProject = localStorage.getItem('project.browse');
					if (browseProject == null) {
						let availableProjects = this.authService.getAvailableProjects();
						if (availableProjects.length > 1) {
							await this.router.navigate(['/projektväljare']);
							return;
						} else {
							localStorage.setItem('project.browse', availableProjects[0]);
						}
					}

					const returnUrl: string = this.route.snapshot.queryParamMap.get('returnUrl');

					if (returnUrl == null || this.authService.projectSettings['startPage'] != '/hem') {
						await this.router.navigate([this.authService.projectSettings['startPage']]);
					} else {
						console.error('route2', returnUrl)
						await this.router.navigate([returnUrl]);
					}

				} else {
					if (data.error) {
						//alert(data.error);
						if(data.constant == 'userExists' || data.constant == 'socialSecurityNotValid') {
							this.user.CivicRegistrationNumber = '';
							this.userFailed.emit(true);
						}
					}
				}
				this.isLoading = false;
			});
	}

	ngOnDestroy() {

	}

	hasProject(project: string) {
		//return this.customerProjects.includes(project);
		return this.user.project == project;
	}

	scrollTop() {
		console.error('scrollTop');
		window.scrollTo(0, 100)
	}
}
