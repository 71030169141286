import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { ClientConfigService } from '@shared/services/client-config.service';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
	selector: 'dd-project-selector',
	templateUrl: './project-selector.component.html',
	styles: [`
		.card-box {
			width: 330px;
		}
	`],
	animations: [FadeIn],
})
export class ProjectSelectorComponent {
	availableProjects: string[];
	selected: string = '';
	items: MenuItem[] = [];

	@Input() asMenu: boolean = false;
	redirectTo: string = '/hem';

	constructor(private authService: AuthenticationService, private configService: ClientConfigService, public route: ActivatedRoute, private router: Router) {
		this.availableProjects = this.authService.getAvailableProjects();
		this.selected = this.authService.getSelectedProject();
	}

	ngOnInit() {
		this.items.push({
			label: 'Välj projekt att se innehåll för',
			styleClass: 'p-menuitem-infotext top',
			escape: false,
			icon: 'pi pi-info-circle',
			command: () => {

			}
		});

		this.availableProjects.forEach((p) => {
			this.items.push({
				label: this.capitalize(p)+' - '+this.getProjectDesc(p),
				icon: '',
				styleClass: 'p-menuitem-seperator',
				command: () => {
					this.setSelected(p, true)
				}
			})
		});

		if(!this.asMenu) {
			const returnUrl: string = this.route.snapshot.paramMap.get('returnUrl');
console.error(returnUrl);
			if (returnUrl == null && !returnUrl.includes('projekt') && !returnUrl.includes('login')) {
				this.redirectTo = '/hem';
			} else {
				this.redirectTo = returnUrl;
			}
		}
	}

	capitalize(s: string)
	{
		return s[0].toUpperCase() + s.slice(1);
	}

	getProjectDesc(project: string): object|null {
		let find;
		if (find = this.configService.find('projects', 'constant', <string>project)) {
			return find['descSmall'];
		}
	}

	setSelected(project: string, reload: boolean = false) {
		project = project.toLowerCase();
		this.selected = project;
		this.authService.setSelectedProject(project);

		/**
		 * Om man är inne på en kurs och byter projekt, skrickas man tillbaka till projektet eftersom
		 * kursen inte finns i det P man byter till.
		 * Lösning är att först skickas till index sidan och Sen reloada
		 */
		if(this.router.url.includes('aktiviteter/visa')) {
			this.router.navigateByUrl('/aktiviteter').then(() => document.location.reload());
			return;
		}

		if(reload)
			document.location.reload();
		else {
			setTimeout(() =>  {
				if(this.redirectTo) {
					this.router.navigateByUrl(this.redirectTo);
				} else
					this.router.navigate(['/hem']);
			}, 750);
		}
	}
}
