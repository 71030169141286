import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@core/auth';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';


/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
	providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {

	constructor(private authService: AuthenticationService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(request.body && request.body.get) {
			if (request.body.get('subaction') !== null)
				console.error(':: SubAction: ', request.body.get('subaction'));
		}
		if (!/^(http|https):/i.test(request.url)) {
			//request = request.clone({ url: environment.serverUrl + request.url });
		}
		let selectedProject = this.authService.getSelectedProject();
		request = request.clone({
			headers: request.headers.append('Current-Customer-Project', selectedProject !== null ? selectedProject : '')
		});
		return next.handle(request);
	}
}
