/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode, ErrorHandler } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";


if (environment.production) {
	/*Sentry.init({
		dsn: "https://2d94a77500b64d91b8aec8665f93d11b@o111161.ingest.sentry.io/5594224",
		autoSessionTracking: true,
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: ["localhost", "https://portal.affarskompetens.se/wp-admin/admin-ajax.php"],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,

	});
	Sentry.setContext('App version', {
		version: environment.version
	});*/
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
	hmrBootstrap(module, bootstrap);
} else {
	bootstrap().then(() => {
		const fn = () => {};

		if ('serviceWorker' in navigator && environment.production) {
			//navigator.serviceWorker.register('ngsw-worker.js');
		}
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					registration.unregister()
				}
			})
		}
	}).catch((err) => console.error(err));
}
