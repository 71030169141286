import { Component, Input, OnInit } from '@angular/core';
import { CourseEventInterface, CourseInterface } from '@shared/interfaces/course';

@Component({
	selector: 'dd-course-date',
	templateUrl: './course-date.component.html',
	styles: []
})
export class CourseDateComponent implements OnInit {

	@Input() format: string;
	@Input() showWeekDay: boolean = false;
	@Input() showDateIcon: boolean = true;
	@Input() showMoreAvailable: boolean = false;
	@Input() showEndTime: boolean = false;
	@Input() showTimeIcon: boolean = false;
	@Input() course: CourseInterface;

	events: CourseEventInterface[];

	ngOnInit() {
		// EEEE = torsdag
		let format: string[] = [];
		if(this.showWeekDay)
			format.push('EEEE');

		format.push('d MMM YYY')

		if(!this.showTimeIcon)
			format.push('kl. HH:mm')

		this.format = format.join(' ');

		this.events = this.course.Events.filter((i) => i.IsFuture);
	}
}
