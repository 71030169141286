<form action="" method="post" #form="ngForm" (ngSubmit)="requestPassword(form)">
    <div class="row justify-content-center">

        <div class="col-12 pt-4 mt-1 justify-content-center">
            <span class="p-float-label mb-4">
                <input ngModel name="p46_ca_password_socialsecurity" class="w-100 bg-white" pInputText type="text" required minlength="10" maxlength="12" pattern="^(\d{10}|\d{12})$" />

                <label for="">Personnummer</label>
            </span>
            <span class="p-float-label mb-4">
                <input ngModel name="p46_ca_password_email" class="w-100 bg-white" pInputText type="email" email required/>
                <label for="">E-post</label>
            </span>

        </div>
        <div class="col-12 pb-4">
            <button [disabled]="!form.valid || isLoading" class="w-100 p-button-lg btn-fw-700 mb-3" label="Skicka nytt lösenord" pButton type="submit">
                <i *ngIf="isLoading" class="fas fa-cog fa-spin position-absolute"></i>
            </button>

            <p-message severity="info" text="Lösenordet skickas ut via mail om en användare hittas." styleClass="py-1 w-100 text-sm"></p-message>
        </div>
    </div>
</form>
