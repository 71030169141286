import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { InvitesInterface } from '@shared/interfaces/user-interface';
import { ClientConfigService } from '@shared/services/client-config.service';
import { CompaniesService } from '@shared/services/companies.service';
import Personnummer from 'personnummer';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-invite-step1',
	templateUrl: './invite-step1.component.html',
	styles: [],
	animations: [FadeIn],
})
export class InviteStep1Component implements OnInit, OnDestroy {
	inModal: boolean = false;
	isLoading: any;
	@Input() user: InvitesInterface;
	@Output() stepValidated: EventEmitter<void> = new EventEmitter();
	personAccessPositionList: any;

	///^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/
	validPsn: any = null;

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService, public configService: ClientConfigService) {
		this.personAccessPositionList = configService.config.eduadmin.fields.personAccessPositionList;
	}

	ngOnInit(): void {

	}

	submitStep(form: NgForm) {
		console.error(this.user)
		this.stepValidated.next();
	}

	ngOnDestroy() {

	}

	testPsn() {
		//this.validPsn = /^\d{6,8}[-|(\s)]{0,1}\d{4}$/.test(this.user.CivicRegistrationNumber);
		this.validPsn = Personnummer.valid(this.user.CivicRegistrationNumber);
	}
}
