<div class="pt-3 pr-3 pl-3 pb-2 action-holder" *ngIf="dialogData.type == 'side'">
    <div *ngIf="!dialogData.force"></div>

    <div class="row">
        <div class="col-12">
            <div class="row p-0">
                <div class="col-12 action-title pb-2 heading-font">
                    <i class="pi pi-chart-bar action-icon mr-1"></i> {{dialogData.title}}
                </div>
                <div class="col-12 action-content" [innerHTML]="dialogData.content"></div>
            </div>
        </div>
    </div>

    <div class="row mt-2 pt-1" *ngIf="dialogData.buttons && dialogData.buttons.length > 0">
        <div class="col-12 flex justify-content-between">
            <ng-container *ngFor="let button of dialogData.buttons">
                <dd-action-needed-button [btn]="button" (onClick)="buttonClicked($event)"></dd-action-needed-button>
            </ng-container>
        </div>
    </div>

</div>

<div class="pt-3 pr-3 pl-3 pb-2 action-holder" *ngIf="dialogData.type == 'cover'">
    <div *ngIf="!dialogData.force"></div>

    <div class="row">
        <div class="col-12">
            <div class="row p-0">
                <div class="col-12 action-title pb-2 heading-font">
                    <i class="fas fa-info-circle action-icon mr-1"></i> {{dialogData.title}}
                </div>
                <div class="col-12 action-content" [innerHTML]="dialogData.content"></div>
            </div>
        </div>
    </div>

    <div class="row mt-2 pt-1" *ngIf="dialogData.buttons && dialogData.buttons.length > 0">
        <div class="col-12 flex justify-content-between">
            <ng-container *ngFor="let button of dialogData.buttons">
                <dd-action-needed-button [btn]="button" (onClick)="buttonClicked($event)"></dd-action-needed-button>
            </ng-container>
        </div>
    </div>

</div>

