<div *ngIf="!asMenu"
        @FadeIn
        class="md:fixed p-4 justify-content-center min-vw-100 min-vh-100 bg-grey d-flex align-items-center start-screen"
>
    <div class="row">
        <div class="col-xs-5">

            <h1 class="text-center mb-4 display-3 font-bold">Välkommen till <br/>Affärskompetens</h1>
            <p class="text-center mb-3 display-5">
                Välj vilket projekt du vill besöka:
            </p>
            <div class="mt-5 gap-4 d-flex flex-wrap justify-content-center">
                <div class="card-box text-center border-round-big px-5 py-4 shadow cursor-pointer {{project}}" *ngFor="let project of availableProjects"
                     (click)="setSelected(project)" [ngClass]="{'bg-primaryColor text-white': project == selected, 'bg-white': project != selected}">
                    <h2 class="mb-1 capitalize">{{project}}</h2>
                    <p class="mb-1">{{ getProjectDesc(project) }}</p>
                </div>
            </div>

            <!--<div class="text-center mt-5">
                <button pButton pRipple
                        type="button" label="Gå vidare" icon="fa-solid fa-arrow-right-long" iconPos="right"
                        class="mt-3 p-button p-button-lg p-button-rounded p-button-primary {{selected}}"
                        [routerLink]="[redirectTo]" [disabled]="!selected">
                </button>
            </div>-->
        </div>
    </div>
</div>

<div *ngIf="asMenu">
    <p-menu #menu [model]="items" [popup]="true" appendTo="body" styleClass="text-md"></p-menu>

    <button (click)="menu.toggle($event)" class="btn btn-dropdown text-sm btn-primary bg-primaryColor border-0 text-shadow px-3" type="button">
        <span>Projekt:</span>
        <span class="font-semibold pr-1"><span class="capitalize">{{ selected }}</span> <span class="d-none d-md-inline">- {{getProjectDesc(selected)}}</span></span>
        <i class="fa-solid fa-arrow-down-long" *ngIf="!menu.visible"></i>
        <i class="fa-solid fa-arrow-up-long" *ngIf="menu.visible"></i>
    </button>
</div>
