import { Component, OnInit } from '@angular/core';


export interface FaqItems {
	id: number,
	heading: string,
	content: string
}

@Component({
	selector: 'dd-faq',
	templateUrl: './faq.component.html',
	styles: []
})
export class FaqComponent implements OnInit {

	items: FaqItems[] = [
		{
			id: 1,
			heading: 'Varför kan jag inte anmäla mig till en aktivitet?',
			content: '<p>Oftast beror det p&aring; att du &auml;r inlagd som &rdquo;medarbetare&rdquo;. Endast f&ouml;retagsledare kan g&ouml;ra bokningar, b&aring;de till sig sj&auml;lva och sina medarbetare. Detta f&ouml;r att n&aring;gon p&aring; f&ouml;retagen ska ha &ouml;vergripande koll och s&aring; att ni tillsammans f&aring;r besluta om vem och vilka aktiviteter som ni ska delta i.&nbsp;<br />Det &auml;r ocks&aring; ett krav att f&ouml;retaget har gjort klart sin behovskartl&auml;ggning innan m&ouml;jligheten att boka in sig p&aring; kurser l&aring;ses upp.</p>'
		},
		{
			id: 1,
			heading: 'Vad gör jag om jag stöter på tekniska problem i här inne på Mina sidor?',
			content: '<p>Prova med dessa nedanst&aring;ende l&ouml;sningar och se om det fungerar b&auml;ttre. Prova i den ordning som vi h&auml;r f&ouml;resl&aring;r.&nbsp;</p>\n' +
				'<ol>\n' +
				'<li> Logga ut och st&auml;ng ner webbl&auml;sare (det f&ouml;nstret du surfar i). Prova igen om en stund.</li>\n' +
				'<li> Anv&auml;nd en annan webbl&auml;sare (Google Crome eller Microsoft Edge t ex). Har du inte den installerad s&aring; beh&ouml;ver du ladda ner den. https://www.google.com/chrome/ https://www.microsoft.com/sv-se/edge</li>\n' +
				'<li> &Ouml;ppna ett privat f&ouml;nster (kallas ocks&aring; InPrivate eller Inkognito) - beroende p&aring; vilken webbl&auml;sare du anv&auml;nder. Logga in som vanligt.</li>\n' +
				'<li> &Auml;r problemet fortfarande inte l&ouml;st s&aring; mejlar du oss p&aring; <a href="mailto:support@affarskompetens.se">support@affarskompetens.se</a> eller ring 010-641 01 10.</li>\n' +
				'</ol>'
		},
		{
			id: 1,
			heading: 'Hur många kurser får man gå?',
			content: '<p>Det beror p&aring; hur m&aring;nga personer som &auml;r v&auml;ljer att vara aktiva och faktiskt g&aring;r kurser i projektet. Uppskattningsvis kan varje medarbetare g&aring; 2-6 st kurser under projektet &ndash; om alla g&aring;r lika mycket. Det vanligaste &auml;r att endast ett f&aring;tal medarbetare ur varje f&ouml;retag faktiskt g&aring;r utbildningar - det betyder ju att ni som &auml;r aktiva kan g&aring; fler kurser...&nbsp;</p>'
		},
		{
			id: 1,
			heading: 'Var hittar jag en gammal närvarorapport som jag har glömt att signera?',
			content: '<p>Vi brukar skicka ut p&aring;minnelser, men g&aring; g&auml;rna in och titta om du har missat n&aring;gon. Du hittar dem h&auml;r: <a href="https://aktivitet.esf.se./pages/login.html" target="_blank" rel="noreferrer noopener">https://aktivitet.esf.se./pages/login.html</a></p>'
		},
		{
			id: 1,
			heading: 'Kommer samma kurs erbjudas flera gånger?',
			content: '<p>De kurser som &auml;r mest popul&auml;ra kommer erbjudas igen, vissa erbjuds flera g&aring;nger per termin. Men det &auml;r klokt att boka s&aring; snart du ser att en kurs som du beh&ouml;ver finns i kalendariet, vi kan inte s&auml;kert lova att den kommer igen.</p>'
		},
		{
			id: 1,
			heading: 'Varför behövs närvarorapporter?',
			content: '<p>Det &auml;r ett krav fr&aring;n Europeiska Socialfonden (ESF) som finansierar projektets insatser. Det beh&ouml;vs intyg p&aring; att n&aring;gra deltagare verkligen g&aring;r p&aring; v&aring;ra kurser. Vi skickar dessa n&auml;rvarorapporter till er via sms eller mejl efter varje genomf&ouml;rd aktivitet. Det &auml;r av allra h&ouml;gsta vikt att ni signerar dessa s&aring; fort som m&ouml;jligt. Du signerar med hj&auml;lp av Mobilt Bank-ID. Utan signerade n&auml;rvarorapporter kan det bli s&aring; att vi inte f&aring;r fortsatt finansiering, och kan ej forts&auml;tta erbjuda er kurser i Digilo.</p>'
		},
		{
			id: 1,
			heading: 'Finns det några krav eller förväntningar på er som är med? ',
			content: 'När ESF finansierar olika utbildningsinsatser så förväntar de sig att få ta del av lärdomar och resultat. För er som deltar i Digilo innebär det bland annat att ni efter varje utbildning får svara på några snabba utvärderingsfrågor. Och ni behöver också signera en närvarorapport.'
		},
		{
			id: 1,
			heading: 'Finns det någon gräns för hur många aktiviteter man få delta i? ',
			content: '<p>Det &auml;r sv&aring;rt att svara p&aring; exakt. Om alla deltagare &auml;r lika aktiva s&aring; kommer vi kunna erbjuda 2-3 utbildningar per&nbsp;medarbetare samt 1 inspirationsf&ouml;rel&auml;sning per medarbetare.&nbsp;</p>\n' +
				'<p>Men om alla deltagare inte &auml;r lika aktiva (vilket vi &auml;r ganska s&auml;kra p&aring;) s&aring; kanske varje aktiv person ist&auml;llet kan f&aring; g&aring; 5-6&nbsp;st&nbsp;utbildningar.&nbsp;</p>\n' +
				'<p>Alla f&ouml;retagsledare/&auml;gare kommer f&aring; minst 2 individuella m&ouml;ten med f&ouml;retagsutvecklare.&nbsp;</p>'
		},
		{
			id: 1,
			heading: 'Vilka utbildningar kommer erbjudas? ',
			content: '<p>Exakt vilka utbildningar det blir kommer tas fram gemensamt utifr&aring;n alla deltagande f&ouml;retags behov, fokus ligger p&aring; digitala verktyg/arbetss&auml;tt som effektiviserar och underl&auml;ttar vardagen f&ouml;r er som sm&aring;f&ouml;retagare och f&ouml;r era medarbetare. Gissningsvis kan det vara utbildningar inom till exempel:&nbsp;</p>\n' +
				'<ul>\n' +
				'<li>Microsoft 365 (tidigare Office 365), t ex Excel&nbsp;&nbsp;</li>\n' +
				'<li>Spara och dela dokument online/i molnet</li>\n' +
				'<li>G&ouml;r ekonomihanteringen mer digital, t ex mejla fakturor</li>\n' +
				'<li>M&ouml;testeknik och bra struktur f&ouml;r digitala m&ouml;ten</li>\n' +
				'<li>Olika verktyg f&ouml;r digitala m&ouml;ten, t ex Zoom, Teams.</li>\n' +
				'<li>Att leda i f&ouml;r&auml;ndring och eller p&aring; distans&nbsp;</li>\n' +
				'<li>Marknadsf&ouml;ring via sociala medier</li>\n' +
				'</ul>'
		},
		{
			id: 1,
			heading: 'Kommer utbildningarna vara fysiska eller digitala? ',
			content: 'På grund av pandemin blir alla utbildningar under våren 2021 digitala. Vi hoppas längre fram att kunna ha en variation, dvs både fysiska och digitala utbildningar (som var ursprungsplanen). Det finns så många positiva effekter när vi träffas ”på riktigt”, till exempel blir det ett helt annat nätverkande.'
		},
		{
			id: 1,
			heading: 'Var kommer de fysiska utbildningar att äga rum? ',
			content: 'På olika platser i Dalarna och Gävleborg. Vi kommer att ha några utbildningsplatser som är strategiskt utvalda utifrån tillgänglighet. Målet är att ingen ska ha mer än en timmes bilresa, oavsett var i regionen man bor.'
		},
		{
			id: 1,
			heading: 'Hur avbokar jag mig från en kurs?',
			content: '<p>Om du beh&ouml;ver avboka dig mer &auml;n 72 tim (3 dygn) f&ouml;re en kurs s&aring; g&ouml;r du det enkelt sj&auml;lv inne p&aring; Mina sidor/utbildningsportalen. Om du m&aring;ste avboka senare s&aring; ska du kontakta oss p&aring; support@affarskompetens.se.&nbsp;</p>\n' +
				'<p>Vid avbokning senare &auml;n 24 timmar innan kursstart eller vid &rdquo;no show&rdquo; kommer vi debitera dig 500 kr.&nbsp;</p>'
		},
		{
			id: 1,
			heading: 'Hur kommer utbildningarna att gå till? ',
			content: '<p>Utbildningarna kommer vara korta (3-6&nbsp;tim), konkreta och anpassade efter ditt, dina medarbetares och andra f&ouml;retagares behov. Vi kommer inte att jobba med n&aring;gra l&aring;nga program, ist&auml;llet ska man kunna v&auml;lja just de utbildningar som man beh&ouml;ver och kan ha nytta av. T&auml;nk er ett sm&ouml;rg&aring;sbord d&auml;r man v&auml;ljer vad man helst vill ha.&nbsp;<br />Vi l&auml;gger stor vikt vid att alla utbildningar ska kunna kopplas till verkligheten och att ni ska ha anv&auml;ndning av kunskaperna. L&aring;nga teoripass &auml;r inget vi efterstr&auml;var.</p>'
		},
		{
			id: 1,
			heading: 'Hur får ni reda på vilka utbildningar som finns och när de äger rum? ',
			content: '<p>Alla som ing&aring;r i projektet kommer f&aring; en personlig inloggning till v&aring;r &rdquo;portal&rdquo;, d&auml;r vi l&auml;gger upp information om kommande utbildningar och det &auml;r ocks&aring; d&auml;r bokningar sker. D&auml;r kommer ni efter&aring;t ocks&aring; hitta utbildningsmaterialet.&nbsp;<br/>Vi kommer prim&auml;rt att h&aring;lla kontakt med er via mejl, men &auml;ven telefon och sms d&aring; och d&aring;.&nbsp;</p>'
		},
		{
			id: 1,
			heading: 'Måste man vara IT-van för att ingå i projektet? ',
			content: 'Nej, inte särskilt, men ni behöver kunna hantera mejl. Eftersom våra digitala utbildningar kommer att ske via Teams kommer vi i initialt också erbjuda utbildning inom det.'
		},
		{
			id: 1,
			heading: 'Vilka är utbildare i projektet?',
			content: '<p>Vi har upphandlat externa utbildare som &auml;r specialister inom sitt omr&aring;de. H&auml;r kan ni se en presentation av v&aring;ra utbildare: <a href="https://affarskompetens.se/vara-utbildare/" target="_blank" rel="noreferrer noopener">https://affarskompetens.se/vara-utbildare/</a></p>'
		},
		{
			id: 1,
			heading: 'Ingår något kursmaterial? ',
			content: '<p>Ja, efter de allra flesta kurstillf&auml;llen finns kursmaterial tillg&auml;ngligt digitalt h&auml;r inne p&aring; Mina sidor f&ouml;r alla som deltagit. Utbildaren laddar upp materialet efter kursen, ni hittar det under &rdquo;Kursmaterial&rdquo;.</p>'
		},
		{
			id: 1,
			heading: 'Vem betalar för Digilo? ',
			content: '<p>Europeiska Socialfonden (ESF) st&aring;r f&ouml;r den st&ouml;rsta delen av finansieringen, 13 miljoner kr. Den totala budgeten &auml;r 17 miljoner kr.&nbsp;</p>\n' +
				'<p>ESF har &auml;ven satt ett v&auml;rde&nbsp;(schablonbelopp)&nbsp;p&aring;&nbsp;de&nbsp;timmar som&nbsp;ni&nbsp;deltagare&nbsp;l&auml;gger i projektet och ser det som en delfinansiering. Och&nbsp;ni&nbsp;f&ouml;retag som ing&aring;r betalar en deltagaravgift p&aring; 2000 kronor.&nbsp;</p>'
		},
		{
			id: 1,
			heading: 'Lägger projektets samarbetspartners in pengar i Digilo?',
			content: '<p>Digilo-projektet har tre samarbetspartners, det &auml;r:&nbsp;<br/>F&ouml;retagarna i G&auml;vleborg och Dalarna, F&ouml;retagarna i Sandviken och Sandvikens Kommun N&auml;ringslivskontoret. Dessa l&auml;gger inte in pengar i projektet, men de l&auml;gger in lite arbetstid. De bidrar med kompetenser och erfarenheter. Representanter fr&aring;n dessa organisationer ing&aring;r i projektets styrgrupp och&nbsp;Digilos&nbsp;projektledare har l&ouml;pande kontakt med dem.</p>'
		},
		{
			id: 1,
			heading: 'Varför behöver ni veta mitt personnummer?',
			content: '<p>Detta projekt f&aring;r medel fr&aring;n Europeiska socialfonden (ESF), de betalar kurserna. Man f&ouml;r statistik p&aring; skattemedel p&aring; personniv&aring;, d&auml;rf&ouml;r kr&auml;vs personnummer. L&auml;s mer: <a href="https://www.esf.se/Vara-fonder/Socialfonden1/Genomfora/Deltagarsidan/" target="_blank" rel="noreferrer noopener">https://www.esf.se/Vara-fonder/Socialfonden1/Genomfora/Deltagarsidan/</a></p>'
		}
	];

	groups = [
		{
			heading: 'Aktiviteter',
			items: [
				{
					heading: 'Jag är anmäld men har fått förhinder. Vad gör jag då?',
					content: `<p>Enligt v&aring;ra avbokningsregler m&aring;ste du avboka din plats senast 72 timmar innan start. Det &auml;r f&ouml;r att vi ska hinna erbjuda platsen till annan deltagare som st&aring;r p&aring; k&ouml;. Du avbokar enklast genom att g&aring; till Portalen och &rdquo;Bokade aktiviteter&rdquo; P&aring; varje bokad kurs finns en avbokaknapp. F&aring;r du f&ouml;rhinder senare &auml;n s&aring; m&aring;ste du kontakta oss p&aring; telefon 010 641 01 10 eller mail&nbsp; <a href="mailto:info@affarskompetens.se">info@affarskompetens.se</a>&nbsp;<br/>Viktigt! Vi ser kontinuerligt &ouml;ver de deltagare som inte meddelar och inte dyker upp p&aring; en bokad aktivitet. </p>`
				},
				{
					heading: 'Vilka aktiviteter spelas in? ',
					content: `Du kan se på respektive aktivitet om det spelas in eller inte, det framgår i kursbeskrivningen.`
				},
				{
					heading: 'Måste man ha kameran på under kurserna? ',
					content: `<p>Ja, det &auml;r trevligt att se varandra i en kurs eller aktivitet. S&aring;vida du inte har speciella sk&auml;l eller behov av att ha den avst&auml;ngd, i s&aring; fall f&aring;r du g&auml;rna meddela oss i f&ouml;rv&auml;g eller skriva i chatten till kursledaren. </p>
<p>P&aring; webinar och f&ouml;rel&auml;sningar har vi st&auml;ngt av kameran och mikrofon f&ouml;r alla deltagare, d&aring; kommunicerar du med oss i chatten. </p>`
				},
				{
					heading: 'Jag har en vän som också vill gå kurser, får hen det? ',
					content: `<p>Hen beh&ouml;ver vara anst&auml;lld i ett f&ouml;retag som &auml;r med i n&aring;got/n&aring;gra av v&aring;ra p&aring;g&aring;ende projekt. Be hen fr&aring;ga sin f&ouml;retagsledare om de &auml;r med. &Auml;r inte hens f&ouml;retag med, kontakta g&auml;rna Aff&auml;rskompetens s&aring; tar vi kontakt och ber&auml;ttar mer vad vi kan erbjuda i de respektive projekten. </p>`
				},
				{
					heading: 'Om jag bara kan vara med en stund på den kurs jag är anmäld till, är det ok?  ',
					content: `<p>Nej, vi vill att ni &auml;r med under hela kursen. Kan ni inte det &auml;r det b&auml;ttre att v&auml;lja ett annat tillf&auml;lle som passar b&auml;ttre. Utbytet f&ouml;r b&aring;de er som deltagare och kursledaren blir mycket b&auml;ttre om alla &auml;r delaktiga under hela kurstiden och inte avbryter sin medverkan mitt i. Det &auml;r &auml;ven viktigt att ansluta god i tid till m&ouml;tesstart s&aring; ni inte missar information. </p>`
				},
				{
					heading: 'Jag håller också kurser. Har ni behov av mina tjänster?',
					content: `<p>Vad roligt att ni &auml;r intresserad av att samarbeta med oss! Ink&ouml;p hos oss sker genom offentlig upphandling &ndash; anm&auml;l ditt intresse p&aring; v&aring;r hemsida <a href="https://www.affarskompetens.se/bli-leverantor/">https://www.affarskompetens.se/bli-leverantor/</a>&nbsp;<br/></p>`
				},
				{
					heading: 'Vi vill gärna sitta flera framför samma dator, får man det?',
					content: `<p>Ja, det g&aring;r oftast bra men ni beh&ouml;ver meddela oss det. Alla som deltar i en aktivitet (&auml;ven gemensamt vid samma dator) beh&ouml;ver vara anm&auml;ld och ha en egen bokning. Det &auml;r f&ouml;r att antalet ska blir r&auml;tt och att vi ska kunna veta vilka som n&auml;rvarat. Alla som deltar m&aring;ste&nbsp; signera sin tid personligen. &nbsp;<br/>I vissa kurser &auml;r det inte lika bra d&aring; man kan delas upp i olika breakoutrooms i olika &ouml;vningar eller diskussioner. D&aring; beh&ouml;ver ni sitta vid varsin dator f&ouml;r att det ska bli r&auml;tt f&ouml;rdelat. St&auml;m g&auml;rna av med Aff&auml;rskompetens eller kursledaren innan ni s&auml;tter er tillsammans.</p>`
				},
				{
					heading: 'Kan man gå kurs via sin telefon eller måste man ha en dator? ',
					content: `<p>M&aring;nga av v&aring;ra kurser kr&auml;ver dator och internetuppkoppling (&auml;ven mikrofon och kamera) f&ouml;r att kunna f&aring; ut mesta m&ouml;jliga av kursen. Vissa f&ouml;rel&auml;sningar eller st&ouml;rre webinar g&aring;r bra att f&ouml;lja via telefonen. Kolla g&auml;rna med Aff&auml;rskompetens innan om ni k&auml;nner er os&auml;kra. </p>`
				},
				{
					heading: 'Finns det en gräns för hur många kurser man får gå? ',
					content: `<p>Nej, ni f&aring;r boka er och g&aring; s&aring; m&aring;nga kurser ni vill. Bokar ni er p&aring; m&aring;nga aktiviteter, &auml;r det bra att ha en kursplanering s&aring; att kurserna blir av och genomf&ouml;rda. Vill ni ha hj&auml;lp med planering kan ni alltid kontakta v&aring;ra kundansvariga s&aring; hj&auml;lper de er med planeringen. &nbsp;</p>`
				},
				{
					heading: 'Vilka företag får gå era kurser? (Branscher, storlek på företag osv.) ',
					content: `<p>De olika projekten har olika krav p&aring; vilka f&ouml;retag som f&aring;r delta. L&auml;s mer om respektive projekt p&aring; v&aring;r hemsida. <a href="https://www.affarskompetens.se/erbjudanden/">https://www.affarskompetens.se/erbjudanden/</a></p>`
				},
				{
					heading: 'Vi har en konsult som jobbar i vårt företag, får hen också gå kurser? ',
					content: `<p>Endast avl&ouml;nade anst&auml;llda f&aring;r vara med i projektet. Tyv&auml;rr ing&aring;r inte konsulter d&auml;r. </p>`
				},
				{
					heading: 'Vilka är utbildare i projektet?',
					content: `<p>Under respektive kurs hittar du information om vilken kursledare och f&ouml;retag som h&aring;ller i kursen. Alla v&aring;ra leverant&ouml;rer &auml;r upphandlade och &auml;r specialister inom sitt omr&aring;de. </p>`
				},
				{
					heading: 'Hur får jag reda på vilka kurser som finns och när de äger rum? ',
					content: `<p>Du kan enkelt logga in i Portalen och se vilka kurser som erbjuds i det projekt du &auml;r med i. Under menyn &rdquo;Aktiviteter&rdquo; till v&auml;nster finns en &ouml;versikt &ouml;ver alla kurser som g&aring;r att boka. L&auml;s ocks&aring; v&aring;ra nyhetsbrev d&auml;r vi p&aring;minner om aktuella kurser och mycket annan bra information.&nbsp;</p>`
				},
				{
					heading: 'Hur kommer kurserna att gå till?',
					content: `<p>Vi erbjuder olika typer av kurser, i respektive kursbeskrivning framg&aring;r om det &auml;r en livekurs, webinar, f&ouml;rel&auml;sning eller en kurs du g&ouml;r p&aring; egen hand. V&aring;ra livekurser sker digitalt via zoom. Zoom &auml;r gratis, ladda g&auml;rna ner appen p&aring; din dator innan start.&nbsp; &nbsp;<br/>N&auml;r du bokat dig p&aring; en aktivitet f&aring;r du en bekr&auml;ftelse p&aring; mejl med information om kurs, tid, kursledare samt l&auml;nk till m&ouml;tet/aktiviteten. Du kan &auml;ven ansluta till m&ouml;tet via Portalen under bokade aktiviteter.&nbsp; </p>`
				},
				{
					heading: 'Kommer utbildningarna vara fysiska eller digitala?',
					content: `<p>S&aring; gott som alla v&aring;ra kurser &auml;r digitala. Aktiviteter sker genom zoom eller som kurs p&aring; egen hand via din dator. Om vi n&aring;gon g&aring;ng har en fysisk aktivitet kommer du f&aring; separat information om det. </p>`
				},
				{
					heading: 'Ingår något kursmaterial? ',
					content: `<p>Ja, efter de allra flesta kurstillf&auml;llena f&aring;r ni presentationen och ev. kursmaterial s&auml;nt till er p&aring; mail. Ibland kan &auml;ven kursledaren dela tips och l&auml;nkar i chatten inne p&aring; zoom.&nbsp;</p>`
				},
				{
					heading: 'Kommer samma kurs erbjudas flera gånger?',
					content: `<p>Utbudet varierar under projektet g&aring;ng och vi upphandlar nya kurser kontinuerligt. Vissa kurser kommer att finnas under l&auml;ngre tid och andra kortare. D&auml;rf&ouml;r &auml;r det klokt att boka de kurser du vill g&aring; och inte v&auml;nta. Vill du veta hur l&auml;nge en kurs kommer att erbjudas kontaktar du oss. </p>`
				},
				{
					heading: 'Varför kan jag inte anmäla mig till en aktivitet?',
					content: `<p>Du beh&ouml;ver ha inloggning till Portalen f&ouml;r att boka dig p&aring; kurs. Kontakta oss p&aring; <a href="mailto:support@affarskompetens.se">support@affarskompetens.se</a> om du har fr&aring;gor kring din inloggning. </p>`
				},
			]
		},
		{
			heading: 'Om projektet',
			items: [
				{
					heading: 'Finns det några krav eller förväntningar på mig som är med? ',
					content: `
					<p>Ja. N&auml;r ESF finansierar olika utbildningsinsatser s&aring; f&ouml;rv&auml;ntar de sig att f&aring; ta del av l&auml;rdomar och resultat. F&ouml;r er som deltar i ett ESF projekt inneb&auml;r det att ni beh&ouml;ver signera en n&auml;rvarorapport samt svara p&aring; utv&auml;rderingsfr&aring;gor om projektet. </p>
					`
				},
				{
					heading: 'Vem betalar för kurserna?',
					content: `
					<p>V&aring;ra projekt Perfekt och Reform medfinansieras av Europeiska socialfonden (ESF) och vi rapporterar till dem varje m&aring;nad om vilka aktiviteter och deltagare vi har.  &nbsp;</p>
<p>ESF har &auml;ven satt ett v&auml;rde (schablonbelopp) p&aring; de timmar som ni deltagare l&auml;gger i projektet och ser det som en delfinansiering.  &nbsp;</p>
					`
				},
			]
		},
		{
			heading: 'Signering',
			items: [
				{
					heading: 'Vad betyder det att man närvarosignerar och varför måste man göra det?  ',
					content: `<p>Tiden varje deltagare l&auml;gger i projektet r&auml;knas som medfinansiering. D&auml;rf&ouml;r beh&ouml;ver alla deltagare signera, d.v.s. godk&auml;nna att de medverkat p&aring; en aktivitet. Signeringen g&ouml;rs efter varje kurs genom en digital signering som kommer p&aring; mail.&nbsp;</p>
					`
				},
				{
					heading: 'Varför behövs närvarorapporter? ',
					content: `
					<p>Det &auml;r ett krav fr&aring;n Europeiska Socialfonden (ESF) som finansierar projektets insatser. Det &auml;r ett intyg p&aring; att deltagare medverkar p&aring; en aktivitet.&nbsp;&nbsp;</p>
					`
				},
				{
					heading: 'Jag har inte signerat min närvaro, vad händer då?',
					content: `
					<p>N&auml;rvarorapporterna kommer p&aring; mail, och vi skickar ut p&aring;minnelser och sms om du inte signerar. Vi kontaktar &auml;ven dig p&aring; telefon om vi inte f&aring;r in din signering. Skulle du inte signera din aktivitet kan vi inte erbjuda dig kurser fram&aring;t och kommer att avregistrera dig i projektet. </p>
					`
				},
				{
					heading: 'Varför behöver ni veta mitt personnummer? ',
					content: `
					<p>Detta projekt f&aring;r medel fr&aring;n Europeiska socialfonden (ESF), de betalar f&ouml;r aktiviteterna. Man f&ouml;r&nbsp; ocks&aring; statistik p&aring; skattemedel p&aring; personniv&aring;, d&auml;rf&ouml;r kr&auml;vs personnummer.&nbsp;</p>
					`
				},
			]
		},
		{
			heading: 'Inloggning och tekniska frågor',
			items: [
				{
					heading: 'Vad gör jag om jag stöter på tekniska problem här inne på Portalen  ',
					content: `
<p>Prova med dessa nedanst&aring;ende l&ouml;sningar och se om det fungerar b&auml;ttre. Prova i den ordning som vi h&auml;r f&ouml;resl&aring;r. &nbsp;</p>
<ol>
<li>Logga ut och st&auml;ng ner webbl&auml;sare (det f&ouml;nstret du surfar i). Prova igen om en stund.&nbsp;</li>
</ol>
<ol>
<li>Anv&auml;nd en annan webbl&auml;sare (Google Crome eller Microsoft Edge t ex). Har du inte den installerad s&aring; beh&ouml;ver du ladda ner den&nbsp;</li>
</ol>
<ol>
<li>&Ouml;ppna ett privat f&ouml;nster (kallas ocks&aring; InPrivate eller Inkognitol&auml;ge) - beroende p&aring; vilken webbl&auml;sare du anv&auml;nder. Logga in som vanligt.&nbsp;</li>
</ol>
<ol>
<li>&Auml;r problemet fortfarande inte l&ouml;st s&aring; mejlar du oss p&aring; <a href="mailto:support@affarskompetens.se">support@affarskompetens.se</a>&nbsp;</li>
</ol>
<p>Ser du saker som inte st&auml;mmer f&aring;r du g&auml;rna rapportera in det. </p>
					`
				},
				{
					heading: 'Måste man vara IT van för att ingå i projektet?',
					content: `
<p>Nej, inte s&auml;rskilt, men ni beh&ouml;ver:&nbsp;&nbsp;</p>
<ul>
<li>Tillg&aring;ng till dator med kamera och ljud&nbsp;&nbsp;</li>
<li>Internetuppkoppling&nbsp;&nbsp;</li>
<li>Kunna hantera mejl&nbsp;&nbsp;</li>
<li>Kunna ansluta till zoomm&ouml;ten. Alla v&aring;ra kurser &auml;r digitala och k&ouml;rs via zoom. Zoom &auml;r ett gratisprogram och laddas ner till dator och/eller mobil. Kurser ser du med f&ouml;rdel p&aring; datorn d&auml;r du har kamera och ljud. </li>
</ul>
					`
				},
			]
		},
		{
			heading: 'Kontakt',
			items: [
				{
					heading: 'Hur kontaktar man Affärskompetens när man har frågor?',
					content: `
<p>Support, inloggningar, tekniska fr&aring;gor: <a href="mailto:support@affarskompetens.se">support@affarskompetens.se</a>&nbsp;<br/>Hj&auml;lp med ny- eller avbokningar: <a href="mailto:info@affarskompetens.se">info@affarskompetens.se</a>&nbsp;<br/>&nbsp;<br/>Vi finns ocks&aring; p&aring; telefon delar av dagen 010-641 01 10</p>
					`
				}
			]
		}
	]

	constructor() {
	}

	ngOnInit(): void {

	}

}
