<div class="project-{{selectedProject}}">
<p-confirmDialog [baseZIndex]="10000" [closable]="false" [closeOnEscape]="false" [style]="{width: '500px'}" header="Uppdatering" icon="fas fa-cogs"
                 key="triggerUpdateDialog"></p-confirmDialog>

<p-toast position="bottom-right"></p-toast>

<p-confirmDialog
        acceptLabel="Ja"
        header="Bekräfta"
        icon="pi pi-exclamation-triangle"
        rejectLabel="Nej"
></p-confirmDialog>


<ng-container *ngIf="appLayout == 'superLayout'">
    <mat-sidenav-container [hasBackdrop]="hasBackdrop" class="app-container">

        <mat-sidenav
			#sidenav
            (setWidth)="setWidth($event)"
            [mode]="modeSidenav"
            [ngStyle]="{ width: width + '%' }"
            [opened]="openedSidenav"
            [sideNav]="sidenav"
            appOpenSidebarOnSwipe
            class="bg-white"
        >
            <app-menu (closeMenu)="sidenav.close()"></app-menu>
        </mat-sidenav>

        <mat-sidenav-content #scrollContainer>

            <mat-toolbar class="main-toolbar" [class.sidenav-opened]="sidenav.opened">
                <div class="flex align-content-between">
                    <button (click)="sidenav.toggle()" class="p-button p-button-secondary p-button-text sidenav-toggler px-4" icon="pi pi-bars" iconPos="left" pButton type="button"></button>
                </div>
                <div *ngIf="currentUser" class="profile-toplink d-flex cursor-pointer pr-3">

                    <button (click)="menu.toggle($event)" class="btn btn-dropdown text-sm btn-primary bg-white text-black px-3 border-black capitalize" type="button">
                        <i class="pi pi-user"></i>
                        <span class="ml-2 d-none d-md-block">{{ currentUser.FirstName }} {{ currentUser.LastName }}</span>
                    </button>

                    <p-menu #menu [model]="avatarMenu" [popup]="true" appendTo="body"></p-menu>
                    <!-- get profile name and picture from db -->
                </div>
                <div></div>
            </mat-toolbar>

            <div class="main-outlet mb-5 pb-5 pb-md-0 px-2 px-sm-3">
                <dd-not-found></dd-not-found>

                <router-outlet></router-outlet>
            </div>

        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="appLayout == 'complete'">
    <mat-sidenav-container [hasBackdrop]="hasBackdrop" class="app-container">

        <mat-sidenav
			#sidenav
            (setWidth)="setWidth($event)"
            [mode]="modeSidenav"
            [ngStyle]="{ width: width + '%' }"
            [opened]="openedSidenav"
            [sideNav]="sidenav"
            appOpenSidebarOnSwipe
            class="bg-white"
        >
            <app-menu (closeMenu)="sidenav.close()"></app-menu>
        </mat-sidenav>
        <mat-sidenav-content #scrollContainer>

            <mat-toolbar class="main-toolbar" [class.sidenav-opened]="sidenav.opened">
                <div class="flex align-content-between">
                    <button (click)="sidenav.toggle()" class="p-button p-button-secondary p-button-text sidenav-toggler px-4" icon="pi pi-bars" iconPos="left" pButton type="button"></button>
                    <div class="ml-2 flex align-items-center text-shadow">
                        <dd-project-selector [asMenu]="true"></dd-project-selector>
                    </div>
                </div>
                <div *ngIf="currentUser" class="profile-toplink d-flex cursor-pointer pr-3">

                    <button (click)="menu.toggle($event)" class="btn btn-dropdown text-sm btn-primary bg-white text-black px-3 border-black capitalize" type="button">
                        <i class="pi pi-user"></i>
                        <span class="ml-2 d-none d-md-block">{{ currentUser.FirstName }} {{ currentUser.LastName }}</span>
                    </button>

                    <p-menu #menu [model]="avatarMenu" [popup]="true" appendTo="body"></p-menu>
                    <!-- get profile name and picture from db -->
                </div>
                <div></div>
            </mat-toolbar>

            <div class="main-outlet mb-5 pb-5 pb-md-0 px-2 px-sm-3">
                <!--<p-messages severity="info" class="mt-5" styleClass="mt-5" [closable]="true">
                    <ng-template pTemplate>
                        <i class="pi pi-info-circle"></i>
                        <div class="ml-2">
                            <strong>Planerat underhållsarbete</strong><br />
                            Lördag den 13 januari mellan 10-16 utför vi ett systemunderhåll<br />
                            Det innebär att e-learning och inspelade kurser kan upplevas lite långsamma.<br />
                            Vi hoppas på din förståelse och att din upplevelse ska bli ännu bättre efter underhållet.<br />
                            <em>Hälsningar Affärskompetens</em>
                        </div>
                    </ng-template>
                </p-messages>-->
                <dd-not-found></dd-not-found>
                <router-outlet></router-outlet>
            </div>
            <mat-toolbar *ngIf="!hideMobileFooter&&!authenticationService.isCourseLeader()" class="footer-toolbar d-md-none d-flex justify-content-between p-0">
                <button [routerLink]="['/']" class="p-button w-50" icon="pi pi-home" pButton type="button"></button>
                <button [routerLink]="['/profil']" class="p-button w-50 side-border" icon="pi pi-user" pButton type="button"></button>
                <button [routerLink]="['/aktiviteter']" class="p-button w-50 side-border" icon="pi pi-fw pi-bookmark" pButton type="button"></button>
                <!--<button [routerLink]="['/analyser']" class="p-button w-50" icon="pi pi-chart-bar" pButton type="button"></button>-->
            </mat-toolbar>
            <mat-toolbar *ngIf="!hideMobileFooter&&authenticationService.isCourseLeader()" class="footer-toolbar d-md-none d-flex justify-content-between p-0">
                <button [routerLink]="['/kursledare/hem']" class="p-button w-50" icon="pi pi-home" pButton type="button"></button>
                <button [routerLink]="['/kursledare/profil']" class="p-button w-50 side-border" icon="pi pi-user" pButton type="button"></button>
                <button [routerLink]="['/kursledare/aktiviteter']" class="p-button w-50" icon="pi pi-calendar" pButton type="button"></button>
            </mat-toolbar>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>

<div *ngIf="appLayout == 'minimal'" class="mx-2 pb-5 pt-5">
    <mat-toolbar class="main-toolbar">

        <div *ngIf="currentUser" class="profile-toplink d-flex cursor-pointer">
            <a (click)="menu.toggle($event)" class="text">
                <span class="d-none d-sm-inline">{{ currentUser.firstname }} {{ currentUser.surname }}</span>
                <img src="./assets/images/blank-profile-picture.svg"/>
            </a>
            <p-menu #menu [model]="avatarMenu" [popup]="true" appendTo="body"></p-menu>
            <!-- get profile name and picture from db -->
        </div>
        <div></div>
    </mat-toolbar>
    <dd-not-found></dd-not-found>
    <router-outlet></router-outlet>
</div>

<dd-book-course-dialog></dd-book-course-dialog>
</div>
