import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOnLeader'
})
export class FilterOnLeaderPipe implements PipeTransform {

  transform(course: any, leaderEmail: any): unknown {

	  return course.filter((i: any) => i.leader?.email == leaderEmail);
  }

}
