import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { BaseWizComponent } from '@shared/components/base-wiz/base-wiz.component';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-step1-user',
	templateUrl: './step1-user.component.html',
	styles: [],
})
export class Step1UserComponent extends BaseWizComponent implements OnInit {
	password: string = '';
	passwordAgain: string = '';
	currentPassword: string = '';

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService) {
		super(companiesService, config, dialogService, route, authService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	submitStep(form: NgForm) {
		this.companiesService.saveCompanyStep(form).then((result: any) => {
			if (result.error == 0) {
				this.stepValidated.next();
				if (this.ref) {
					this.ref.close()
				}
			}
		});
	}

	ngOnDestroy() {

	}
}
