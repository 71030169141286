<div *ngIf="user" class="row d-flex justify-content-center h-75" @FadeIn>
    <div class="rounded mw-50 align-self-center" [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}">
        <div class="row mb-5">
            <div class="col" *ngIf="!inModal">
                <h1 class="text-center mb-4">Villkor</h1>
            </div>
        </div>
        <form action="" method="post" (ngSubmit)="submitStep(form)" #form="ngForm">
            <div class="row justify-content-center" [class.pb-5]="!inModal">
                <div class="col px-0 justify-content-center" style="max-width: 350px">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                [(ngModel)]="approveGdpr"
                                inputId="approveGdpr"
                                name="approveGdpr"
                                value="1"
                                required
                        ></p-checkbox>
                        <label for="approveGdpr">Jag godkänner att mina personuppgifter sparas av <strong>Affärskompetens Sverige</strong><br /><a (click)="displayGdpr = !displayGdpr">Klicka här för att läsa</a></label>
                    </div>
                    <div class="p-field-checkbox" *ngIf="requireEsf">
                        <p-checkbox
                                [(ngModel)]="approveEsf"
                                inputId="approveEsf"
                                name="approveEsf"
                                value="1"
                                required
                        ></p-checkbox>
                        <label for="approveEsf">Jag godkänner att mina personuppgifter sparas av <strong>ESF (Europeiska socialfonden)</strong><br /><a (click)="displayEsf = !displayEsf">Klicka här för att läsa</a></label>
                    </div>
                    <div class="row justify-content-center pb-5" [class.pt-5]="!inModal">
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" label="Spara & gå vidare" [disabled]="!form.valid || isLoading" *ngIf="!inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" [label]="'Spara'" [disabled]="!form.valid || isLoading" *ngIf="inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<p-dialog [(visible)]="displayGdpr" [style]="{width: '50vw'}" [contentStyle]="{background:'#fff'}">
    <p-header>
        Integritets- och personuppgiftspolicy
    </p-header>
    <div class="py-4">
        <dd-gdpr [inModal]="true"></dd-gdpr>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayEsf" [style]="{width: '50vw'}" [contentStyle]="{background:'#fff'}">
    <p-header>
        Att ingå i ett ESF projekt
    </p-header>
    <div class="py-4">
        <dd-esf [inModal]="true"></dd-esf>
    </div>
</p-dialog>
