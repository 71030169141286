<div class="container pb-4">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Att ingå i ett ESF projekt</h2>
        <h3>Att ing&aring; i ett projekt som finansieras med medel fr&aring;n Europeiska Socialfonden</h3>
        <div class="row mt-3">
            <div class="col-6">
                <p><strong>Vad &auml;r ett ESF-projekt</strong>&nbsp;</p>
                <p>Svenska ESF-r&aring;det f&aring;r st&ouml;d fr&aring;n EU ifr&aring;n Socialfonden och FEAD. Genom fonderna finansierar vi projekt som arbetar med kompetensutveckling,
                    syssels&auml;ttnings&aring;tg&auml;rder och integrationsinsatser. Verksamheten syftar till att minska arbetsl&ouml;shet och utanf&ouml;rskap, att st&auml;rka Sveriges l&aring;ngsiktiga
                    kompetensf&ouml;rs&ouml;rjning och tillv&auml;xt och att &ouml;ka sammanh&aring;llningen inom EU.&nbsp;</p>
                <p>V&aring;ra projekt arbetar med att utveckla befintliga metoder, ta fram alternativa metoder och pr&ouml;va nya organisationsformer och samverkansformer. Det ger nya l&ouml;sningar,
                    metoder och arbetss&auml;tt som sedan kan f&ouml;ras in i ordinarie arbetsmarknadspolitik och v&auml;lf&auml;rdssystem.&nbsp;</p>
                <p>V&aring;rt arbete genomsyras av en ambition att motverka diskriminering och att &ouml;ka j&auml;mst&auml;lldheten mellan kvinnor och m&auml;n. Vi arbetar ocks&aring;
                    aktivt med insatser f&ouml;r &ouml;kad tillg&auml;nglighet f&ouml;r personer med funktionsneds&auml;ttning. Vi har &auml;ven byggt upp en unik kompetens n&auml;r det g&auml;ller
                    europeisk sammanh&aring;llningspolitik och f&ouml;rvaltning av EU:s strukturfondsprogram och projekt i Sverige.&nbsp;</p>
                <p><strong>Redovisning av deltagare i socialfondsprojekten&nbsp;</strong>&nbsp;</p>
                <p>Du deltar i ett socialfondsprojekt som finansieras av allm&auml;nna medel. F&ouml;r att se till att medlen anv&auml;nds p&aring; ett bra s&auml;tt och att projekten n&aring;r
                    de resultat vi vill samlar vi in uppgifter om projektdeltagarnas bakgrund och hur det g&aring;r f&ouml;r dem efter att de deltagit i projektet.&nbsp;&nbsp;</p>
            </div>
            <div class="col-6">
                <p><strong>N&auml;rvaroregistrering &ndash; hur fungerar det?&nbsp;</strong></p>
                <p>Ett krav att f&aring; delta i ett ESF-projekt &auml;r att signera sin n&auml;rvarotid efter varje avslutad aktivitet. Det sker genom en digital signering. Du f&aring;r ett
                    mejl med uppmaning att signera din n&auml;rvarotid &ndash; du kontrollerar din n&auml;rvarorapport och signerar digitalt. &nbsp;</p>
                <p><strong>S&aring; samlas uppgifterna in&nbsp;</strong>&nbsp;</p>
                <p>Vi tar fram uppgifter om deltagarna genom att anv&auml;nda register. Vi har valt den metoden eftersom den j&auml;mf&ouml;rt med andra t&auml;nkbara alternativ inneb&auml;r
                    mindre administration, b&auml;ttre kvalitet i uppgifterna och framf&ouml;rallt ett b&auml;ttre skydd f&ouml;r deltagarnas personliga integritet.&nbsp;</p>
                <p>Statististikmyndigheten SCB ansvarar f&ouml;r uppr&auml;ttandet av ett deltagarregister och att ta fram statistik p&aring; uppdrag av Svenska
                    ESF-r&aring;det.&nbsp;&nbsp;</p>
                <p>Insamlingen av uppgifter f&ouml;ruts&auml;tter att projekten rapporterar deltagarnas personnummer till SCB, som med hj&auml;lp av dessa uppgifter inh&auml;mtar
                    registerdata. Beroende p&aring; projektens inriktning kan uppgifter h&auml;mtas fr&aring;n register vid SCB, Arbetsf&ouml;rmedlingen, Centrala studiest&ouml;dsn&auml;mnden
                    och F&ouml;rs&auml;kringskassan. P&aring; n&auml;sta sida finns mer information om de uppgifter som kan komma att kopplas till deltagarinformationen.&nbsp;&nbsp;</p>
                <p>Ungef&auml;r sex m&aring;nader efter du avslutat ditt deltagande i projektet kan du f&aring; en enk&auml;t om hur du upplevt projektet. De rapporterade uppgifterna bidrar
                    till att ge v&auml;rdefull information om hur v&aring;ra gemensamma skattemedel anv&auml;nds. Det &auml;r viktigt att f&aring; veta om projekten verkligen n&aring;r de
                    grupper som de ska och hur det g&aring;r f&ouml;r dem som deltagit i projektet. Vilka uppgifter som g&auml;ller just dig kommer inte att framg&aring; n&auml;r resultat
                    redovisas.&nbsp;&nbsp;</p>
            </div>
        </div>
    </div>
</div>

<div class="container pb-4" *ddIfProject="'perfekt'">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Projektet Perfekt – Korta och konkreta kurser.</h2>
        <div class="row mt-3">
            <div class="col-6">
                <p>I projektet Perfekt &ndash; Korta och konkreta kurser har f&ouml;retagsledare och medarbetare m&ouml;jlighet att p&aring; olika s&auml;tt kompetensutveckla sig. Med avstamp i vilka behov f&ouml;retaget och individen har s&aring; finns det ett stort urval av kurser inom omr&aring;den vi kallar f&ouml;retagar&auml;mnen. Exempel &auml;r ledarskap, personlig utveckling, f&ouml;rs&auml;ljning och marknadsf&ouml;ring. Vi lever i en f&ouml;r&auml;nderlig tid d&auml;r fortbildning verkligen beh&ouml;vs, men det &auml;r b&aring;de sv&aring;rt att hinna med och &auml;ven kostnaden kan vara ett hinder. Vi &auml;r d&auml;rf&ouml;r glada &ouml;ver att kunna erbjuda dig kurser inom en rad olika &auml;mnen som hj&auml;lper f&ouml;retaget att forts&auml;tta vara konkurrenskraftigt. F&ouml;r att s&aring; m&aring;nga som m&ouml;jligt ska kunna delta, n&auml;r det passar dig sj&auml;lv b&auml;st, har vi &auml;ven inspelade kurser och e-learning som ett komplement till de digitala livekurserna.</p>
            </div>
            <div class="col-6">
                <ul>
                    <li>Projektet har plats f&ouml;r 1200 f&ouml;retag och 1750 deltagare som alla har m&ouml;jlighet att delta i kompetensh&ouml;jande insatser.</li>
                    <li>P&aring;g&aring;r mellan 23-02-01-26-03-31 (aktiviteter tom. december -25)&nbsp;</li>
                    <li>Alla insatser sker digitalt.&nbsp;</li>
                    <li>Projektet &auml;r kostnadsfritt och det &auml;r m&ouml;jligt eftersom det finansieras av ESF (Europeiska socialfonden) och i samarbete med F&ouml;retagarna.&nbsp;</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container pb-4" *ddIfProject="'reform'">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Reform – Effektiva team</h2>
        <div class="row mt-3">
            <div class="col-6">
                <p>I projektet Reform - Effektiva team, arbetar vi med f&ouml;ljande:</p>
                <p><strong>Ledning</strong>: H&ouml;j blicken och tydligg&ouml;r vision, m&aring;l och planer</p>
                <p><strong>Kompetens</strong>: Ta reda p&aring; vad ni redan &auml;r bra p&aring; och vad ni beh&ouml;ver mer av f&ouml;r att forts&auml;tta vara konkurrenskraftiga.</p>
                <p><strong>Teamet</strong>: Hur bygger man ett bra team och hur f&aring;r man samarbetet att fungera?</p>
                <p><strong>Bli tryggare med personalfr&aring;gorna</strong>: Vad g&auml;ller n&auml;r medarbetare missk&ouml;ter sig, n&auml;r du beh&ouml;ver s&auml;ga upp n&aring;gon eller hur tar du ett sv&aring;rt samtal?</p>
            </div>
            <div class="col-6">
                <ul>
                    <li>150 f&ouml;retag och 525 deltagare kommer erbjudas plats i projeket&nbsp;</li>
                    <li>P&aring;g&aring;r mellan 2023-03-01 &ndash; 2026-02-28 (aktiviteter tom. december -25)&nbsp;</li>
                    <li>Endast f&ouml;retag fr&aring;n G&auml;vleborg, Dalarna och V&auml;rmland kan delta&nbsp;</li>
                    <li>Projektet finansieras av Europeiska socialfonden (ESF) och medfinansieras av F&ouml;retagarna Mellansverige och G&auml;vle kommun. V&auml;rmlands projektparaply &auml;r samverkanspart.&nbsp;</li>
                </ul>
            </div>
        </div>
    </div>
</div>
