import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/sv';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, Router, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@app/@core/auth';
import { ShellService } from '@app/shell/shell.service';

import { CoreModule } from '@core';

import { environment } from '@env/environment';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

//import * as Sentry from '@sentry/angular';
import { SharedModule } from '@shared';
import { ResponsiveService } from '@shared/services/responsize.service';
import { SidebarSwipeService } from '@shared/services/sidebar-swipe.service';
//import { Angulartics2Module } from 'angulartics2';
import 'core-js/es/object/values';
import { DraggableItemService, SortableModule } from 'ngx-bootstrap/sortable';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ShellModule } from './shell/shell.module';
import { CommunicationKitComponent } from './pages/communication-kit/communication-kit.component';
import { ProjectInfoComponent } from './pages/project-info/project-info.component';
import { TipsComponent } from './pages/tips/tips.component';

/*
export class SentryErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		const eventId = Sentry.captureException(error.originalError || error);
		Sentry.showReportDialog({
			eventId,
			title: 'Ajdå, nånting gick tyvärr sönder',
			subtitle: 'Skicka gärna in detta formulär så fixar vi det!',
			subtitle2: 'För att fortsätta, töm cachen och ladda om sidan (cmd+R / ctrl+R)',
			lang: 'sv',
			labelName: 'Ditt namn',
			labelEmail: 'Din e-post',
			labelComments: 'Beskriv kort vad du försökte göra',
			labelClose: 'Stäng',
			labelSubmit: 'Skicka',
			successMessage: 'Tack för att du hjälper till!'
		});
	}
}*/

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		// Reuse the route of the RouteConfig is the same, or if both routes use the
		// same component, because the latter can have different RouteConfigs.
		return future.routeConfig === curr.routeConfig ||
			(!!future.routeConfig?.component &&
				future.routeConfig?.component === curr.routeConfig?.component);
	}
}

@NgModule({
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
		FormsModule,
		HttpClientModule,
		TranslateModule.forRoot(),
		NgbModule,
		CoreModule,
		SharedModule,
		ShellModule,
		AuthModule,
		//Angulartics2Module.forRoot(),
		SortableModule,
		DragDropModule,
		AppRoutingModule,
		FieldsetModule,
		// must be imported as the last module as it contains the fallback route
	],
	declarations: [AppComponent, ContactComponent, FaqComponent, CommunicationKitComponent, ProjectInfoComponent, TipsComponent],
	providers: [
		{
			provide: ErrorHandler,
			//useClass: SentryErrorHandler
			/*useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),*/
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {
			},
			//deps: [Sentry.TraceService],
			multi: true,
		},
		{provide: LOCALE_ID, useValue: 'sv'},
		{provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
		SidebarSwipeService, DialogService, DynamicDialogConfig, MessageService, ResponsiveService, DynamicDialogRef, ConfirmationService, DraggableItemService, ShellService],
	bootstrap: [AppComponent],
})
export class AppModule {
}
