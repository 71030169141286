import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'employeePosition'
})
export class EmployeePositionPipe implements PipeTransform {

	transform(value: string) {
		if(value == 'leader' || value == 'mainleader')
			return '<span class="badge-employee-position">Företagsledare</span>';

		return '<span class="badge-employee-position">Medarbetare</span>';
	}

}
