import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { InvitesInterface } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-approve-terms-needed',
	templateUrl: './approve-terms-needed.component.html',
	styles: [],
	animations: [FadeIn],
})
export class ApproveTermsNeededComponent {
	inModal: boolean = false;
	isLoading: any;
	@Input() user: InvitesInterface;
	customerProjects: any[];
	activeIndex: number = 0;
	lastIndex: number = 3;
	notApprovedTerms: string;
	currentProject: string = '';

	constructor(public route: ActivatedRoute, public authService: AuthenticationService, private router: Router, public dialogConfig: DynamicDialogConfig, public dialogRef: DynamicDialogRef) {
		let getNotApprovedProjects = this.authService.getNotApprovedProjects();
		this.notApprovedTerms = getNotApprovedProjects.join(', ');

		this.currentProject = this.authService.getSelectedProject();

		this.dialogConfig.modal = true;
		this.dialogConfig.showHeader = false;
		this.dialogConfig.closable = false;
		this.dialogConfig.width = '95vw';
		this.dialogConfig.height = '95vh';
		this.dialogConfig.styleClass = 'approve-terms-needed-dialog';

		if(getNotApprovedProjects.length == 0) {
			this.router.navigate(['/hem']);
			return;
		}

	}

	async submitStep(form: NgForm) {

		this.isLoading = true;

		this.authService.acceptTerms()
			.then(async (data: any) => {
				let result = data;
				console.error(data)
				if (result && !data.error) {

					let availableProjects = this.authService.getAvailableProjects();
					this.authService.currentAuth.approvedTermsFor.list = availableProjects;
					this.authService.currentAuth.token = data.newAuth.auth.token;

					localStorage.setItem('currentAuth', JSON.stringify(this.authService.currentAuth));
					this.authService.currentUserSubject.next(this.authService.currentAuth);

					this.dialogRef.close();
					/*let browseProject = localStorage.getItem('project.browse');

					if (browseProject == null) {
						if (availableProjects.length > 1) {
							await this.router.navigate(['/projektväljare']);
							return;
						} else {
							localStorage.setItem('project.browse', availableProjects[0]);
						}
					}

					await this.router.navigate(['/hem']);*/
				}
				this.isLoading = false;
			});
	}

	scrollTop() {
		document
			.getElementsByClassName('approve-terms-needed-dialog')[0]
			.getElementsByClassName('p-dialog-content')[0]
			.scrollTop = 0;
	}
}
