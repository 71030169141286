import { Component, OnInit, Input } from '@angular/core';
import { FadeIn } from '@shared/animations/animations';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	animations: [FadeIn],
})
export class LoaderComponent implements OnInit {
	@Input() isLoading = true;
	@Input() inline = false;
	@Input() message: string | undefined = '';

	constructor() {
	}

	ngOnInit() {
	}
}
