<div class="container pb-4 w-50 flex justify-content-center">
    <div class="card-box border-round-big p-5 shadow bg-white text-center">
        <h2>Kontakta oss</h2>
        <div class="row mt-3">
            <div class="col-12">
                <p>Du &auml;r v&auml;lkommen att kontakta oss via mail p&aring;<br /><a href="mailto:info@affarskompetens.se">info@affarskompetens.se</a> eller ring oss p&aring; <a href="tel:0106410110">010-641 01 10</a>.</p>
                <p>Gäller ditt ärende <strong>teknisk support</strong> ber vi dig kontakta oss på <br /><a href="mailto:info@affarskompetens.se">support@affarskompetens.se</a></p>
                <p>G&aring; in p&aring; v&aring;r kontaktsida om du vill komma i kontakt<br />med n&aring;gon s&auml;rskild person hos oss.</p>
                <a pButton type="button" class="p-button-raised p-button-lg mt-3" href="https://www.affarskompetens.se/kontakt/" target="_blank"
                        icon="fa-solid fa-arrow-right-long" iconPos="right" label="Till kontaktsidan"></a>
            </div>
        </div>
    </div>
</div>
