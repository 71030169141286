import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFilename'
})
export class TruncateFilenamePipe implements PipeTransform {

	transform(name: string, limit: number = 20): string {
		const ext: string =
			name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
		let newName: string = name.replace('.' + ext, '');
		if (name.length <= limit) {
			// if file name length is less than 8 do not format
			// return same name
			return name;
		}
		newName = newName.substring(0, limit) + (name.length > limit ? '[...]' : '');
		return newName + '.' + ext;
	}

}
