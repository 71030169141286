import { Component, Input, OnInit } from '@angular/core';
import { CoursesService } from '@shared/services/courses.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-cancel-submission-dialog',
	templateUrl: './cancel-submission.dialog.html',
	styles: []
})
export class CancelSubmissionDialog implements OnInit {
	isMainCourse: boolean = false;
	isMultiDayCourse: boolean = false;
	canCancel: boolean = false;
	started: boolean = false;

	@Input() course: any;
	reason: any;
	cancelAllparts: boolean = true;
	isLoading: boolean;

	constructor(private coursesService: CoursesService, private confirmationService: ConfirmationService, public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
	}

	ngOnInit(): void {
		if (this.config?.data && this.config.data.course) {
			this.course = this.config.data.course;
		}
		console.error('init CancelSubmissionDialog', this.course);

		let timeNow = Math.floor(Date.now() / 1000);
		this.isMainCourse = this.course.courseID == this.course.mainCourseID;

		/**
		 * Clientconfig canCancelTimeBefore
		 * ska bestämma timmar före kursstart man kan avboka.
		 * false = kan ej avboka
		 * true = kan avboka, ej bestämt om det ska finnas begräsning
		 *
		 * Det ska Aldrig gå att avboka efter Slutdatum(?)
		 */
		this.canCancel = this.course.course_startdate < (timeNow + 259200);
		this.started = this.course.course_startdate < (timeNow);
		this.isMultiDayCourse = this.course.multiDayCourse == 1;
	}

	submit() {
		/**
		 * @todo if !canCancel AND !started, show message about late cancelation-terms?
		 * @todo get other parts If isMultiDayCourse?
		 */
		this.confirmationService.confirm({
			message: 'Vill du verkligen avboka?',
			accept: async () => {
				this.isLoading = true;
				await this.coursesService.cancelSubmission(this.course.ParticipantId, this.course.PersonId).then((result: any) => {
					this.isLoading = false;
					if(result.error == 0) {
						this.ref.close();
						this.course.Canceled = true;
						//this.course.cancel_date = new Date().getTime() / 1000;
						this.coursesService.fetchSubmissions(true).then();
					}
				});
			}
		});
	}
}
