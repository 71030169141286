import { CompanyInterface } from '@shared/interfaces/company-interface';
import { MenuItem } from 'primeng/api';

export interface UserInterface {
	_lastLogin: boolean|Date;
	accessRole: any;
	accessPosition: any;
	finishedProfile: number;
	foodPref: string
	disabilities: string
	/*userID: number;
	firstname: string;
	surname: string;
	socialsecurityno: string;
	email: string;
	password?: string;
	position: string;
	mobilephone: string;
	allergies?: any[];
	disabilitydetails?: any[];
	token?: string;*/
	position: string;
	allergies?: any[];
	disabilitydetails?: any[];
	projects?: { fields: any[], list: any[] };

	// EA
	PersonId: number
	CustomerId: number
	FirstName: string
	LastName: string
	Address: string
	Address2: string
	Zip: string
	City: string
	Mobile: string
	Phone: string
	Email: string
	CivicRegistrationNumber: string
	Birthdate: string
	EmployeeNumber: string
	JobTitle: string
	Country: string
	CountryCode: string
	SsoId: string
	LmaNumber: string
	Created: string
	Modified: string
	CanLogin: boolean
	IsContactPerson: boolean
	PurchaseOrderNumber: string
	Reference: string
	IndividualId: number
	CustomFields: Array<{
		CustomFieldId: number
		CustomFieldName: string
		CustomFieldType: string
		MetaType: string
		ShowOnWeb: boolean
		SortIndex: any
		CustomFieldValue: string
		CustomFieldChecked: boolean
		CustomFieldDate: any
		CustomFieldAlternativeId: number
		CustomFieldAlternativeValue: string
	}>
	Consents: Array<any>
}

export interface InvitesInterface {
	rowID: number
	companyID: number
	email: string
	accessRoleFieldID: number
	token: string
	created: string

	FirstName: string
	LastName: string
	CivicRegistrationNumber: string
	Mobile: string
	customer: CompanyInterface

	project: string

	foodPref: string
	disabilities: boolean
	personAccessPosition: any
}

/**
 * @todo ladda listan från API för att slippa kompilera om vid ändring
 */
export const DisabilityOptions: any = [
	'Nej, jag har inget behov av anpassning',
	'Ja, läs- och skrivsvårigheter',
	'Ja, hörselnedsättning',
	'Ja, synnedsättning',
	'Ja, rörelsehinder',
	'Ja, kognitiv, psykisk eller annan funktionsnedsättning',
	'Ja, språk',
	'Ja, annat'
];

/**
 * @todo ladda listan från API för att slippa kompilera om vid ändring
 */
export const AllergieOptions: any = [
	'Nej, jag har ingen matallergi/kostpreferenser',
	'Ja, glutenfritt',
	'Ja, laktosfritt',
	'Ja, mjölkproteinfritt',
	'Ja, vegetariskt',
	'Ja, annat'
];

export interface CustomMenuItem extends MenuItem{
	index: number
}
