// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
	production: true,
	enableSentry: true,
	hmr: false,
	version: env.npm_package_version,
	serverUrl: 'https://portal.affarskompetens.se/api/v1',
	//serverUrl: 'https://portal.affarskompetens.se/wp-admin/admin-ajax.php?action=ng_ca_auth_actions',
	defaultLanguage: 'sv-SE',
	supportedLanguages: ['sv-SE'],
};
