<div *ngIf="user" class="row d-flex justify-content-center h-75" @FadeIn>
    <div [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}" class="rounded mw-50 align-self-center">
        <div class="row mb-2 mb-sm-5">
            <div *ngIf="!inModal" class="col">
                <h1 class="text-center mb-4">LÖSENORD</h1>
                <p class="text-center">
                    Säkerheten först!<br/>
                    Välj ett nytt lösenord för att logga in här på portalen. <br/>
                    Tänk på att välja ett lösenord som inte är lätt att gissa sig till.
                </p>
            </div>
        </div>
        <form #form="ngForm" (ngSubmit)="submitStep(form)" action="" method="post">
            <div [class.pb-sm-5]="!inModal" class="row justify-content-center">
                <div class="col px-0 justify-content-center" style="max-width: 350px">
                    <div *ngIf="inModal" class="col-12">
                        <p class="text-center mb-3">
                            Tänk på att välja ett lösenord som inte är lätt att gissa sig till.
                        </p>
                    </div>

                        <span *ngIf="inModal" class="p-float-label mb-4">
                            <input [(ngModel)]="currentPassword" [feedback]="false" class="w-100 bg-white" id="float-input0" name="currentPassword" pPassword required type="password"/>
                            <label for="float-input0">Nuvarande lösenord</label>
                        </span>
                        <span class="p-float-label mb-4">
                            <input [(ngModel)]="password" [feedback]="true"
                                   promptLabel="Skriv in ett lösenord"
                                   weakLabel="Oj, lite för enkelt lösenord?"
                                   mediumLabel="Ganska starkt"
                                   strongLabel="Starkt, mycket bra!"
                                   class="w-100 bg-white" id="float-input1" name="password" pPassword required type="password"/>
                            <label for="float-input1">Ange nytt lösenord</label>
                        </span>
                        <span class="p-float-label mb-4">
                            <input [(ngModel)]="passwordAgain" [feedback]="false" class="w-100 bg-white" id="float-input2" name="passwordAgain" pPassword required type="password"/>
                            <label for="float-input2">Upprepa nytt lösenord</label>
                        </span>
                        <span *ngIf="password != '' && passwordAgain != '' && password != passwordAgain" class="text-danger">Lösenorden matchar inte varandra!</span>


                    <div [class.pt-sm-5]="!inModal" class="row justify-content-center pb-sm-5">
                        <button [disabled]="!form.valid || password != passwordAgain || isLoading" class="w-100 mx-3 p-button-lg btn-fw-700" label="Byt lösenord" pButton type="submit">
                            <i *ngIf="isLoading" class="fas fa-cog fa-spin position-absolute"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


