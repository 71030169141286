import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { CustomMenuItem, InvitesInterface } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';

@Component({
	selector: 'dd-invite',
	templateUrl: './invite.component.html',
	styles: [`
		::ng-deep .p-steps .p-steps-item .p-menuitem-link {
			background: none;
		}

		::ng-deep .p-steps .p-steps-item.p-highlight .p-steps-number {
			background: #fff;
		}

		::ng-deep .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
			
		}

		::ng-deep .p-float-label .p-inputwrapper-focus ~ label,
		::ng-deep .p-float-label input.p-filled ~ label,
		::ng-deep .p-float-label .p-inputwrapper-filled ~ label,
		::ng-deep .p-float-label input:focus ~ label {
			
		}
	`],
	animations: [FadeIn],
})
export class InviteComponent {
	started: boolean = true;
	finished: boolean = false;
	activeIndex: number = 0;
	items: CustomMenuItem[] = [];
	token: string;
	invite: InvitesInterface;
	invalidToken: boolean = false;

	constructor(private route: ActivatedRoute, private router: Router, public companiesService: CompaniesService, private authService: AuthenticationService) {
		this.items = [];
		this.items.push({
			label: 'Personuppgifter', index: 0, command: (event: any) => {
				this.activeIndex = 0
			}
		});
		this.items.push({
			label: 'Anpassningar & kostpreferenser', index: 1, command: (event: any) => {
				this.activeIndex = 1
			}
		});
		this.items.push({
			label: 'Villkor', index: 2, command: (event: any) => {
				this.activeIndex = 2
			}
		});
		/*this.items.push({
			label: 'Välkommen', index: 3, command: (event: any) => {
				this.activeIndex = 3
			}
		});*/
	}

	ngOnInit() {

		this.route.paramMap.subscribe(params => {
			this.token = params.get('token');
		});

		this.companiesService.validateInvite(this.token).then((invite) => {
			if (invite == null) {
				this.invalidToken = true;
				return;
			}
			invite.personAccessPosition = '';
			this.invite = invite;
		});

	}

	start() {
		this.started = !this.started;
		//localStorage.setItem(this.storageKey, '0');
	}

	nextStep(nextIndex: number = 0) {

		this.activeIndex = nextIndex;

		if (nextIndex == 3) {
			return;
		}
		window.scroll(0, 0);
	}
}
