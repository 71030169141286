import { Component, Input } from '@angular/core';

@Component({
  selector: 'dd-project-info',
  templateUrl: './project-info.component.html',
  styles: [
  ]
})
export class ProjectInfoComponent {
	@Input('inModal') inModal: boolean = false;

	constructor() {
	}

	ngOnInit(): void {
	}

}
