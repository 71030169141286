import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'dd-category-tag',
	templateUrl: './category-tag.component.html',
	styles: []
})
export class CategoryTagComponent implements OnInit {

	@Input() tag: any;

	@Input() split: boolean = false;
	@Input() invert: boolean = false; // revert colors
	@Input() classes: string = 'course-type-tag';
	@Input() extraClasses: string = '';

	constructor() {
	}

	ngOnInit(): void {
		if(this.split && typeof(this.tag) === "string") {
			this.tag = this.tag.split(', ');
		}
		if(this.invert) {
			this.classes += ' bg-primaryDarker';
		}
	}

}
