import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth';
import { clientConfig } from '@env/client.config';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class ClientConfigService {

	public config = clientConfig;

	constructor(private http: HttpClient) {

	}

	find(path: string, onKey: string, where: string): any {
		return (this.config[path] as object[]).find((i) => i[onKey] == where);
	}

	findCourseTypeBadge(where: string): any {
		return (this.config['courseTypeBadge'] as object[]).find((i) => i['type'] == where);
	}

	saveCustomField(metatype: string, value: string | object) {
		let formData = new FormData();
		formData.append('subaction', 'saveCustomField');
		formData.append('metatype', metatype.toString());
		formData.append('value', (typeof value === 'string' ? value : JSON.stringify(value)));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((result: any) => {
				return result;
			});
	}
}
