import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'ddMissingData'
})
export class MissingDataPipe implements PipeTransform {

	title: string = 'Uppgift saknas..';

	constructor(public sanitizer: DomSanitizer) {

	}

	transform(value: any, title?: string): SafeHtml {
		if (value === null || value == '' || value == '0' || value == 'undefined' || value.includes('Välj')) {
			if(title !== undefined)
				this.title = title;
			return this.sanitizer.bypassSecurityTrustHtml(
				'<span class="font-xs font-italic text-muted font-weight-normal">'+this.title+'</span>'
			);
		}

		return value;
	}

}
