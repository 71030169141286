import { EventEmitter } from '@angular/core';
import { Route, Routes } from '@angular/router';

import { NewAuthGuard } from '@app/@core/auth';
import { NotFoundMessageInterface } from '@shared/interfaces/not-found';
import { ShellComponent } from './shell.component';

/**
 * Provides helper methods to create routes.
 */
export class ShellService {

	public showNotFoundComp: EventEmitter<NotFoundMessageInterface> = new EventEmitter<NotFoundMessageInterface>();

	/**
	 * Creates routes using the shell component and authentication.
	 * @param routes The routes to add.
	 * @return The new route using shell as the base.
	 */
	static childRoutes(routes: Routes): Route {
		return {
			path: '',
			component: ShellComponent,
			children: routes,
			canActivate: [NewAuthGuard],
			// Reuse ShellComponent instance when navigating between child views
			data: {reuse: true},
		};
	}
}
