import { Component } from '@angular/core';

@Component({
  selector: 'dd-tips',
  templateUrl: './tips.component.html',
  styles: [`
  iframe { max-width:100%; }
  `
  ]
})
export class TipsComponent {

}
