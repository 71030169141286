import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'courseTypeFilter',
	pure: true
})
export class CourseTypeFilterPipe implements PipeTransform {

	transform(value: any) {
		return value.filter((i: any) => i.config?.showAsFilter);
	}

}
