import { Component, Input } from '@angular/core';

@Component({
  selector: 'dd-empty-list-msg',
  templateUrl: './empty-list-msg.component.html',
  styles: [
  ]
})
export class EmptyListMsgComponent {
	@Input() list: any;
	@Input() msg: string = 'Listan är just nu tom..';
}
