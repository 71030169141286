<p-dataView #dv [value]="courses | sortBy:selectedSorting.dir:selectedSorting.value:selectedSorting.subValue" layout="grid" styleClass="" gridStyleClass="" @FadeIn>
    <ng-template let-course pTemplate="gridItem">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-2 d-flex">
            <!--<p-card styleClass="d-flex flex-column">

                <ng-template pTemplate="header">
                    <div [ngStyle]="{'background-image': 'url('+course.ImageUrl+')'}" class="list-type-grid-header"></div>
                </ng-template>

                <ng-template pTemplate="content">
                    <div class="d-inline-block">
                        <dd-category-tag [split]="true" [tag]="course.Categories" extraClasses="py-0" [invert]="false"></dd-category-tag>
                    </div>
                    <div class="p-card-title text-lg">{{course.CourseName}}</div>
                    <div class="text-md" [innerHTML]="course.CourseDescriptionShort"></div>
                </ng-template>

                <ng-template pTemplate="footer">
                    <div class="d-flex justify-content-between">
                        <div>
                            <span class="badge badge-pill badge-primary font-medium">{{course._FutureEvents}} st</span>
                            <span class="text-500">kommande tillfällen</span>
                        </div>
                        <div>
                            <span class="badge badge-pill badge-grey ml-1" *ngFor="let type of course._CourseTypeList">{{type}}</span>
                        </div>
                    </div>
                </ng-template>
            </p-card>
            -->
            <dd-course-card-small [course]="course"></dd-course-card-small>
        </div>
    </ng-template>
</p-dataView>
