<div class="wrapper" *ngIf="isLoading" @FadeIn>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow-loader"></div>
    <div class="shadow-loader"></div>
    <div class="shadow-loader"></div>
    <span>{{message}}</span>
</div>
<div class="wrapper-overlay" *ngIf="isLoading" @FadeIn>

</div>
