import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'dd-attending',
	template: `		
        <ng-container>
            <span *ngIf="attendant == null" class="text-warning">Uppgift saknas</span>
            <span *ngIf="attendant == true" class="text-success">Ja</span>
            <span *ngIf="attendant == false" class="text-danger">Nej</span>
        </ng-container>
	`,
	styles: []
})
export class AttendingWidget implements OnInit {
	get attendant() {
		return this._attendant;
	}

	@Input() set attendant(value) {
		this._attendant = value;
	}

	private _attendant: any = 0;

	constructor() {
	}

	ngOnInit(): void {
	}

}
