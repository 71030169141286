import { inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChildFn,
	CanActivateFn,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate{
	constructor(private router: Router, private authenticationService: AuthenticationService) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const currentUser = this.authenticationService.currentAuth;
		console.error(':: Route: ', state.url);
		if (route.routeConfig.path != '') {
			console.error(':: Route: ', state.url);
		}

		if (currentUser) {
			// logged in so return true
			let role = this.authenticationService.getRole();
			if (role == 'mainleader') {
				role = 'leader';
			}

			if (route.data.role && route.data.role.indexOf(role) === -1) {
				this.router.navigate(['/']);
				return false;
			}

			if (route.data.leaderType && route.data.leaderType.indexOf(this.authenticationService.getLeaderType()) === -1) {
				this.router.navigate(['/']);
				return false;
			}

			return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});

		return false;
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): boolean {
		return this.canActivate(next, state);
	}
}

export const NewAuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const authenticationService = inject(AuthenticationService);
	const router = inject(Router);

	const currentUser = authenticationService.currentAuth;
	console.error(':: Route: ', state.url);

	if (currentUser) {
		// logged in so return true
		let role = authenticationService.getRole();
		if (role == 'mainleader') {
			role = 'leader';
		}

		if (route.data.role && route.data.role.indexOf(role) === -1) {
			router.navigate(['/']);
			return false;
		}

		if (route.data.leaderType && route.data.leaderType.indexOf(authenticationService.getLeaderType()) === -1) {
			router.navigate(['/']);
			return false;
		}

		return true;
	}

	// not logged in so redirect to login page with the return url
	router.navigate(['/login'], {queryParams: {returnUrl: state.url}});

	return false;
};
