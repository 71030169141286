import { CourseEventInterface } from '@shared/interfaces/course';
import * as dayjs from 'dayjs';
import { Table } from 'primeng/table';

export const ddCalendarUtils = {
	localeCalendarSwe: {
		firstDayOfWeek: 1,
		dayNames: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
		dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
		dayNamesMin: ["Sö", "Må", "Ti", "On", "To", "Fr", "Lö"],
		monthNames: [ "Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December" ],
		monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "Maj", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dec" ],
		today: 'Idag',
		clear: 'Rensa',
		dateFormat: 'yy-mm-dd',
		weekHeader: 'v'
	},

	secondsTohhmmss(duration:number) {
		duration = duration * 60;
		let hours   = Math.floor(duration / 3600);
		let minutes = Math.floor((duration - (hours * 3600)) / 60);
		let seconds = duration - (hours * 3600) - (minutes * 60);

		// round seconds
		seconds = Math.round(seconds * 100) / 100;

		let result = '';
		if(hours > 0)
			if(hours == 1)
				result += hours+' tim.';
			else
				result += hours+' tim.';
		if(minutes > 0) {
			if(hours > 0)
				result += ', ';
			result += minutes + ' min.';
		}
		return result;
	},

	dateRangeFilter (table: Table, value:any, field: string, activeFilters: any) {
		// get the from/start value
		var s = activeFilters[0]?.getTime() / 1000;
		var e;
		// the to/end value might not be set
		// use the from/start date and add 1 day
		// or the to/end date and add 1 day
		if ( activeFilters[1]) {
			e =  activeFilters[1]?.getTime() / 1000 + 8640000;
		} else {
			e = s + 8640000;
		}
		// compare it to the actual values

		//console.error(value, field, activeFilters, s, e);

		//table.filter(s, field, 'gte') ;
		table.filter(e, field, 'lt');

		//return value.getTime() >= s && value.getTime() <= e;


	},

	anyInFilter(table: Table, event:any, field: string) {
		//console.error(event.value, field);
		table.filter(event.value, field, 'in')
	},

	onMinimumChange(table: Table, event:any, field: string) {
		const value = event.target.value;
		if (value && value.trim().length) {
			const activity = parseInt(value);

			if (!isNaN(activity)) {
				table.filter(activity, field, 'gte');
			}
		} else
			table.filter('', field, '');
	},

	hasStarted(startDate: string) {
		let date = new Date(Date.parse(startDate));
		return (date.getTime() < Date.now());
	},

	isRunning(startDate: string, endDate: any) {
		let now = Date.now();
		let startDateObj = new Date(Date.parse(startDate));
		let endDateObj = new Date(Date.parse(endDate));
		return (now > startDateObj.getTime() && now < endDateObj.getTime());
	},

	hasPassed(hasStarted: boolean, isRunning: boolean, event: CourseEventInterface) {
		return hasStarted && !isRunning;
	},

	formatDate(date: any) {
		let month = date.getMonth() + 1;
		let day = date.getDate();

		if (month < 10) {
			month = '0' + month;
		}

		if (day < 10) {
			day = '0' + day;
		}

		return date.getFullYear() + '-' + month + '-' + day;
	},

	onDateSelect(table: Table, value: any) {
		table.filter(this.formatDate(value), 'date', 'equals')
	},

	isFuture(StartDate: string): boolean {
		return dayjs(StartDate).isAfter(dayjs());
	}
};
