import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const FadeIn = trigger('FadeIn', [
	transition(':enter', [style({opacity: 0}), animate('500ms', style({opacity: 1}))]),
	transition(':leave', [animate('500ms', style({opacity: 0}))]),
]);
export const fadeInOutTimeout = 500;
export const fadeInOut = trigger('fadeInOut', [
	transition('void => *', [style({opacity: '0', transform: 'translateY(-10%)'}), animate(fadeInOutTimeout)]),
	transition('* => void', [animate(fadeInOutTimeout, style({opacity: '0'}))]),
	transition('* => *', [
		style({opacity: '0', transform: 'translateY(-10%)'}),
		animate(fadeInOutTimeout, style({opacity: '1', transform: 'translateX(0%)'})),
	]),
]);

export const nextPrev = trigger('nextPrev', [
	transition(':decrement', group([
		query('.container-flush', [
			style({opacity: 0}), animate('500ms ease-in-out', style({opacity: 1}))
		], {optional: true}),
	])),
	transition(':increment', group([
		query('.container-flush', [
			style({opacity: 0}), animate('500ms ease-in-out', style({opacity: 1}))
		], {optional: true}),
	]))
]);
