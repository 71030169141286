import { clientConfig } from '@env/client.config';
import { CourseEventInterface, CourseInterface } from '@shared/interfaces/course';
import 'dayjs/locale/sv';
import * as dayjs from 'dayjs'
dayjs.locale('sv');

export const ddCourseUtils = {

	getNotCanceledBookings(list: any[]) {
		if(list.length == 0)
			return list;

		if (list[0].Canceled === undefined) {
			return list;
		}

		return list.filter((i) => !i.Canceled);
	},

	getCanceledBookings(list: any[]) {
		if(list.length == 0)
			return list;

		if (list[0].Canceled === undefined) {
			return list;
		}

		return list.filter((i) =>
			(i.Canceled ||
				(i.OnDemandAccessExpired && !i.Arrived) ||
				(!i.Completed && !i.Event.IsFuture && !i.Event.IsRunning && i.Arrived == false)
			) && !i.Completed
		);
	},

	getActiveBookings(list: any[]) {
		if(list.length == 0)
			return list;

		if (list[0].Event.IsFuture === undefined) {
			return list;
		}

		return list.filter((i) => (i.Event.IsFuture || i.Event.IsRunning || i.CourseTemplate.IsRecording || i.CourseTemplate.IsElearning) && !i.OnDemandAccessExpired);
	},

	getPastBookings(list: any[]) {
		if(list.length == 0)
			return list;

		if (list[0].Event.IsFuture === undefined) {
			return list;
		}

		return list.filter((i) => (i.Completed || (i.Arrived && !i.CourseTemplate.IsElearning)) && !i.Event.IsFuture);
	},

	_getCourseTypeConfig(i: any)
	{
		return (clientConfig['courseTypeBadge'] as any[]).find((type) => type['type'] == i.CourseTemplate.CourseType);;
	},

	showCancelBookingButton(i: any) {
		let findType = ddCourseUtils._getCourseTypeConfig(i);
		if(findType) {
			//findType.canCancelTimeBefore

			/**
			 * Clientconfig canCancelTimeBefore
			 * ska bestämma timmar före kursstart man kan avboka.
			 * false = kan ej avboka
			 * true = kan avboka, ska gå fram till slutdatum för kursen!
			 *
			 * Det ska Aldrig gå att avboka efter Slutdatum(?)
			 */

			if(findType.canCancelTimeBefore == false)
				return false;
		}
		return true;
	},

	canCancelBooking(i: any) {

		/**
		 * Pga att man ska kunna avboka e-learning undertiden så går itne följande IF
		 */
		//if (i.Event.IsFuture && !i.Event.IsRunning && !i.Canceled) {
		let findType = ddCourseUtils._getCourseTypeConfig(i);

		if (((i.Event.IsFuture && !i.Event.IsRunning) || findType.canCancelDuring == true)
			&& !i.Canceled) {

			if(!ddCourseUtils.showCancelBookingButton(i))
				return false;

			if(findType) {
				if(findType.canCancelTimeBefore == true && (i.Event.IsFuture || i.Event.IsRunning))
					return true;

				let now = dayjs();

				if(now.isAfter(i.Event.EndDate))
					return false;

				/**
				 * Kollar så att dagens datum + x timmar frammåt är tidigare än kursstart (t.ex. 3 dagar före kursstart)
				 */
				let inFuture = now.add(findType.canCancelTimeBefore.time, findType.canCancelTimeBefore.unit)

				return inFuture.isBefore(dayjs(i.Event.StartDate))
			}
			return true;
		}

		return false;
	},

	isSubmissionOpen(e: CourseEventInterface, course: CourseInterface) {
		if (!e.ApplicationOpenDate) {
			return true;
		}
		let now = Date.now();
		let open = new Date(Date.parse(e.ApplicationOpenDate));
		return (open.getTime() < now);
	},

	isSubmissionClosed(e: CourseEventInterface, course: CourseInterface) {
		if (!e.LastApplicationDate && !course.IsOnDemand) {
			return false;
		}

		if(!course.IsOnDemand) {
			let now = Date.now();
			let closed = new Date(Date.parse(e.LastApplicationDate));

			return (closed.getTime() < now);
		} else {
			return e.HasPassed || e.IsRunning
		}
	}

};
