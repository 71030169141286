import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { LoginComponent } from '@app/pages/login/login.component';
import { SupermarioComponent } from '@app/pages/login/supermario/supermario.component';

const routes: Routes = [
	{path: 'login', component: LoginComponent, data: {title: extract('Login')}},
	{path: 'supermario', component: SupermarioComponent, data: {title: extract('Login')}},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [],
})
export class AuthRoutingModule {
}
