<ng-container [ngSwitch]="btn.type">
    <!-- the same view can be shown in more than one case -->
    <div *ngSwitchCase="'close'">
        <button pButton type="button" [label]="btn.title" class="p-button-{{btn.color}}" (click)="emitOnClick('close')"></button>
    </div>
    <div *ngSwitchCase="'internal'">
        <button pButton type="button" [label]="btn.title" class="p-button-{{btn.color}}" [routerLink]="btn.link" (click)="emitOnClick('close')"></button>
    </div>
    <!--default case when there are no matches -->
    <div *ngSwitchDefault>...</div>
</ng-container>
