import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { BaseQuestionComponent } from '@app/pages/analyzes/_question_types/base-question.component';
import { AnalysisService } from '@shared/services/analysis.service';
import { ResponsiveService } from '@shared/services/responsize.service';
import { orderBy } from 'lodash';
import { SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';
@Component({
	selector: 'dd-type-prioritizeanswers',
	templateUrl: './type-prioritizeanswers.component.html',
	styles: [`		
		.example-container {
			width: 400px;
			max-width: 100%;
			margin: 0 25px 25px 0;
			display: inline-block;
			vertical-align: top;
		}

		.example-list {
			border: solid 1px #ccc;
			min-height: 41px;
			background: white;
			border-radius: 4px;
			overflow: hidden;
			display: block;
		}

		.example-box {
			padding: 10px 10px;
			border-bottom: solid 1px #ccc;
			color: rgba(0, 0, 0, 0.87);
			/*display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;*/
			cursor: move;
			background: white;
			font-size: 14px;
		}
		.example-box small { font-weight: bold }
		.example-box div span { font-weight: bold }
		
		.cdk-drag-preview {
			box-sizing: border-box;
			border-radius: 4px;
			box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
			0 8px 10px 1px rgba(0, 0, 0, 0.14),
			0 3px 14px 2px rgba(0, 0, 0, 0.12);
		}

		.cdk-drag-placeholder {
			opacity: 0;
		}

		.cdk-drag-animating {
			transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
		}

		.example-box:last-child {
			border: none;
		}

		.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
			transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
		}
		.listfull .p-card { background: rgba(21 184 0, 0.09); }
		
		.example-box-select {
			padding: 10px 10px;
			color: rgba(0, 0, 0, 0.87);
			font-size: 14px;
			white-space: normal;
		}
		::ng-deep .p-multiselect-panel .p-multiselect-items { padding:1px;}
		::ng-deep .p-multiselect-panel .p-multiselect-item { padding:0px 10px !important;}
		::ng-deep .p-multiselect-panel .p-multiselect-item.p-highlight .example-box-select { color: #fff !important; }
		::ng-deep .p-multiselect-panel .p-highlight .p-checkbox .p-checkbox-box { border-color: #fff; }
		::ng-deep .p-multiselect-panel .p-highlight .p-checkbox .p-checkbox-box .p-checkbox-icon { color:#fff; }
		::ng-deep .p-multiselect .p-multiselect-label { padding:5px 10px }
		::ng-deep .p-multiselect {margin-bottom: 10px;}
	`]
})
export class TypePrioritizeanswersComponent extends BaseQuestionComponent implements OnInit, OnDestroy {

	@Input() completeList: any[] = [];
	@Input() selectedList: any[] = [];
	@Input() summary: boolean = false;
	dragged: any[];
	max: number = 2;
	draggableDisabled: boolean = false;
	multiSelected: any[];

	constructor(public analysisService: AnalysisService, private responsiveService: ResponsiveService) {
		super(analysisService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.selectedList = [];

		this.addSub(this.analysisService.steppedForward.subscribe(() => {
			this.selectedList = [];
		}));

		this.addSub(this.analysisService.steppedBack.subscribe(() => {
			this.selectedList = [];
		}));

		let findAnswer = this.findAnswer(this.typeData.from_questionID);

		if(findAnswer) {
			Object.entries(findAnswer).forEach(([key, value]) => {
				(value as any).forEach((item:any, index:any) => {
					this.completeList.push({top: key, sub: item});
				});
			});
		}
		let findPrioAnswer = this.findAnswer(this.question.questionID);

		if(findPrioAnswer && findPrioAnswer[0]) {
			findPrioAnswer = findPrioAnswer[0].split(';');
			findPrioAnswer.map((i:any) => {
				i = i.split('#');

				let findIndex = this.completeList.findIndex(r => r.top == i[0] && r.sub == i[1]);

				if(findIndex > -1)
					this.selectedList.push({top: i[0], sub: i[1]});
			})
		}

		//console.error(this.completeList);
		this.completeList = orderBy(this.completeList, 'top', 'asc');
		this.max = this.typeData.maxcount;
		this.validate();

		this.addSub(this.analysisService.collectAnswer.pipe(take(1)).subscribe(() => {

			let answers = this.getAnswers();
			let answer = this._questionObject(this.question.questionID, answers);
			this.analysisService.returnAnswerSource.next(answer);
		}));

		this.addSub(this.responsiveService.mediaBreakpoint$.subscribe(() => {
			this.draggableDisabled = this.selectedList.length >= this.max;
		}));
	}

	getAnswers()
	{
		let answers:any = [];

		this.selectedList.map((i) => {
			answers.push(i['top']+'#'+i['sub']);
		});
		return answers.join(';');
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
		this.validate();
		this.draggableDisabled = this.selectedList.length >= this.max;
	}

	validate() {
		//console.error(this.selectedList);

		this.setValidQuestion(this.selectedList.length > 0);
	}

	ngOnDestroy(): void {

	}
}
