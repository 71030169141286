import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
	/* tslint:disable */
	selector: '[ddClearInput]',
	exportAs: 'ddClearInput'
})

export class ClearInputDirective implements OnInit, OnChanges {

	@Input() ddClearInput: any;

	constructor(private renderer: Renderer2, private el: ElementRef) {
	}

	ngOnChanges(change:any) {

	}
	//on Init add x to text box
	ngOnInit() {
		//console.error('clear input directive  ngOnInit');
		let me = this.el.nativeElement as HTMLInputElement;
		if (me.nodeName.toUpperCase() !== 'INPUT') {
			throw new Error('Invalid input type for clearableInput:' + me);
		}
		this.el.nativeElement.parentNode.className = this.el.nativeElement.parentNode.className + ' dd-table-filter-clearer-wrapper';
		let wrapper = document.createElement('span') as HTMLSpanElement;
		wrapper.className = 'dd-table-filter-clearer';

		let searchIcon = document.createElement('i') as HTMLElement;
		// // calls the clearvalue function
		searchIcon.addEventListener('click', () => this.clearValue());
		searchIcon.className = 'fa fa-times fa-1x text-muted';

		wrapper.appendChild(searchIcon);

		me.insertAdjacentElement('afterend', wrapper);
	}

	/*showElement() {
		console.error('showElement');
		/*let searchIcon = document.getElementById('searchIcon');
		if (searchIcon) {
			searchIcon.removeAttribute('style');
		}
	}*/

	/*
		hideElement() {
			let searchIcon = document.getElementById('searchIcon');
			if (searchIcon) {
				searchIcon.setAttribute('style', 'display:none');
			}
		}
	*/
	clearValue() {
		if (this.el.nativeElement.value != '') {
			this.ddClearInput = '';
			this.el.nativeElement.value = '';
			this.el.nativeElement.dispatchEvent(new Event('input'));
		}
	}
}
