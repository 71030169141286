<div class="flex gap-1 flex-wrap">
    <span *ngIf="projects.fields.length == 0" class="font-italic text-muted py-1 mr-1">Finns inga projekt.</span>
    <span class="{{classes}} {{extraClasses}}" *ngFor="let t of projects.fields">{{t.CustomFieldAlternativeValue}}</span>

    <ng-container *ngIf="showAddButton && selectabeProjects.length > 0">

        <button pButton type="button" class="p-button-raised p-button p-button-sm py-1 px-2 text-nowrap"
                pTooltip="Ge personen åtkomst till fler projekt ditt företag är medlem i." tooltipPosition="top"
                (click)="op.toggle($event)">
            <i class="fas fa-add"></i>
        </button>

        <p-overlayPanel #op (onHide)="reset()">
            <ng-template pTemplate="content">
                <div class="flex">
                    <p-selectButton [options]="selectabeProjects" [(ngModel)]="addToProjects" [multiple]="true" optionLabel="CustomFieldAlternativeValue" styleClass="p-button-tags" [disabled]="isLoading"></p-selectButton>

                    <button pButton type="button" class="p-button-raised p-button p-button-sm py-1 text-nowrap ml-2" [disabled]="addToProjects.length == 0 || isLoading" (click)="saveProjects()">
                        <i class="fas fa-save mr-2"></i> Spara
                    </button>
                </div>
            </ng-template>
        </p-overlayPanel>

    </ng-container>
</div>
