import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOnCategories'
})
export class FilterOnCategoriesPipe implements PipeTransform {

  transform(value: any, filter: any[]): unknown {
	  // value = {data: value, key: idkey to check
	  // value = form table list
	  // filter valda kategorier

	  if (value === undefined || value === null || filter.length == 0 || filter[0] == 0) {
		  return value;
	  }

	  if (typeof value == 'string') {
		  value = [value];
	  }

	  //console.error('filterOnCategories', value, filter);

	  const filtered = value.filter(
		  function (e: any) {
			  return this.indexOf(e.CategoryId) > -1;
		  },
		  filter
	  );

	  return filtered;
  }

}
