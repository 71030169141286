import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@app/i18n';
import { Logger, untilDestroyed } from '@core';
import { AuthenticationService } from '@core/auth';

import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from '@shared/services/update.service';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

const log = new Logger('App');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styles: [],
})
export class AppComponent implements OnInit, OnDestroy {
	version: string | null = environment.version;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private translateService: TranslateService,
		private zone: NgZone,
		// do not remove the analytics injection, even if the call in ngOnInit() is removed
		// this injection initializes page tracking through the router
		//private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
		private i18nService: I18nService,
		private authenticationService: AuthenticationService,
		private sw: UpdateService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.sw.checkForUpdates();
	}

	async ngOnInit() {
		// Setup logger
		if (environment.production) {
			Logger.enableProductionMode();
		}
		console.info('::: AKSE version ' + this.version);

		log.debug('init');

		if (this.authenticationService.currentAuth) {
			if (this.authenticationService.currentAuth.leader_login) {
				await this.authenticationService.validateLeaderAuth();
			} else {
				await this.authenticationService.validateAuth();
			}
		}

		//this.validateAuth().then();
		//this.angulartics2GoogleAnalytics.startTracking();
		//this.angulartics2GoogleAnalytics.eventTrack(environment.version, {category: 'App initialized'});

		// Setup translations
		//this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

		const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

		// Change page title on navigation or language change, based on route data
		merge(this.translateService.onLangChange, onNavigationEnd)
			.pipe(
				map(() => {
					let route = this.activatedRoute;
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				switchMap((route) => route.data),
				untilDestroyed(this)
			)
			.subscribe((event) => {
				const title = event.title;
				if (title) {
					this.titleService.setTitle(this.translateService.instant(title));
				}
				/*console.error("gtag('event', 'page_view'");
				gtag('event', 'page_view', {
					page_title: title,
					page_path: event.urlAfterRedirects,
					page_location: this.document.location.href
				})*/
			});
		// Cordova platform and plugins initialization
		document.addEventListener(
			'deviceready',
			() => {
				this.zone.run(() => this.onCordovaReady());
			},
			false
		);
	}


	ngOnDestroy() {
		this.i18nService.destroy();
	}

	private onCordovaReady() {
		log.debug('device ready');

		if ((window as any).cordova) {
			log.debug('Cordova init');
		}
	}
}
