<app-loader [isLoading]="isLoading"></app-loader>
<div *ngIf="analyze" @FadeIn class="container-flush">

    <div class="row justify-content-center align-items-center pt-4">
        <div class="col-lg-12 col-xl-10 m2 b0 text-center rounded">
            <h3 class="my-3">{{analyze.name}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-8 offset-md-2 pt-2">
            <p-card [style]="{width: '100%'}" styleClass="ui-card-shadow d-flex flex-column px-3">
                <div [innerHTML]="analyze.description" class="mb-3"></div>
                <div>
                    <!-- Extended display of group info -->
                    <ng-container *ngIf="analyze.show_group_info == 'extended'">
                        <!--<h5>Denna analys innehåller frågor inom följande områden:</h5>-->
                        <ul class="list-unstyled mb-4 mt-4 pl-2">
                            <li *ngFor="let group of analyze.groups" class="mb-2">
                                <div class="mb-3">
                                    <h6 class="pb-2 mb-2">
                                        <i class="fa fa-circle text-primary text-sm"></i>
                                        {{group.name}} ({{group.group_question_count}} frågor)
                                    </h6>
                                    <hr class="line-delimiter mt-2" />
                                    <div [innerHTML]="group.description"></div>
                                </div>
                            </li>
                        </ul>
                    </ng-container>

                    <!-- Simple display of group info, just shows question count -->
                    <ng-container *ngIf="analyze.show_group_info == 'simple'" >
                        <p class="mt-4 text-muted text-md font-italic">Du kommer behöva svara på {{analyze.question_count}} frågor.</p>
                    </ng-container>

                    <div class="mt-3" [innerHTML]="analyze.footer_desc" *ngIf="analyze.footer_desc != ''"></div>
                </div>

                <div class="d-md-flex justify-content-between">
                    <span class="d-block text-primary h6 mt-2 pb-3" *ngIf="analyze.finished && analyze.finished > 0">
                        <i class="far fa-check-circle"></i>

                        <ng-container *ngIf="analyze.results.length > analyze._countFinished;else oneResult">
                            {{analyze._countFinished}} av {{analyze.results.length}} resultat slutförda!
                        </ng-container>
                        <ng-template #oneResult>
                            Slutförd!
                        </ng-template>

                    </span>
                    <span *ngIf="!analyze.finished"></span>

                    <div class="flex gap-2 flex-column flex-sm-row" *ngIf="!isSurvey">

                        <!-- Om flera resultat finns (eg. 1 slutfört och 1 ej slutfört) -->
                        <button pButton type="button" label="Fortsätt påbörjad" icon="pi pi-play" class="p-button-secondary" iconPos="right"
                                [routerLink]="['/analyser/start', analyze.analyzeID]" *ngIf="analyze._unfinishedResultID > 0"></button>

                        <button pButton type="button" label="Gör igen" icon="pi pi-replay" class="p-button-secondary"
                                [routerLink]="['/analyser/start', analyze.analyzeID]" *ngIf="analyze.started > 0 && analyze.finished > 0 && !analyze.allow_revise && analyze.results.length == analyze._countFinished"></button>

                        <button pButton type="button" label="Påbörja" icon="pi pi-play" iconPos="right"
                                [routerLink]="['/analyser/start', analyze.analyzeID]" *ngIf="analyze.finished == 0 && analyze.started == 0"></button>
                        <button pButton type="button" label="Fortsätt" icon="pi pi-play" iconPos="right"
                                [routerLink]="['/analyser/start', analyze.analyzeID]" *ngIf="analyze.started > 0 && analyze.finished == 0 && analyze._unfinishedResultID == 0"></button>
                        <button pButton type="button" label="Se senaste resultatet" icon="pi pi-chart-bar"
                                [routerLink]="['/analyser/resultat', analyze.analyzeID, analyze._latestFinishedResultID]" *ngIf="analyze.started > 0 && analyze.finished > 0"></button>


                    </div>

                    <div class="float-right" *ngIf="isSurvey">
                        <button pButton type="button" label="Påbörja" icon="pi pi-play" iconPos="right"
                                [routerLink]="['/analyser/start', analyze.analyzeID, courseID]" *ngIf="analyze.finished == 0 && analyze.started == 0"></button>
                        <button pButton type="button" label="Fortsätt" icon="pi pi-play" iconPos="right"
                                [routerLink]="['/analyser/start', analyze.analyzeID, courseID]" *ngIf="analyze.started > 0 && analyze.finished == 0"></button>
                    </div>
                </div>
            </p-card>

            <div *ngIf="analyze.results.length > 0" class="mt-5">
                <p-divider align="center">
                    <div class="inline-flex align-items-center">
                        <i class="pi pi-chart-line mr-2"></i>
                        <b>Dina resultat</b>
                    </div>
                </p-divider>

                <ng-container *ngFor="let result of analyze.results | sortBy:'asc':'finished'">
                    <div class="border-round-md p-3 bg-white shadow-sm flex justify-content-between align-items-center mb-3 flex-wrap flex-column flex-lg-row">
                        <div>
                            Startad: {{result.started * 1000 | date: 'd MMM HH:mm'}}
                        </div>
                        <div>
                            <ng-container *ngIf="result.finished > 0">Slutförd: {{result.finished * 1000 | date: 'd MMM HH:mm'}}</ng-container>
                            <div *ngIf="result.finished == 0" class="text-muted font-italic">Ej slutförd</div>
                        </div>
                        <div>
                            <button pButton type="button" class="p-button-sm" label="Se resultat" icon="pi pi-chart-bar"
                                    [routerLink]="['/analyser/resultat', analyze.analyzeID, result.resultID]" *ngIf="result.started > 0 && result.finished > 0"></button>
                            <button pButton type="button" class="p-button-sm" label="Fortsätt" icon="pi pi-play" iconPos="right"
                                    [routerLink]="['/analyser/start', analyze.analyzeID, courseID]" *ngIf="result.started > 0 && result.finished == 0"></button>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>

</div>

<div *ngIf="analyze && analyze.finished > 0 && analyzeID == 3 && false" @FadeIn class="container-flush">


    <div class="mb-4 pt-4 px-3">
        <h2 class="text-primary"><i class="far fa-check-circle"></i> Digital Mognadsanalys slutförd!</h2>
        <p class="mt-3">
            Här nedan hittar du ditt sammanställda Digital Mognads Index (DMI)
        </p>
    </div>

    <dd-analyse-results [showDMI]="true"></dd-analyse-results>
</div>
