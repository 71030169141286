import { Component, Input, OnInit } from '@angular/core';
import { ClientConfigService } from '@shared/services/client-config.service';
import { CoursesService } from '@shared/services/courses.service';

@Component({
	selector: 'dd-course-type-tag',
	templateUrl: './course-type-tag.component.html',
	styles: [`
		::ng-deep .badge-courstype {
			height: 17px;
			width: 17px;
		}
		::ng-deep .tag-desc-full {
			line-height:200%;
		}
	`]
})
export class CourseTypeTagComponent implements OnInit {
	get type(): string | string[] | object[] {
		return this._type;
	}

	@Input() set type(value: string | string[] | object[]) {
		this.isString = typeof value === 'string';
		this._type = value;
	}

	private _type: string | string[] | object[];

	isString: boolean;
	@Input() showDesc: boolean = false;
	@Input() minimal: boolean = false;
	@Input() showReadMore: boolean = false;

	loadType: any[] = [];
	visible: boolean = false;

	constructor(private couseService: CoursesService, private configService: ClientConfigService) {
	}

	ngOnInit() {
		if(!this.type)
			return;

		if (!this.isString) {
			for (let typeElement of this.type) {
				if(!typeElement)
					continue;

				if(typeof typeElement !== "string") {
					if(typeElement['showAsFilter'] == false)
						continue;
					this.loadType.push(typeElement);
				} else {
					this.loadType.push(this.configService.find('courseTypeBadge', 'type', <string>typeElement));
				}
			}
		} else {
			this.loadType.push(this.configService.find('courseTypeBadge', 'type', <string>this.type));
		}
	}

	alert(number: number) {

	}
}
