
<p-card styleClass="d-flex flex-column text-left position-relative">

    <span *ngIf="_isNewCourse" class="course-card-new-badge badge badge-pill badge-primary">NY</span>

    <ng-template pTemplate="header">
        <div [ngStyle]="{'background-image': 'url('+course.ImageUrl+')'}" class="list-type-grid-header cursor-pointer" [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]"></div>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="flex gap-2 text-sm">
            <dd-course-type-tag [type]="course._CourseTypeList"></dd-course-type-tag>
            <dd-category-tag [split]="true" [tag]="course.Categories" [invert]="false"></dd-category-tag>
        </div>
        <div class="p-card-title text-lg mt-3 cursor-pointer" [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">{{course.CourseName}}</div>
        <div class="text-md course-card-short-desc" [innerHTML]="course.CourseDescriptionShort"></div>

    </ng-template>
    <ng-template pTemplate="footer">
        <div class="text-md font-bold course-card-footer-dates">
            <dd-course-date [course]="course" [showWeekDay]="true"
                            [showEndTime]="true" [showMoreAvailable]="true"></dd-course-date>
        </div>
        <button pButton pRipple
                type="button" label="Läs mer och boka här" icon="fa-solid fa-arrow-right-long" iconPos="right"
                class="mt-3 p-button-sm p-button-rounded p-button-primary cursor-pointer p-button-raised"
                [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">
        </button>
    </ng-template>
    <!--
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-between">
            <div>
                <span class="badge badge-pill badge-primary font-medium">{{course._FutureEvents}} st</span>
                <span class="text-500">kommande tillfällen</span>
            </div>
            <div>
                <span class="badge badge-pill badge-grey ml-1" *ngFor="let type of course._CourseTypeList">{{type}}</span>
            </div>
        </div>
    </ng-template>-->
</p-card>
