<div class="container pb-4">

    <div class="row justify-content-center align-items-center mb-3">
        <div class="col-lg-12 col-xl-10 m2 b0 text-center rounded">
            <h3 class="mt-3 mb-2">Hållbart och inkluderande företagande</h3>
            <div class="text-lg mb-4">På den här sidan får du information om hur du i ditt företag kan arbeta med hållbarhet, tillgänglighet och jämställdhet. Genom att integrera och prioritera dessa områden kan företaget bland annat minska sina kostnader och stärka sitt varumärke som en ansvarsfull aktör i samhället. </div>
        </div>
    </div>

    <div class="card-box border-round-big px-5 py-4 shadow bg-white mx-0 mx-lg-5 mb-5">
        <div class="row">
            <div class="col-12 col-lg-6 text-center">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="470" height="265" src="https://www.youtube.com/embed/bT5BgJc2oOM?si=WegJfOdzrzYxf0nm&amp;controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <p class="font-bold  mt-3 mb-0">
                    <i class="fa-solid fa-arrow-up-long"></i> Video från Företagarna - 7 smarta tips om hållbart företagande
                </p>
            </div>
            <div class="col-12 col-lg-6 pt-3">

                <h4>Hållbart företagande</h4>

                <p>Att använda resurser smartare, bli mer konkurrenskraftig och tjäna mer pengar, är att driva företaget hållbart. Genom att tänka på miljön, samhället och ekonomin i dina produkter och tjänster kan du hitta nya affärsmöjligheter, få fler kunder och minska företagets påverkan på omvärlden.</p>

                <div class="row">
                    <div class="col-6">
                        <p><strong>ETU.se</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://etu.se/" target="_blank"
                                    icon="fa-solid fa-arrow-right-long" iconPos="right" label="Läs mer här"></a>
                        </p>

                        <p><strong>Hall&aring;konsument.se:</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://www.hallakonsument.se/
omrade/miljo-och-hallbarhet/" target="_blank"
                                    icon="fa-solid fa-arrow-right-long" iconPos="right" label="Läs mer här"></a>
                        </p>

                        <p><strong>F&ouml;retagarna - Vad &auml;r h&aring;llbart f&ouml;retagande:</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://www.foretagarna.se/innehallsbank/riks/2019/mars/vad-ar-hallbart-foretagande/
" target="_blank"
                               icon="fa-solid fa-arrow-right-long" iconPos="right" label="Läs mer här"></a>
                        </p>
                    </div>
                    <div class="col-6">
                        <p><strong>F&ouml;retagarna - Digitala verktyg och guider som hj&auml;lper dig att arbeta h&aring;llbart:</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://www.foretagarna.se/innehallsbank/riks/2019/mars/digitala-verktyg-och-guider-som-hjalper-dig-att-arbeta-hallbart/" target="_blank"
                               icon="fa-solid fa-arrow-right-long" iconPos="right" label="Läs mer här"></a>
                        </p>
                        <p><strong>Poddtips: Jobba H&aring;llbart-podden</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://www.jobbahallbart.se/podd/" target="_blank"
                               icon="fa-solid fa-arrow-right-long" iconPos="right" label="Lyssna här"></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="card-box border-round-big px-5 py-4 shadow bg-white mx-0 mx-lg-5 mb-5">
        <div class="row">
            <div class="col-12 col-lg-6 text-center">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="470" height="265" src="https://www.youtube.com/embed/6a-5gVrjDz8?si=VQZ8XsfLuY31PuI2&amp;controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

            </div>
            <div class="col-12 col-lg-6 pt-3">

                <h4>Tillgänglighet</h4>

                <p>Tillgänglighet innebär att göra platser, produkter och tjänster enkla att använda för alla, oavsett funktionsförmåga. Det handlar om att ta bort hinder så att alla människor kan delta i samhället på lika villkor.</p>
                <div class="row">
                    <div class="col-9">
                        <p class="font-bold">
                            <i class="fa-solid fa-arrow-left-long"></i> Video från Diskriminerings-ombudsmannen <br />DO - Hur tillgängligt är din verksamhet?
                        </p>
                        <p>
                            <strong>Myndigheten för delaktighet:</strong>
                            <br />
                            <a href="https://www.mfd.se/">https://www.mfd.se/</a>
                        </p>
                        <p>
                            <strong>Hållbart företagande - verksamt.se</strong>
                            <br />
                            <a href="https://verksamt.se/hallbart-foretagande">https://verksamt.se/hallbart-foretagande</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-box border-round-big px-5 py-4 shadow bg-white mx-0 mx-lg-5 mb-5">
        <div class="row">
            <div class="col-12 col-lg-6 text-center">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="470" height="265" src="https://www.youtube.com/embed/rErRjfGlGXg?si=7_OPN7kBuRNaxYrM&amp;controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <p class="font-bold  mt-3 mb-0">
                    <i class="fa-solid fa-arrow-up-long"></i> Video från VKNA - Vad företag vinner för jämställdhet
                </p>
            </div>
            <div class="col-12 col-lg-6 pt-3">

                <h4>Jämställdhet</h4>

                <p>Jämställdhet innebär att ge alla, oavsett kön, samma möjligheter och rättigheter inom arbetslivet. Det handlar om att skapa en rättvis arbetsplats där både kvinnor och män kan växa och bidra på lika villkor, vilket leder till ett mer kreativt och framgångsrikt företag.</p>

                <div class="row">
                    <div class="col-6">
                        <p>
                            <strong>Jämställdhetsmyndigheten </strong><br />
                            <a href="https://jamstalldhetsmyndigheten.se/">https://jamstalldhetsmyndigheten.se/</a>
                        </p>
                        <p><strong>Vägledning för jämställdhetsintegrering:</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://jamstalldhetsmyndigheten.se/jamstalldhetsintegrering/vagledning-for-jamstalldhetsintegrering/" target="_blank"
                               icon="fa-solid fa-arrow-right-long" iconPos="right" label="Läs mer här"></a>
                        </p>
                    </div>
                    <div class="col-6">
                        <p><strong>Poddtips "Så skapar vi inkluderande arbetsplatser":</strong></p>
                        <p>
                            <a pButton type="button" class="p-button-raised p-button-sm py-1" href="https://poddtoppen.se/podcast/1558657122/jamstallt-arbetsliv/29-sa-skapar-vi-inkluderande-arbetsplatser" target="_blank"
                               icon="fa-solid fa-arrow-right-long" iconPos="right" label="Lyssna här"></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
