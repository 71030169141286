<div class="container pb-4">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Integritetsskyddspolicy</h2>
        <h3>Aff&auml;rskompetens Sverige Ideell f&ouml;rening</h3>
        <div class="row mt-3">
            <div class="col-12">
                <div>
                    <div>
                        <p>Styrelsen f&ouml;r Aff&auml;rskompetens Sverige, organisationsnummer 802525-0856, med registrerad postadress p&aring; c/o DOSPACE, Drottninggatan 18, 803 20 G&auml;vle
                            (&rsquo;Ideella f&ouml;reningen&rsquo; eller &rdquo;Aff&auml;rskompetens&rdquo;) &auml;r personuppgiftsansvarig och behandlar dina personuppgifter n&auml;r du bes&ouml;ker
                            Ideella f&ouml;reningens hemsida eller&nbsp;anv&auml;nder Ideella f&ouml;reningens verktyg samt andra tj&auml;nster som hanteras av Ideella f&ouml;reningen. Du
                            samtycker h&auml;rmed till Ideella f&ouml;reningens behandling av dina personuppgifter p&aring; de villkor som anges enligt nedan. Villkoren f&ouml;reskriver
                            vilka av dina personuppgifter som behandlas, hur &aring;tkomst till dessa ges, hur de behandlas och till vilket syfte, samt grunderna f&ouml;r hur detta lagenligt
                            sker. F&ouml;r mer information om dina r&auml;ttigheter s&aring;som registrerad, se &rdquo;Kontaktuppgifter nedan&rdquo;.</p>
                        <p>Den h&auml;r integritetsskyddspolicyn g&auml;ller f&ouml;r denna webbplats och andra interaktioner (t.ex. n&auml;r du skapar ett konto p&aring; webbplatsen, k&ouml;per
                            eller registrerar dig f&ouml;r v&aring;r produkt eller tj&auml;nst, registrerar dig f&ouml;r v&aring;rt nyhetsbrev, registrerar dig f&ouml;r uppdateringar, deltar
                            vid v&aring;ra &ouml;ppna Webinars, n&auml;r du kontaktar oss via e-post eller telefon eller kontaktar oss via sociala medier) som du kan ha med oss.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>1. Kontaktuppgifter</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>1.1 Dataskyddsombud<br/>Vi har utsett ett dataskyddsombud vars uppgift &auml;r att granska att vi f&ouml;ljer regler, riktlinjer och rutiner. Dataskyddsombudet
                            &auml;r verksamhetsansvarig Sara Hellberg.</p>
                        <p>Om du har n&aring;gra fr&aring;gor eller klagom&aring;l om hur vi hanterar dina personuppgifter, v&auml;nligen kontakta oss via e-post eller telefon.</p>
                        <p>Kontaktuppgifter till Dataskyddsombudet:<br/>Sara Hellberg<br/>sara.hellberg@affarskompetens.se<br/>Tel: 073-386 56 21</p>
                        <p>Ideella f&ouml;reningens postadress &auml;r:<br/>Aff&auml;rskompetens Sverige c/o DOSPACE, Drottninggatan 18, 803 20 G&auml;vle</p>
                        <p>1.2 Betr&auml;ffande klagom&aring;l kan du ocks&aring; kontakta tillsynsmyndigheten f&ouml;r personuppgiftsbehandling: Integritetsskyddsmyndigheten, org.nr. 202100&ndash;0050,
                            med adress Box 8114, 104 20 Stockholm, med telefon 08-657 61 00, samt med e-post imy@imy.se.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>2. Personuppgiftsansvaret samt delning</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>2.1 Personuppgifter &auml;r all information som direkt eller indirekt (dvs. tillsammans med andra uppgifter) kan kopplas till dig, t ex namn, bild, personnummer,
                            eller IP-adress. Vi kan samla in och behandla dina personuppgifter p&aring; olika s&auml;tt. N&auml;r Ideella f&ouml;reningen behandlar dina personuppgifter
                            &auml;r styrelsen f&ouml;r Ideella f&ouml;reningen personuppgiftsansvarig. Forts&auml;ttningsvis h&auml;nvisas till Ideella f&ouml;reningen som
                            personuppgiftsansvarig men med det menas att styrelsen &auml;r personuppgiftsansvarig. Med &rsquo;personuppgifter&rsquo; menas varje upplysning som avser en
                            identifierad eller identifierbar fysisk person. Att Ideella f&ouml;reningen &auml;r personuppgiftsansvarig inneb&auml;r att Ideella f&ouml;reningen ensamt eller
                            tillsammans med andra best&auml;mmer &auml;ndam&aring;len och medlen f&ouml;r behandlingen av personuppgifter. Med behandling av personuppgifter menas varje
                            process som inneh&aring;ller personuppgifter. Behandling som kan betraktas som hantering av personuppgifter kan exempelvis vara insamling och sortering av
                            personuppgifter med avsikt att l&auml;sa, anv&auml;nda, justera och / eller radera information.</p>
                        <p>2.2 Ideella f&ouml;reningen &auml;ger alltid r&auml;tt att behandla personuppgifter genom att dela dem med bolaget aff&auml;rskompetens sverige ab
                            organisationsnummer 559401-6437 &rdquo;bolaget&rdquo;. Ideella f&ouml;reningen behandlar &auml;ven personuppgifter som &ouml;verf&ouml;rs fr&aring;n bolaget till
                            ideella f&ouml;reningen. Syftet f&ouml;r denna behandling &auml;r f&ouml;r att ideella f&ouml;reningen och bolaget samarbetar f&ouml;r att kunna erbjuda s&aring;
                            bra tj&auml;nster som m&ouml;jligt till sina medlemmar och kunder samt att n&ouml;dv&auml;ndig avrapportering till myndigheter och organisationer kan utf&ouml;ras.
                            Ideella f&ouml;reningen och bolaget behandlar &auml;ven personupgifter gemensamt i syfte att kommunicera och samarbeta internt mellan ideella f&ouml;reningen och
                            bolaget. Bolaget och den ideella f&ouml;reningen &auml;r gemensamt personuppgiftsansvariga f&ouml;r ded behandlingar man gemensamt best&auml;mmer &auml;ndam&aring;l
                            och medel f&ouml;r.</p>
                        <p>2.3 Om en underleverant&ouml;r anlitas av Ideella f&ouml;reningen, ska Ideella f&ouml;reningen s&auml;kerst&auml;lla att dina personuppgifter ges ett likv&auml;rdigt
                            skydd som anges i dessa villkor. Ideella f&ouml;reningen kan &auml;ven komma att ge tredje parter tillg&aring;ng till dina personuppgifter, s&aring;som men ej
                            begr&auml;nsat till leverant&ouml;rer, bank, och redovisningssystem, molntj&auml;nstleverant&ouml;rer, konsulter, eller myndigheter. &Auml;ven andra parter kan
                            ges tillg&aring;ng till dina personuppgifter enligt lag. Ideella f&ouml;reningen ska d&auml;remot fortsatt vara personuppgiftsansvarig. Personuppgifter kommer att
                            lagras i en databas f&ouml;r de syften som beskrivits ovan.</p>
                        <p>2.4 Aff&auml;rskompetens s&auml;ljer aldrig dina uppgifter till en tredje part. Vi kan dock komma att l&auml;mna ut dina personuppgifter till f&ouml;ljande
                            kategorier av mottagare:<br/>(a) Leverant&ouml;rer, b&aring;de inom och utanf&ouml;r EU/EES, som t ex tillhandah&aring;ller IT-tj&auml;nster, hanterar medlems&auml;renden
                            och marknadsf&ouml;r f&ouml;r Aff&auml;rskompetens r&auml;kning.<br/>(b) Samarbetspartners som tillhandah&aring;ller medlemsf&ouml;rm&aring;ner.<br/>(c) Andra
                            mottagare n&auml;r det f&ouml;ljer av lagkrav, annan f&ouml;rfattning eller myndighetsbeslut.<br/>(d) Sociala medie-akt&ouml;rer, s&aring;som t.ex. Facebook och
                            LinkedIn.</p>
                        <p>2.5 Mottagare som hanterar personuppgifter f&ouml;r Aff&auml;rskompetens r&auml;kning ska alltid ing&aring; ett s&aring; kallat personuppgiftsbitr&auml;desavtal i
                            syfte att vi ska kunna s&auml;kerst&auml;lla att dina uppgifter hanteras p&aring; korrekt och s&auml;kert s&auml;tt. N&auml;r dina personuppgifter delas med en
                            mottagare som &auml;r sj&auml;lvst&auml;ndigt personuppgiftsansvarig, t ex en myndighet eller en bank, g&auml;ller mottagarens integritetspolicy och information
                            om personuppgiftshantering.</p>
                        <p>2.6 Om Ideella f&ouml;reningen &ouml;verf&ouml;r dina personuppgifter till ett tredjeland, dvs. ett land utanf&ouml;r EU/EES, eller till internationella
                            organisationer ska Ideella f&ouml;reningen d&aring; s&auml;kerst&auml;lla att det landet uppr&auml;tth&aring;ller samma adekvata skyddsniv&aring; som om
                            behandlingen hade skett inom EU/EES. Du hittar mer information om vilka l&auml;nder som anses ha en &ldquo;adekvat skyddsniv&aring;&rdquo; p&aring; EU
                            Kommissionens hemsida, och du kan l&auml;sa mer om standardklausuler p&aring; Integritetsskyddsmyndighetens hemsida www.imy.se.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>3. Vilka personuppgifter behandlar vi?</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>3.1 Som anst&auml;lld hos Ideella f&ouml;reningen: vid jobbans&ouml;kan eller anst&auml;llningen (inkl. f&ouml;r tidigare anst&auml;llda) hanteras personuppgifter
                            i form av namn, adress, personnummer, telefonnummer, e-postadress, k&ouml;n, s&ouml;kt tj&auml;nst, tillg&auml;nglighet, anteckningar och summering av intervjuer
                            och inskickat material vilket omfattar personligt brev, CV, &ouml;vriga meriter, samt utbildningsintyg. Personuppgifterna hanteras f&ouml;r att kunna etablera och
                            uppr&auml;tth&aring;lla en kommunikation med den jobbans&ouml;kande g&auml;llande den tj&auml;nst som &auml;r aktuell. Behandlingen av personuppgifter &auml;r n&ouml;dv&auml;ndig
                            f&ouml;r att kunna tillgodose Ideella f&ouml;reningens och ditt intresse att hantera jobbans&ouml;kningen. Bed&ouml;mningen att hantering av jobbans&ouml;kan
                            &auml;r av ber&auml;ttigat intresse vilket vi bed&ouml;mer v&auml;ger tyngre &auml;n ditt intresse av skydd f&ouml;r din personliga integritet. Ber&auml;ttigat
                            intresse utg&ouml;r en r&auml;ttslig grund f&ouml;r Ideella f&ouml;reningen att spara dina personuppgifter. Lagring av personuppgifter sker s&aring; l&auml;nge
                            det &auml;r n&ouml;dv&auml;ndigt efter senaste kontakten eller till dess den aktuella tj&auml;nsten har tillsatts. Lagringen av personuppgifterna sker sedan
                            vidare s&aring; l&auml;nge du &auml;r anst&auml;lld och d&auml;refter endast s&aring; l&auml;nge det &auml;r n&ouml;dv&auml;ndigt efter avslutad tj&auml;nst eller
                            tills r&auml;ttslig skyldighet att beh&aring;lla uppgifterna inte l&auml;ngre &auml;r g&auml;llande. I det fall vi &ouml;nskar spara personuppgifter f&ouml;r
                            kommande aktuella tj&auml;nster s&aring; kommer vi att f&ouml;rst beg&auml;ra ditt samtycke till det. Oavsett vad som stadgas i denna integritetsskyddspolicy s&aring;
                            lagras personuppgifter i enlighet med var tid g&auml;llande lagar och f&ouml;rordningar.</p>
                        <p>3.2 Som projektdeltagare och/eller kund till Ideella f&ouml;reningen: Anv&auml;ndning av begreppet &rdquo;kund&rdquo; ska f&ouml;rst&aring;s som projektdeltagare
                            och/eller kunder. Som befintlig eller tidigare kund hanteras personuppgifter i form av namn, telefonnummer, e-postadress, personnummer, betalningsinformation,
                            behov av tj&auml;nst, relevant kontakthistorik. Personuppgifterna behandlas f&ouml;r att kunna administrera och hantera avtal som slutits, och tillhandah&aring;lla
                            k&ouml;pta tj&auml;nster. Vidare behandlas personuppgifterna f&ouml;r att uppr&auml;tth&aring;lla en kommunikation med kund g&auml;llande relevanta erbjudanden av
                            tj&auml;nst. Behandlingen av personuppgifter &auml;r n&ouml;dv&auml;ndig f&ouml;r att kunna tillgodose Ideella f&ouml;reningens och ditt intresse att hantera samt
                            fullg&ouml;ra din befintliga tj&auml;nst. Ber&auml;ttigat intresse utg&ouml;r en r&auml;ttslig grund f&ouml;r Ideella f&ouml;reningen att spara dina
                            personuppgifter. Lagring av personuppgifter sker s&aring; l&auml;nge det &auml;r n&ouml;dv&auml;ndigt eller s&aring; l&auml;nge det finns en r&auml;ttslig
                            skyldighet (laglig grund r&auml;ttslig f&ouml;rpliktelse) att spara informationen. Till exempel finns skyldighet att spara all information som anv&auml;nds i bokf&ouml;rings-
                            och revisions syfte i minst sju (7) &aring;r enligt bokf&ouml;ringslagen. Vidare, i ESF-projekt sparas uppgifter i tio (10) &aring;r. Ideella f&ouml;reningen
                            &auml;ger ocks&aring; r&auml;tt att dela dina personuppgifter d&auml;r det finns en rapporteringsskyldighet gentemot en uppdragsgivare eller annan organisation
                            som kr&auml;ver det f&ouml;r n&ouml;dv&auml;ndig rapportering av finansiella medel exempelvis.</p>
                        <p>3.3 Ideella f&ouml;reningen kan komma att be dig att fylla i formul&auml;r som inkluderar dina personuppgifter. Ideella f&ouml;reningen samlar in och behandlar
                            personuppgifter som tillhandah&aring;lls i samband med ditt bes&ouml;k eller registrering p&aring; Ideella f&ouml;reningens hemsida, genom kontakt via telefon,
                            e-post och brev, d&aring; du registrerar dig f&ouml;r Ideella f&ouml;reningens nyhetsbrev, n&auml;r du l&auml;mnar en recension av Ideella f&ouml;reningen eller
                            dennes produkter, samt d&aring; tredje part tillhandah&aring;ller dina personuppgifter. Det &aring;ligger Ideella f&ouml;reningen att ansvara f&ouml;r att
                            personuppgifter behandlas korrekt och i enlighet med till&auml;mplig svensk lag och EU-r&auml;tt.</p>
                        <p>3.4 Ideella f&ouml;reningens behandling av dina personuppgifter inneb&auml;r lagring, bearbetning eller &auml;ndring, insamling, registrering, organisering,
                            strukturering, framtagning, l&auml;sning, anv&auml;ndning, utl&auml;mning genom &ouml;verf&ouml;ring, spridning eller tillhandah&aring;llande p&aring; annat s&auml;tt,
                            justering eller sammanf&ouml;rande, begr&auml;nsning, radering eller f&ouml;rst&ouml;ring. Ideella f&ouml;reningen behandlar dina personuppgifter i olika syften,
                            exempelvis genom att fullg&ouml;ra sina f&ouml;rpliktelser enligt lag eller avtal, s&auml;kerst&auml;lla dina r&auml;ttigheter, administrera och hantera ditt k&ouml;p
                            och betalning, kontakta dig f&ouml;r att informera om erbjudanden, produkter m.m.</p>
                        <p>3.5 Vi kan anv&auml;nda kunddata, anv&auml;ndardata, tekniska data och marknadsf&ouml;ringsdata f&ouml;r att leverera relevant webbplatsinneh&aring;ll och annonser
                            till dig (inklusive Facebook-annonser eller andra visningsannonser) och f&ouml;r att m&auml;ta eller f&ouml;rst&aring; effektiviteten av den reklam vi visar dig.
                            Ideella f&ouml;reningen behandlar f&ouml;ljande olika typer av personuppgifter fr&aring;n dig:</p>
                        <p>(a) Kommunikationsdata som inkluderar all kommunikation som du skickar till oss, oavsett om det &auml;r via kontaktformul&auml;ret p&aring; v&aring;r webbplats,
                            via e-post, text, meddelanden p&aring; sociala medier, inl&auml;gg p&aring; sociala medier eller annan kommunikation som du skickar till oss.</p>
                        <p>(b) Kunddata som inneh&aring;ller medlemsuppgifter om projektdeltagande och k&ouml;p av varor och/eller tj&auml;nster s&aring;som namn, titel, faktureringsadress,
                            leveransadress e-postadress, telefonnummer, kontaktuppgifter, k&ouml;puppgifter och dina kortuppgifter.</p>
                        <p>(c) Anv&auml;ndardata som inneh&aring;ller data om hur du anv&auml;nder v&aring;r webbplats och eventuella onlinetj&auml;nster tillsammans med all data som du
                            publicerar f&ouml;r publicering p&aring; v&aring;r webbplats eller via andra onlinetj&auml;nster. Detta kan inkludera produkt, inneh&aring;ll, filer, kurser, fr&aring;gesporter,
                            unders&ouml;kningar eller annat inneh&aring;ll som du laddar upp till eller skapar p&aring; webbplatsen.</p>
                        <p>(d) Teknisk data som inkluderar data om din anv&auml;ndning av v&aring;r webbplats och onlinetj&auml;nster som din IP-adress, dina inloggningsuppgifter, detaljer
                            om din webbl&auml;sare, l&auml;ngden p&aring; bes&ouml;ket p&aring; sidor p&aring; v&aring;r webbplats, sidvisningar och navigeringsv&auml;gar, detaljer om
                            antalet g&aring;nger du anv&auml;nder v&aring;r webbplats, tidszonsinst&auml;llningar och annan teknik p&aring; de enheter du anv&auml;nder f&ouml;r att komma
                            &aring;t v&aring;r webbplats. K&auml;llan till dessa data kommer fr&aring;n v&aring;rt analyssp&aring;rningssystem.</p>
                        <p>(e) Marknadsf&ouml;ringsdata som inneh&aring;ller data om dina preferenser f&ouml;r att ta emot marknadsf&ouml;ring fr&aring;n oss och v&aring;ra tredje parter och
                            dina kommunikationspreferenser.</p>
                        <p>(f) Data fr&aring;n tredjeparter och/eller offentliga k&auml;llor som kommer fr&aring;n tredje part utanf&ouml;r EU s&aring; som tex. analysleverant&ouml;rer som
                            tex Google, annonsn&auml;tverk som Facebook, s&aring;som s&ouml;kinformationsleverant&ouml;rer som Google, leverant&ouml;rer av tekniska betalningar, som Kwikk,
                            Stripe och PayPal och leveranstj&auml;nster, s&aring;som datam&auml;klare eller aggregatorer. N&auml;r en tredjepartstj&auml;nst &auml;r aktiverad har vi beh&ouml;righet
                            att ansluta och komma &aring;t annan information som g&ouml;rs tillg&auml;nglig f&ouml;r oss i enlighet med v&aring;rt avtal med tredjepartsleverant&ouml;ren. Vi
                            tar dock inte emot eller lagrar l&ouml;senord f&ouml;r n&aring;gon av dessa tredjepartstj&auml;nster n&auml;r vi ansluter dem till tj&auml;nsterna.
                            Tredjepartsintegrationer kan bland annat inkludera:</p>
                        <ul>
                            <li>Zapier.com<br/>&bull;&nbsp;MailChimp.com<br/>&bull;&nbsp;Google.com<br/>&bull;&nbsp;Facebook.com<br/>&bull;&nbsp;Segment.io</li>
                        </ul>
                        <p>3.6 I de fall d&auml;r vi &auml;r skyldiga att samla in personuppgifter enligt lag eller enligt villkoren i projektdeltagaravtalet mellan oss och du inte f&ouml;rser
                            oss med dessa uppgifter vid beg&auml;ran, kanske vi inte kan utf&ouml;ra avtalet (till exempel f&ouml;r att leverera varor eller tj&auml;nster till dig). Om du
                            inte f&ouml;rser oss med de beg&auml;rda uppgifterna kan vi tex beh&ouml;va avbryta ditt deltagande av v&aring;ra tj&auml;nster. Om vi g&ouml;r det kommer vi att
                            meddela dig.</p>
                        <p>3.7 Ideella f&ouml;reningen f&ouml;rbeh&aring;ller sig r&auml;tten att samla in personuppgifter genom anv&auml;ndning av cookie-filer som sparas p&aring; din dator
                            eller telefon och som anv&auml;nds i syfte att identifiera din webbl&auml;sare f&ouml;r att k&auml;nna igen dina inst&auml;llningar och preferenser. Du har r&auml;tt
                            att neka anv&auml;ndningen av cookie-filer. Notera &auml;ven att de personuppgifter som tillhandah&aring;lls av dig kommer att samlas in och lagras av Ideella f&ouml;reningen.
                            L&auml;s l&auml;ngre ned p&aring; sidan om hur vi behandlar cookies.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>4. K&auml;nsliga personuppgifter</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>4.1 Vi skyddar dina k&auml;nsliga personuppgifter med s&auml;rskilt skyddade tekniska &aring;tg&auml;rder samt inh&auml;mtar alltid samtycke fr&aring;n dig innan
                            vi f&aring;r lagra dessa k&auml;nsliga personuppgifter. Ideella f&ouml;reningen kommer inte som huvudregel att samla in k&auml;nsliga personuppgifter om dig. Om
                            tj&auml;nsten behandlar uppgifter som utg&ouml;r k&auml;nsliga personuppgifter (exempelvis om mat allergier, behov av hj&auml;lpmedel) sker Ideella f&ouml;reningens
                            behandling baserat p&aring; ditt uttryckliga samtycke (Artikel 9(2)(a) GDPR). Vidare kommer Ideella f&ouml;reningen endast behandla de personuppgifter som &auml;r
                            n&ouml;dv&auml;ndiga mot bakgrund av syftet med behandlingen.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>5. Ideella f&ouml;reningens lagliga grund f&ouml;r behandlingen</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>5.1 F&ouml;r det fall Ideella f&ouml;reningen inte tillhandah&aring;lls erforderliga personuppgifter, ges Ideella f&ouml;reningen inte m&ouml;jlighet att uppfylla
                            sina skyldigheter under avtalet med dig och andra parter. Ideella f&ouml;reningen lyder under Europaparlamentets och r&aring;dets f&ouml;rordning (EU) 2016/679 av
                            den 27 april 2016 om skydd f&ouml;r fysiska personer med avseende p&aring; behandling av personuppgifter och om det fria fl&ouml;det av s&aring;dana uppgifter och
                            om upph&auml;vande av direktiv 95/46/EG (allm&auml;n dataskyddsf&ouml;rordning / GDPR). Ideella f&ouml;reningen lyder &auml;ven under nationell
                            dataskyddslagstiftning, f&ouml;rutsatt att den &auml;r till&auml;mplig, likv&auml;l som tillsynsmyndighets beslut.</p>
                        <p>5.2 D&aring; du samtycker till att Ideella f&ouml;reningen behandlar dina personuppgifter, utg&ouml;r det en laglig grund f&ouml;r behandlingen. Den lagliga
                            grunden f&ouml;r Ideella f&ouml;reningen &auml;r dessutom att fullg&ouml;ra sina f&ouml;rpliktelser under avtal, samt Ideella f&ouml;reningens ber&auml;ttigade
                            intresse att behandla personuppgifter f&ouml;r exempelvis direktmarknadsf&ouml;ring. Ideella f&ouml;reningen kan ha en skyldighet enligt lag att behandla dina
                            personuppgifter, exempelvis f&ouml;r att f&ouml;lja tillsynsmyndighets beslut, betala skatter och avgifter, eller i bokf&ouml;ringssyfte. Du kan l&auml;sa mer om
                            v&aring;rt syfte med personuppgiftshanteringen samt den specifika lagliga grunden i</p>
                    </div>
                </div>
                <div><a href="https://www.affarskompetenssverige.se/wp-content/uploads/2023/03/230228-bilaga-1-till-integritetsskyddspolicy-ideell-forening.pdf">Bilaga 1</a></div>
                <div>
                    <div>
                        <p><strong>6. Personuppgiftsincidenter</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>6.1 Med &rsquo;personuppgiftsincident&rsquo; menas en s&auml;kerhetsincident som leder till oavsiktlig eller olaglig f&ouml;rst&ouml;ring, f&ouml;rlust eller
                            &auml;ndring eller till obeh&ouml;rigt r&ouml;jande av eller obeh&ouml;rig &aring;tkomst till de personuppgifter som &ouml;verf&ouml;rts, lagrats eller p&aring;
                            annat s&auml;tt behandlats. N&auml;r du sj&auml;lv &ouml;verf&ouml;r personuppgifter via internet &auml;r du ansvarig f&ouml;r de personuppgiftsincidenter som kan
                            komma att uppst&aring; och du &auml;r h&auml;rmed uppm&auml;rksammad om det faktum att det &auml;r f&ouml;renat med risker att &ouml;verf&ouml;ra personuppgifter
                            &ouml;ver internet.</p>
                        <p>6.2 Lagringen av dina personuppgifter kommer att ske under den tid som &auml;r n&ouml;dv&auml;ndig f&ouml;r att Ideella f&ouml;reningen ska kunna fullg&ouml;ra
                            sina f&ouml;rpliktelser, samt f&ouml;r de &ouml;vriga syften som angetts ovan. Ideella f&ouml;reningen kommer att vidta n&ouml;dv&auml;ndiga &aring;tg&auml;rder f&ouml;r
                            att ge dina personuppgifter skydd mot obeh&ouml;rig tillg&aring;ng och f&ouml;rlust dessa. Personuppgifterna kommer att raderas n&auml;r de inte l&auml;ngre
                            &auml;r n&ouml;dv&auml;ndiga.</p>
                        <p>6.3 N&auml;r du sj&auml;lv behandlar dina egna personuppgifter &auml;r du sj&auml;lv personuppgiftsansvarig. Det &auml;r d&auml;rf&ouml;r av vikt att du skyddar
                            och uppdaterar dina inloggningsuppgifter, skyddar din mobiltelefon och dator mot virus m.m., samt f&ouml;ljer till&auml;mplig lag och dataskyddslagstiftning. N&auml;r
                            du skriver in fritextsvar &auml;r det viktigt att du undviker att ange personuppgifter som kan vara integritetskr&auml;nkande eller k&auml;nsliga.</p>
                        <p>6.4 Ideella f&ouml;reningen ansvarar inte f&ouml;r skador som uppst&aring;r med anledning av personuppgiftsbehandling, om inte annat f&ouml;ljer av till&auml;mplig
                            dataskyddslagstiftning. Ideella f&ouml;reningen ansvarar i alla h&auml;ndelser inte f&ouml;r indirekta skador.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>7. Dina r&auml;ttigheter</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>7.1 Du har r&auml;ttigheter g&auml;llande Ideella f&ouml;reningens behandling av dina personuppgifter och du kan p&aring;verka din information och hur den
                            lagras.</p>
                        <p>7.2 Du har r&auml;tt att mots&auml;tta dig behandling av dina personuppgifter, s&aring;som exempelvis n&auml;r Ideella f&ouml;reningen riktar direktmarknadsf&ouml;ring
                            till dig. Om du &aring;terkallar ditt samtycke till behandlingen, eller om de lagrade personuppgifterna &auml;r inkorrekta eller irrelevanta, kan Ideella f&ouml;reningen
                            beh&ouml;va radera, begr&auml;nsa eller r&auml;tta dina personuppgifter. Du har r&auml;tt att efterfr&aring;ga radering, begr&auml;nsning och r&auml;ttelse av
                            dina personuppgifter.</p>
                        <p>7.3 Du &auml;ger r&auml;tt att efterfr&aring;ga information om Ideella f&ouml;reningens behandling av dina personuppgifter. Om du efterfr&aring;gar information,
                            exempelvis vilka personuppgifter som &auml;r sparade eller behandlade, ska Ideella f&ouml;reningen kontaktas. Det g&ouml;r du enklast genom att skicka ett mejl
                            till&nbsp;<a href="mailto:info@affarskompetens.se"><strong>info@affarskompetens.se</strong></a>. Om f&ouml;rfr&aring;gan sker elektronisk ska Ideella f&ouml;reningen
                            tillhandah&aring;lla informationen i en strukturerad och vanligt anv&auml;nd elektroniskt l&auml;sbar form. En f&ouml;rfr&aring;gan fr&aring;n dig ska besvaras
                            inom sk&auml;lig tid av Ideella f&ouml;reningen.</p>
                        <p>7.4 I fall d&auml;r Ideella f&ouml;reningen behandlar dina personuppgifter baserat p&aring; ditt samtycke eller uttryckliga samtycke, har du r&auml;tt att n&auml;r
                            som helst &aring;terkalla ditt samtycke. Det g&ouml;r du genom att skicka ett mejl till&nbsp;<a href="mailto:medlem@affarskompetens.se"><strong>medlem@affarskompetens.se</strong></a>.
                            N&auml;r du &aring;terkallar ditt samtycke kommer Ideella f&ouml;reningen att upph&ouml;ra med personuppgiftsbehandlingen. Om du &aring;terkallar ditt samtycke
                            eller tar bort den uppladdade informationen kan det inneb&auml;ra att tj&auml;nsten inte kan forts&auml;tta anv&auml;ndas i de fall Ideella f&ouml;reningens
                            behandling av personuppgifter baseras p&aring; ditt samtycke.</p>
                        <p>7.5 Du har r&auml;tt att &ouml;verf&ouml;ra dina personuppgifter till en annan personuppgiftsansvarig (dataportabilitet), likv&auml;l som att inge klagom&aring;l
                            med anledning av Ideella f&ouml;reningens personuppgiftsbehandling.</p>
                        <p>Denna integritetsskyddspolicy uppdaterades senast den 28 februari 2023 och kan komma att &auml;ndras. &Auml;ndringar publiceras p&aring; v&aring;r webbplats och i
                            projektportalen. Den Ideella f&ouml;reningen reserverar r&auml;tten att g&ouml;ra ensidiga &auml;ndringar vid behov.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>Cookie policy</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>P&aring; den h&auml;r webbplatsen tror vi p&aring; att vara transparenta med hur vi samlar in och anv&auml;nder data. I den h&auml;r policyn f&aring;r du
                            information om hur och n&auml;r vi anv&auml;nder cookies f&ouml;r dessa &auml;ndam&aring;l. Du kan l&auml;sa mer om termer som anv&auml;nds i denna policy utan
                            definition och f&aring; definitionen i v&aring;r Integritetspolicy (se l&auml;ngre upp p&aring; sidan), som ocks&aring; inneh&aring;ller ytterligare information
                            om insamling och anv&auml;ndning av information p&aring; denna webbplats. Enligt lagen om elektronisk kommunikation, som tr&auml;dde i kraft den 1 juli 2011, ska
                            alla bes&ouml;kare av en webbplats med cookies f&aring; information om:&nbsp;</p>
                        <ul>
                            <li>Att webbplatsen inneh&aring;ller cookies.</li>
                            <li>Vad cookies p&aring; webbplatsen anv&auml;nds till.</li>
                            <li>Hur en bes&ouml;kare kan undvika cookies.</li>
                        </ul>
                        <p>Bes&ouml;karen ska ocks&aring; samtycka till att cookies anv&auml;nds.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>Vad &auml;r en Cookie?</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Cookies &auml;r sm&aring; textfiler som skickas av oss till din dator eller mobila enhet. De &auml;r unika f&ouml;r ditt konto eller din webbl&auml;sare. De
                            registrerar hur du r&ouml;r dig p&aring; en webbplats s&aring; att den, n&auml;r du bes&ouml;ker den webbplatsen igen, kan presentera skr&auml;ddarsydda
                            alternativ baserat p&aring; den information som lagrats om ditt senaste bes&ouml;k. Cookies kan ocks&aring; anv&auml;ndas f&ouml;r att analysera trafik och f&ouml;r
                            reklam- och marknadsf&ouml;rings&auml;ndam&aring;l. Sessionsbaserade cookies varar bara medan din webbl&auml;sare &auml;r &ouml;ppen och raderas automatiskt n&auml;r
                            du st&auml;nger din webbl&auml;sare. Best&auml;ndiga cookies varar tills du eller din webbl&auml;sare tar bort dem eller tills de l&ouml;per ut. Cookies anv&auml;nds
                            av n&auml;stan alla webbplatser och skadar inte ditt system. F&ouml;r att ta reda p&aring; mer om cookies, bes&ouml;k den h&auml;r&nbsp;<a
                                    href="https://allaboutcookies.org/"><strong>webbplatsen</strong></a>.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>Anv&auml;nder den h&auml;r websodan Cookies?</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Ja. Den h&auml;r webbplatsen anv&auml;nder cookies och liknande tekniker som gifs med en pixel och webbfyrar. Vi anv&auml;nder b&aring;de sessionsbaserade och best&auml;ndiga
                            cookies. Denna webbplats st&auml;ller in och f&aring;r tillg&aring;ng till v&aring;ra egna cookies p&aring; de dom&auml;ner som drivs av denna webbplats och dess
                            dotterbolag (tillsammans &rdquo;webbplatserna&rdquo;). Dessutom anv&auml;nder vi cookies fr&aring;n tredje part, som Google Analytics. N&auml;r du godk&auml;nner
                            v&aring;r anv&auml;ndning av dessa cookies inneb&auml;r det att behandling av din IP-adress sker. En IP-adress &auml;r en personuppgift enligt GDPR. Google &auml;r
                            baserad i USA och d&auml;rmed sker en &ouml;verf&ouml;ring av personuppgifter till USA, dvs. ett tredje land utanf&ouml;r EES. Detta inneb&auml;r en st&ouml;rre
                            risk f&ouml;r dig som registrerad eftersom lagar och regler i USA inte ger ett lika gott skydd f&ouml;r dina personuppgifter som inom EES. L&auml;s mer om hur
                            Google hanterar cookies i&nbsp;<a href="https://policies.google.com/privacy?hl=sv"><strong>Googles integritetspolicy</strong></a>. Personuppgiftsansvarig &auml;r:
                        </p>
                        <p>Styrelsen f&ouml;r Aff&auml;rskompetens Sverige, organisationsnummer 802525-0856, med registrerad utdelningsadress p&aring; c/o DOSPACE, Drottninggatan 18, 803 20
                            G&auml;vle.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p><strong>Hur anv&auml;nder den h&auml;r websidan Cookies?</strong></p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Vi anv&auml;nder cookies f&ouml;r att sp&aring;ra din anv&auml;ndning av v&aring;r webbplats. Detta g&ouml;r det m&ouml;jligt f&ouml;r oss att f&ouml;rst&aring;
                            hur du anv&auml;nder webbplatsen och sp&aring;ra eventuella m&ouml;nster n&auml;r det g&auml;ller hur du anv&auml;nder v&aring;r webbplats. Vissa cookies &auml;r
                            kopplade till ditt konto och din personliga information f&ouml;r att komma ih&aring;g att du &auml;r inloggad och vilka arbetsytor du &auml;r inloggad p&aring;.
                            Andra cookies &auml;r inte knutna till ditt konto men &auml;r unika och till&aring;ter oss att bland annat utf&ouml;ra analyser och anpassningar. Cookies kan anv&auml;ndas
                            f&ouml;r att k&auml;nna igen dig n&auml;r du bes&ouml;ker en webbplats eller anv&auml;nder v&aring;ra tj&auml;nster, komma ih&aring;g dina preferenser och ge dig
                            en personlig upplevelse som &ouml;verensst&auml;mmer med dina inst&auml;llningar. Detta hj&auml;lper oss att utveckla och f&ouml;rb&auml;ttra v&aring;r webbplats
                            samt v&aring;ra tj&auml;nster. Cookies g&ouml;r ocks&aring; dina interaktioner snabbare och s&auml;krare.</p>
                        <p><strong>Kategorier av anv&auml;ndning:</strong></p>
                        <ul>
                            <li>Sessionscookies: dessa lagras endast p&aring; din dator under din webbsession och raderas automatiskt n&auml;r du st&auml;nger din webbl&auml;sare &ndash; de
                                lagrar vanligtvis ett anonymt sessions-ID s&aring; att du kan surfa p&aring; en webbplats utan att beh&ouml;va logga in p&aring; varje sida men de samlar inte
                                in n&aring;gra personuppgifter fr&aring;n din dator; eller;
                            </li>
                            <li>Best&aring;ende cookies: en best&aring;ende cookie lagras som en fil p&aring; din enhet s&aring;som dator och den finns kvar n&auml;r du st&auml;nger din
                                webbl&auml;sare och startar den igen. Cookien kan l&auml;sas av webbplatsen som skapade den n&auml;r du bes&ouml;ker den webbplatsen igen. Best&aring;ende
                                cookies lagras i syfte att komma ih&aring;g preferenser, inst&auml;llningar och information om en anv&auml;ndare. Dessa kakor har ett utg&aring;ngsdatum som
                                utf&auml;rdas av webbservern. N&auml;r utg&aring;ngsdatumet har uppn&aring;tts f&ouml;rst&ouml;rs cookien av &auml;garen. Vi anv&auml;nder f&ouml;ljande best&aring;ende
                                cookies p&aring; v&aring;r webbplats:
                            </li>
                        </ul>
                        <p>_gi</p>
                        <p>Den h&auml;r kakan &auml;r en best&aring;ende Google Analytics-cookie och anv&auml;nds f&ouml;r att samla in information om hur bes&ouml;kare anv&auml;nder v&aring;r
                            webbplats</p>
                        <p>_ga</p>
                        <p>Denna cookie &auml;r en best&aring;ende Google Analytics-cookie och anv&auml;nds f&ouml;r att samla in information om bes&ouml;kshistorik.</p>
                        <p>Vi kan sammanfattningsvis anv&auml;nda best&aring;ende cookies f&ouml;r denna webbplats Analytics, Google Analytics, Mixpanel Analytics eller Segment.io
                            Analytics.</p>
                        <p><strong>Cookies kan ocks&aring; kategoriseras enligt f&ouml;ljande:</strong></p>
                        <ul>
                            <li>Strikt n&ouml;dv&auml;ndiga cookies: Dessa cookies &auml;r n&ouml;dv&auml;ndiga f&ouml;r att du ska kunna anv&auml;nda webbplatsen effektivt, till exempel n&auml;r
                                du k&ouml;per en produkt och / eller tj&auml;nst, och kan d&auml;rf&ouml;r inte st&auml;ngas av. Utan dessa cookies kan de tj&auml;nster som &auml;r tillg&auml;ngliga
                                f&ouml;r dig p&aring; v&aring;r webbplats inte tillhandah&aring;llas. Dessa cookies samlar inte in information om dig som kan anv&auml;ndas f&ouml;r marknadsf&ouml;ring
                                eller f&ouml;r att komma ih&aring;g var du har varit p&aring; internet.
                            </li>
                            <li>Prestanda- och analytiska cookies: Dessa cookies g&ouml;r det m&ouml;jligt f&ouml;r oss att &ouml;vervaka och f&ouml;rb&auml;ttra prestandan p&aring; v&aring;r
                                webbplats. Till exempel till&aring;ter de oss att r&auml;kna bes&ouml;k, identifiera trafikk&auml;llor och se vilka delar av webbplatsen som &auml;r mest
                                popul&auml;ra.
                            </li>
                            <li>Funktionscookies: Dessa cookies g&ouml;r det m&ouml;jligt f&ouml;r v&aring;r webbplats att komma ih&aring;g val du g&ouml;r och tillhandah&aring;lla f&ouml;rb&auml;ttrade
                                funktioner. Vi kan till exempel f&ouml;rse dig med nyheter eller uppdateringar som &auml;r relevanta f&ouml;r de tj&auml;nster du anv&auml;nder. De kan ocks&aring;
                                anv&auml;ndas f&ouml;r att tillhandah&aring;lla tj&auml;nster du har beg&auml;rt, till exempel att titta p&aring; en video eller kommentera en blogg.
                                Informationen som dessa cookies samlar in &auml;r vanligtvis anonymiserad.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Vad kan du g&ouml;ra om du inte vill att cookies ska st&auml;llas in eller om du vill att de ska tas bort?</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Vissa m&auml;nniskor f&ouml;redrar att inte till&aring;ta cookies och de flesta webbl&auml;sare ger dig m&ouml;jlighet att hantera cookies s&aring; att de passar
                            dig. I vissa webbl&auml;sare kan du st&auml;lla in regler f&ouml;r att hantera cookies p&aring; webbplats-f&ouml;r-plats-basis, vilket ger dig mer detaljerad
                            kontroll &ouml;ver din integritet. Vad detta betyder &auml;r att du kan f&ouml;rbjuda cookies fr&aring;n alla webbplatser utom de som du litar p&aring;. Om du
                            &ouml;nskar f&ouml;rhindra att cookies sparas p&aring; din dator n&auml;r webbl&auml;saren &auml;r st&auml;ngd, s&aring; kan du bes&ouml;ka sidan i privat l&auml;ge,
                            &auml;ven ibland ben&auml;mnt &ldquo;Inkognitof&ouml;nster&rdquo; (Chrome), &ldquo;Privat f&ouml;nster&rdquo; (Firefox) eller &ldquo;InPrivate-f&ouml;nster&rdquo;
                            (Microsoft Edge). Det inneb&auml;r att alla cookies i dessa inst&auml;llningar av privat l&auml;ge raderas fr&aring;n din dator s&aring; fort du st&auml;nger din
                            webbl&auml;sare.</p>
                        <p>Webbl&auml;sartillverkare tillhandah&aring;ller hj&auml;lpsidor som r&ouml;r cookiehantering i sina produkter. Se nedan f&ouml;r mer information.</p>
                        <ul>
                            <li><strong><a href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a></strong></li>
                            <li><strong><a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies">Internet Explorer</a></strong></li>
                            <li><strong><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></strong></li>
                            <li><strong><a href="https://support.apple.com/kb/PH5042?locale=en_US">Safari (Desktop)</a></strong></li>
                            <li><strong><a href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a></strong></li>
                            <li><strong><a href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&amp;answer=2425067">Android Browser</a></strong></li>
                            <li><strong><a href="http://www.opera.com/help">Opera</a></strong></li>
                            <li><strong><a href="http://www.opera.com/help/mobile/android#privacy">Opera Mobile</a></strong></li>
                        </ul>
                        <p>Om du begr&auml;nsar m&ouml;jligheten f&ouml;r webbplatser och applikationer att st&auml;lla in cookies kan du f&ouml;rs&auml;mra din totala anv&auml;ndarupplevelse
                            och/eller f&ouml;rlora m&ouml;jligheten att komma &aring;t tj&auml;nsterna, eftersom den inte l&auml;ngre kommer att anpassas till dig. Det kan ocks&aring; hindra
                            dig fr&aring;n att spara anpassade inst&auml;llningar, som inloggningsinformation.</p>
                        <p>I det fall du &ouml;nskar mer information om cookies kan du bes&ouml;ka Post- och telestyrelsens webbplats:&nbsp;<a
                                href="https://pts.se/"><strong>https://pts.se/</strong></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
