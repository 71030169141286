import { Component, Input, OnInit } from '@angular/core';
import { CancelSubmissionDialog } from '@app/pages/submissions/cancel-submission/cancel-submission.dialog';
import {
	CancelationExpiredDialog
} from '@app/pages/submissions/cancel-submission/cancelation-expired-dialog/cancelation-expired-dialog.component';
import { ddCourseUtils } from '@shared/services/course.utils';
import { CoursesService } from '@shared/services/courses.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-cancel-submission-button',
	template: `

        <ng-container *ngIf="showCancelButton">
            <button pButton type="button" class="cancel-booking-button p-button-raised p-button p-button-sm py-1"
                    [tooltipDisabled]="canCancel" pTooltip="Senaste avbokningsdag passerat, klicka för att läsa mer."
                    [class.font-italic]="!canCancel" (click)="showDialog()">
	            Avboka
                <span class="p-badge bg-white p-badge-no-gutter" *ngIf="!canCancel">
	                <i class="fa-solid fa-info text-sm"></i>
                </span>
            </button>
        </ng-container>
        <ng-container *ngIf="course.Canceled">
            <span class="text-primary font-italic">Avbokad {{course.CanceledDate | date}}</span>
        </ng-container>
        <ng-container *ngIf="!course.Canceled && course.OnDemandAccessExpired">
            <span class="text-primary font-italic">Förfallen</span>
        </ng-container>        
        <!--<ng-container *ngIf="!course.canCancel && !course.passedCourse && course.status == 1 && !forceAllow">
            Maila för avbokning: <a href="mailto:support@affarskompetens.se?subject=Avbokningsförfrågan: {{course.course_name}}, {{course.course_startdate*1000 | date}}">support@affarskompetens.se</a>
        </ng-container>-->
	`,
	styles: []
})
export class CancelSubmissionButton implements OnInit {

	dialogRef: DynamicDialogRef;
	@Input() course: any;
	@Input() forceAllow: boolean = false;
	showCancelButton: boolean = false;
	canCancel: boolean;
	isLoading: boolean;

	constructor(private coursesService: CoursesService, public config: DynamicDialogConfig, public dialogService: DialogService, private confirmationService: ConfirmationService) {
	}

	ngOnInit(): void {
		this.showCancelButton = ddCourseUtils.showCancelBookingButton(this.course)
			&& !this.course.Canceled
			&& !this.course.OnDemandAccessExpired
			&& (this.course.Event.IsFuture || this.course.Event.IsRunning);

		if(this.showCancelButton)
			this.canCancel = ddCourseUtils.canCancelBooking(this.course) || (this.forceAllow);
	}

	showDialog() {

		if(this.canCancel) {
			this.confirmationService.confirm({
				message: 'Vill du verkligen avboka?',
				accept: async () => {
					this.isLoading = true;
					await this.coursesService.cancelSubmission(this.course.ParticipantId, this.course.PersonId).then((result: any) => {
						this.isLoading = false;
						if(result.error == 0) {
							this.course.Canceled = true;
							//this.course.cancel_date = new Date().getTime() / 1000;
							this.coursesService.fetchSubmissions(true).then();
						}
					});
				}
			});
		} else {
			this.dialogRef = this.dialogService.open(CancelationExpiredDialog, {
				data: {
					course: this.course,
					canCancel: this.canCancel
				},
				header: 'Sista avbokningsdag passerat',
				width: '600px'
			});
		}
	}

}
