import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService, AuthUser } from '@app/@core/auth';
import { environment } from '@env/environment';
import { CompaniesService } from '@shared/services/companies.service';
import { ResponsiveService } from '@shared/services/responsize.service';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
	selector: 'app-shell',
	templateUrl: './shell.component.html',
	styles: [],
})
export class ShellComponent implements OnInit {
	width: number;
	avatarMenu: MenuItem[] = [];
	currentUser: AuthUser;
	openedSidenav: any = true;
	hasBackdrop: any = false;
	modeSidenav: any = 'push';
	@ViewChild('sidenav') sidenav: MatSidenav;

	hideMobileFooterOn: any = ['/analyser'];
	hideMobileFooter: boolean = false;

	// projectSettings för vilken typ av layout som ska visas
	appLayout: string = 'complete';

	@ViewChild('scrollContainer') private scrollContainer: MatSidenavContent;
	version: string | null = environment.version;
	selectedProject: string;

	constructor(
		private cdr: ChangeDetectorRef,
		public authenticationService: AuthenticationService,
		private companiesService: CompaniesService,
		private responsiveService: ResponsiveService,
		private router: Router,
		private confirmationService: ConfirmationService
	) {
		this.currentUser = this.authenticationService.currentAuth['person'];

		if (this.authenticationService.projectSettings) {
			this.appLayout = this.authenticationService.projectSettings['appLayout'];
		}

		this.selectedProject = this.authenticationService.getSelectedProject();

		let triggerUpdate = false;
		let storedVersion = localStorage.getItem('dd.appVersion');

		if (storedVersion !== null) {
			console.error(':: stored app version found');
			if (storedVersion != this.version) {
				console.error(':: version diff', this.version, 'stored version', storedVersion);
				triggerUpdate = true;
			}
		} else {
			console.error(':: stored app version NOT found');
			localStorage.setItem('dd.appVersion', this.version);
			//triggerUpdate = true;
		}

		setTimeout(() => {
			if (triggerUpdate) {
				this.confirmationService.confirm({
					key: 'triggerUpdateDialog',
					message: 'Det finns en ny version av portalen, klicka på Uppdatera för att ladda om sidan med senaste ändringar.',
					accept: () => {
						//Actual logic to perform a confirmation
						localStorage.removeItem('dd.myActivitites');
						localStorage.removeItem('dd.course.tablefilter');
						localStorage.removeItem('dd.course.viewCategories');
						localStorage.removeItem('dd.course.viewCourseType');

						localStorage.setItem('dd.appVersion', this.version);
						//window.location.reload(true)
						const form = document.createElement('form');
						form.method = 'GET';
						form.action = location.href;
						document.body.appendChild(form);
						form.submit();
					},
					acceptLabel: 'Uppdatera',
					rejectVisible: false,
					acceptIcon: 'pi pi-refresh',
					acceptButtonStyleClass: 'p-button-sm p-button-danger hide-duplicated-icon'
				});
			}
		}, 200);

		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if (this.modeSidenav == 'over' && this.sidenav) {
					this.sidenav.close();
				}

				this.hideMobileFooter = this.hideMobileFooterOn.some((i: any) => router.url.includes(i));
			}
		});

	}

	scrollToBottom(): void {

		try {
			//this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
			this.scrollContainer.scrollTo({bottom: 0});
		} catch (err) {
			//console.error(this.scrollContainer.scrollTo(0, 0));
		}
	}

	setWidth(widthNumber: number) {
		this.width = widthNumber;
		this.cdr.detectChanges();
	}

	ngOnInit() {

		this.responsiveService.scrollToBottom.subscribe(() => {
			this.scrollToBottom();
		});

		this.responsiveService.mediaBreakpoint$.subscribe((value) => {
			if (value == 'xs' || value == 'sm' || value == 'md' || value == 'lg') {
				this.openedSidenav = false;
				this.modeSidenav = 'over';
				this.hasBackdrop = true;
			} else if (value == 'xxl' || value == 'xl') {
				this.openedSidenav = true;
				this.modeSidenav = 'side';
				this.hasBackdrop = false;
			} else {
				//this.openedSidenav = true;
				this.modeSidenav = 'push'
			}
		});

		this.responsiveService.forceCloseMenu.subscribe(() => {
			if (this.openedSidenav) {
				this.openedSidenav = false;
			}
		});
		this.responsiveService.forceOpenMenu.subscribe(() => {
			if (!this.openedSidenav) {
				this.openedSidenav = true;
			}
		});

		if (this.authenticationService.isCourseLeader()) {
			this.avatarMenu.push({label: 'Min profil', icon: 'pi pi-fw pi-user', routerLink: ['/kursledare/profil']});
		}
		if (!this.authenticationService.isSuperAdmin()) {
			this.avatarMenu.push({label: 'Min profil', icon: 'pi pi-fw pi-user', routerLink: ['/profil']});
		}

		this.avatarMenu.push(
			{
				label: 'Logga ut',
				icon: 'pi pi-fw pi-lock-open',
				command: (event: any) => {
					this.authenticationService.logout();
				},
			});
	}

}
