<div class="container pb-4">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Så här kommunicerar du till dina medarbetare</h2>
        <h3 class="mb-3 font-normal">om projektet Perfekt – korta och konkreta kurser</h3>
        <small>Projektet Perfekt medfinansieras av ESF (Europeiska Socialfonden) och i samarbete med Företagarna.</small>

        <div class="row mt-2">
            <div class="col-12">
                <p>
                    Låt oss hjälpa dig att peppa igång dina medarbetare att gå på kurser hos oss. Vi har samlat ihop förslag på mail som du kan skicka ut till dina medarbetare, en pdf som du kan skriva ut och sätta upp i tex. personalrummet och ett tipsblad med idéer på hur du kan inspirera dina medarbetare att gå kurser – för deras vidareutveckling.
                </p>
            </div>

            <hr class="line-delimiter w-90 mt-0" />

            <div class="col-12 mb-2">
                <h4 class="font-normal">Förslag på kommunikation:</h4>
            </div>

            <div class="col-12 col-md-4">
                <img src="https://www.affarskompetens.se/wp-content/uploads/Skarmavbild-2023-10-19-kl.-13.52.02.png" alt="Förslag på text finns (word-fil)." class="shadow-3" style="max-width:251px;">
                <p class="mt-4">Maila ut till dina medarbetare och informera om hur de kommer igång med alla kurser i portalen. Förslag på text finns (word-fil).</p>

                <a pButton type="button" label="Ladda ner här" icon="pi pi-download" iconPos="right" class="" href="https://www.affarskompetens.se/wp-content/uploads/Textforslag-pa-mail-till-medarbetare.docx" target="_blank"></a>
            </div>
            <div class="col-12 col-md-4">
                <img src="https://www.affarskompetens.se/wp-content/uploads/PDF-till-medarbetare-1.jpg" class="shadow-3" style="max-width:251px;">
                <p class="mt-4">Kommunicera ut till dina kollegor igenom att maila denna pdf eller skriv ut och sätt upp den i tex. personalrummet.<br><br></p>

                <a pButton type="button" label="Ladda ner här" icon="pi pi-download" iconPos="right" class="" href="https://www.affarskompetens.se/wp-content/uploads/PDF-till-medarbetare-1.pdf" target="_blank"></a>
            </div>
            <div class="col-12 col-md-4">
                <img src="https://www.affarskompetens.se/wp-content/uploads/Tipsblad-till-foretagsledare-2.png" class="shadow-3" style="max-width:251px;">
                <p class="mt-4">Ta del av vårt tipsblad om hur du kan peppa dina närmsta medarbetare att komma igång och gå kurser.<br><br></p>

                <a pButton type="button" label="Ladda ner här" icon="pi pi-download" iconPos="right" class="" href="https://www.affarskompetens.se/wp-content/uploads/Tipsblad.pdf" target="_blank"></a>
            </div>
        </div>


    </div>
</div>
