
<div class="bg-image border-round-md shadow-sm" [ngStyle]="{'background-image': 'url('+course.ImageUrl+')'}">

    <div class="content">
        <div class="flex gap-2 text-sm">
            <dd-course-type-tag [type]="course._CourseTypeList" [showReadMore]="true"></dd-course-type-tag>
            <dd-category-tag [split]="true" [tag]="course.Categories" [invert]="false"></dd-category-tag>
        </div>
        <div class="course-name h5 font-semibold mt-3 mb-3 cursor-pointer text-white text-shadow-2" [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">
            {{course.CourseName}}
        </div>

        <div class="text-md course-card-footer-dates text-white text-shadow-2" [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">
            <dd-course-date [course]="course" [showWeekDay]="true"
                            [showEndTime]="true" [showMoreAvailable]="true"></dd-course-date>
        </div>
        <button pButton pRipple
                type="button" label="Läs mer och boka här" icon="fa-solid fa-arrow-right-long" iconPos="right"
                class="mt-3 p-button-sm p-button-rounded p-button-primary cursor-pointer p-button-raised"
                [routerLink]="['/aktiviteter/visa', course.CourseTemplateId, 0]">
        </button>
    </div>

</div>
