<div class="my-3">
    <label for="reason" class="d-block">Orsak till avbokning:</label>
    <textarea rows="1" [ngModel]="reason" id="reason" class="p-inputtextarea p-inputtext p-component bg-white w-100" pInputTextarea autoResize="autoResize"></textarea>
    <div class="p-field-checkbox" *ngIf="isMultiDayCourse">
        <p-checkbox [(ngModel)]="cancelAllparts" inputId="cancelAllparts" name="cancelAllparts" value="1" required binary="true"></p-checkbox>
        <label for="cancelAllparts">Avboka från alla dagar</label>
    </div>
    <small *ngIf="isMultiDayCourse">Då detta är en flerdagarskurs måste du välja om du vill avboka dig helt eller bara denna dag.</small>
</div>
<div class="p-dialog-footer" style="margin-left:-24px; margin-right: -24px;">
    <button [disabled]="isLoading" class="w-100 p-button-lg btn-fw-700" label="Skicka avbokning" pButton type="submit" (click)="submit()">
        <i *ngIf="isLoading" class="fas fa-cog fa-spin position-absolute"></i>
    </button>
</div>

