<div class="row d-flex justify-content-center h-75" @FadeIn>
    <div class="rounded mw-50 align-self-center" [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}">
        <div class="row mb-2">
            <div class="col" *ngIf="!inModal">
                <h1 class="text-center mb-2">Personuppgifter</h1>
            </div>
        </div>
        <form action="" method="post" (ngSubmit)="submitStep(form)" #form="ngForm">
            <div class="row justify-content-center" [class.pb-5]="!inModal" *ngIf="user">
                <div class="col px-0 justify-content-center" style="max-width: 350px">

                    <div class="flex flex-column mb-4">
                        <span class="text-sm">Företag:</span>
                        <span class="h5 text-lg">{{user.customer.CustomerName}}</span>
                    </div>

                    <div class="row d-flex justify-content-between">
                        <div class="col-6">
                            <span class="p-float-label mb-3">
                                <input class="bg-white w-100" type="text" pInputText [(ngModel)]="user.FirstName" name="firstname" required/>
                                <label>Förnamn</label>
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label mb-3">
                                <input class="bg-white w-100" type="text" pInputText [(ngModel)]="user.LastName" name="surname" required/>
                                <label>Efternamn</label>
                            </span>
                        </div>
                    </div>
                    <span class="p-float-label mb-4 pt-1">
                        <p-inputMask mask="99999999-9999" [(ngModel)]="user.CivicRegistrationNumber" name="socialsecurityno" placeholder=""
                                     [autoClear]="false" styleClass="w-100 bg-white" [required]="true" (onComplete)="testPsn()" [class.ng-invalid.ng-dirty]="validPsn === false"></p-inputMask>
                        <label class="">Pers. nummer (YYYYMMDD-NNNN)</label>
                    </span>
                    <span class="p-float-label mb-4 pt-1">
                        <p-dropdown [(ngModel)]="user.personAccessPosition" [options]="personAccessPositionList"
                                placeholder="Välj din roll" styleClass="bg-white w-100" required name="personAccessPosition" appendTo="body"></p-dropdown>
                        <label for="float-label">Roll</label>
                    </span>

                    <span class="p-float-label mb-4 pt-1">
                        <input class="w-100 bg-white" pInputText [(ngModel)]="user.email" name="email" type="email" email required/>
                        <label>E-post</label>
                    </span>

                    <span class="p-float-label mb-3">
                        <p-inputMask mask="999-99 99 999" [(ngModel)]="user.Mobile" name="mobilephone" placeholder=""
                                     [autoClear]="false" styleClass="w-100 bg-white" [required]="true"></p-inputMask>
                        <label>Mobiltelefon</label>
                    </span>

                    <div class="flex">
                        <p-checkbox ngModel inputId="confirm" name="confirm" value="1" required></p-checkbox>
                        <label for="confirm" class="text-sm">Ja, jag är anställd i <i>{{user.customer.CustomerName}}</i></label>
                    </div>

                    <div class="row justify-content-center pb-5" [class.pt-5]="!inModal">
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" label="Spara & gå vidare" [disabled]="(!form.valid || isLoading) || !validPsn" *ngIf="!inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" [label]="'Spara'" [disabled]="(!form.valid || isLoading) || !validPsn" *ngIf="inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
