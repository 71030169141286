import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app/@core/auth';
import { MenuItems, MenuItemsSmall } from '@app/menu-items.interface';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styles: [],
})
export class MenuComponent implements OnInit {
	@Output() closeMenu: EventEmitter<any> = new EventEmitter<any>();
	items: MenuItem[] = [];
	itemsSmall: MenuItem[] = [];

	_role: string;
	_project: string;
	_leaderType: string;

	constructor(private router: Router, private authenticationService: AuthenticationService) {
	}

	ngOnInit() {

		this._role = this.authenticationService.getRole();
		this._project = this.authenticationService.getSelectedProject();
		this._leaderType = this.authenticationService.getLeaderType()?.toLowerCase();

		if (this._role == "mainleader")
			this._role = 'leader';

		this.items = MenuItems.filter(this.filterMenuFnc, this);
		this.itemsSmall = MenuItemsSmall.filter(this.filterMenuFnc, this);
	}

	filterMenuFnc(m:any) {
		if((!m.role || m.role == "*") && (!m.project || m.project == '*')) return true;

		if(m.leaderType) {
			if(m.leaderType != this._leaderType)
				return false;
		}
		//if(m.role && (m.role.includes(role+',') || m.role.includes(','+role))) return true;
		let splitRole = m.role.split(',');

		if(!splitRole.includes(this._role))
			return false;

		if(!m.project)
			return true;

		let splitProject = m.project.split(',');

		if(!splitProject.includes(this._project) && !splitProject.includes('*'))
			return false;

		return true;
	}

	toggleMenu() {
		this.closeMenu.emit(true);
	}
}
