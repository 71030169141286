import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/auth';
import { CourseEventInterface } from '@shared/interfaces/course';
import { CoursesService } from '@shared/services/courses.service';
import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'dd-booking-button',
	templateUrl: './booking-button.button.html',
	styles: [`
		.text-warning {
			font-size: 12px !important;
		}
	`]
})
export class BookingButtonButton implements OnInit, OnChanges {

	get course(): any {
		return this._course;
	}

	@Input() set course(value: any) {
		this._course = value;
	}

	_course: any = [];

	@Input() event: CourseEventInterface;

	@Input() cssClasses = '';
	@Input() title = 'BOKA';

	@Input() showWaitingList: boolean = true;
	@Input() showBooking: boolean = true;

	@Input() head: any = [];
	canBook: boolean = false;

	//validatedAnalysis: boolean = false;
	spotsFull: boolean = false;

	constructor(private coursesService: CoursesService, private authService: AuthenticationService, private confirmationService: ConfirmationService) {
	}

	ngOnInit(): void {
		//this.validatedAnalysis = true;//this.authService.currentCompany && this.authService.currentCompany['validated_analysis'];
		this.canBook = true;//this.authService.appSettings['allow_user_type_submission'].indexOf(this.authService.getRole()) > -1;

		//this.submissionOpen = ddCourseUtils.isSubmissionOpen(this.event);//this._course['submission_open'] == 1 && this._course['spots_left'] > 0;
		//this.submissionClosed = ddCourseUtils.isSubmissionClosed(this.event);//this._course['submission_open'] == 1 && this._course['spots_left'] > 0;
		this.spotsFull = this.event.ParticipantNumberLeft !== null && this.event.ParticipantNumberLeft < 1;//this._course['submission_open'] == 1 && this._course['spots_left'] < 1;
		//this.spotsFull = true;
//2023-11-12

		//Maila för avbokning: <a href="mailto:support@affarskompetens.se?subject=Avbokningsförfrågan: {{course.course_name}}, {{course.course_startdate*1000 | date}}">support@affarskompetens.se</a>
	}

	ngOnChanges() {

	}

	openDialog() {
		this.coursesService.openBookingDialog.emit({event: this.event, course: this._course});
	}

	confirmQue() {
		console.error(this._course);
		//if(this._course['_userOnWaitingList'] == true)
		//	return;


		let message = 'Om tillfället är fullbokat kan du ställa dig på vår kölista. Om det blir en plats ledig kommer vi att skicka ut ett mail till alla som står på kölistan. Då är det först till kvarn som gäller och du bokar dig i Portalen.<br />Maila oss på <a href="mailto:support@affarskompetens.se?subject=Jag vill köa för: '+this._course.CourseName+'&body='+this._course.CourseName+', '+this.event.StartDate+'">support@affarskompetens.se</a>';

		this.confirmationService.confirm({
			key: 'courseQue_' + this.event.EventId,
			//message: 'Du kommer bli meddelad när en avbokning skett på denna aktivitet, det är då upp till dig att gå in och anmäla dig till kursen, först till kvarn!',
			message: message,
			rejectVisible: false,
			acceptVisible: false,
			accept: async () => {
				//Actual logic to perform a confirmation
				/*await this.coursesService.addUserToWaitingList(this.authService.currentUser['userID'], this._course['courseID'], this._course['startdate']).then((result:any) => {
				  if(result['error'] == 0) {
					this._course['_userOnWaitingList'] = true;
				  }
				});*/
			}
		});
	}
}
