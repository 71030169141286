import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'dd-gdpr',
	templateUrl: './gdpr.component.html',
	styles: []
})
export class GdprComponent implements OnInit {
	@Input('inModal') inModal: boolean = false;

	constructor() {
	}

	ngOnInit(): void {
	}

}
