import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@app/@core/auth';

import { I18nModule } from '@app/i18n';


import { MenuComponent } from '@app/shell/menu/menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { OpenSidebarOnSwipeDirective } from '@shared/directives/open-sidebar-onswipe.directive';
import { SetGetWidthSidebarPipe } from '@shared/pipes/set-get-width-sidebar.pipe';
import { SharedModule } from '@shared/shared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { ShellComponent } from './shell.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		NgbModule,
		AuthModule,
		I18nModule,
		RouterModule,
		MatSidenavModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		SharedModule,
		MenuModule,
		ToastModule,
		ConfirmDialogModule,
		MessagesModule
	],
	declarations: [ShellComponent, MenuComponent, OpenSidebarOnSwipeDirective, SetGetWidthSidebarPipe, NotFoundComponent],
})
export class ShellModule {
}
