<ng-container *ngIf="showBooking">
    <button pButton type="button" class="p-button-raised p-button py-1" (click)="openDialog()" *ngIf="!spotsFull && event.IsSubmissionOpen && !event.IsSubmissionClosed"
            ddGtagEvent [track]="{ on: 'click', action: 'open_booking', params: {'course':course.CourseName, 'eventDate': event.StartDate, 'eventId': event.EventId }}">
        {{title}}
    </button>

    <p *ngIf="!event.IsSubmissionOpen" class="text-sm text-muted font-sm font-italic d-inline-block mr-2 mb-1">
      Anmälan öppnar {{event.ApplicationOpenDate | date}}
    </p>
    <p *ngIf="event.IsSubmissionClosed" class="text-sm text-muted font-sm font-italic d-inline-block mr-2 mb-1">
      Anmälan stängd
    </p>

    <!--<button class="{{cssClasses}}" (click)="openDialog()" *ngIf="canBook && event.IsSubmissionOpen && validatedAnalysis">{{title}}</button>-->

    <!--<span class="text-warning p-text-italic mt-4 pb-2 d-inline-block" *ngIf="!validatedAnalysis && false">
        <i class="fa fa-info"></i> För att kunna boka en kurs måste Behovskartläggning först vara slutförd.
    </span>-->

    <!--<span class="text-warning p-text-italic mt-4 pb-2 d-inline-block" *ngIf="!canBook">
        <i class="fa fa-info"></i> Varför kan jag inte boka utbildningen?<br>
        Den högsta ansvariga på ditt företag är den personen som har behörighet att boka in dig på utbildningar. Kontakta den personen för att boka in dig.
    </span>-->
</ng-container>

<ng-container *ngIf="showWaitingList && event.IsSubmissionOpen">
    <button pButton type="button" class="p-button-raised p-button p-button-sm py-1 d-block" (click)="confirmQue()" *ngIf="spotsFull">
        <!--<span *ngIf="_course['_userOnWaitingList'] == true" pTooltip="Du kommer bli notifierad vid ledig plats!" tooltipPosition="bottom">
            <i class="fas fa-check pr-1"></i> Står i kö!
        </span>-->
        <span pTooltip="Skriv upp dig på kölistan!" tooltipPosition="bottom">
            Kölista
        </span>
    </button>
</ng-container>

<p-confirmDialog header="Är tillfället fullbokat - ställ dig på kö!" icon="pi pi-bell" key="courseQue_{{event.EventId}}" [style]="{'max-width': '500px'}" acceptLabel="Ja" rejectLabel="Nej" *ngIf="event">
</p-confirmDialog>
