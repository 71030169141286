import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'dd-contact',
	templateUrl: './contact.component.html',
	styles: [`
		img {
			margin: 5px;
			box-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
			border: 2px solid #fff;			
		}
	`]
})
export class ContactComponent implements OnInit {

	users: any = [
		{
			name: 'Sara Hellberg',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Sara_200x200_2021.png',
			title: 'Verksamhetsledning och affärscoaching',
			email: 'sara.hellberg@affarskompetens.se',
			phone: '073 – 386 56 21',
		},
		{
			name: 'Leif Bäckström',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Leif_-200x200_2021.png',
			title: 'Ekonomi och affärsutveckling',
			email: 'leif.backstrom@affarskompetens.se',
			phone: '070 – 754 13 35',
		},
		{
			name: 'Vera Andersson',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Vera_200x200_2021.png',
			title: 'Utbildning och affärscoaching',
			email: 'vera.andersson@affarskompetens.se',
			phone: '072 – 215 62 09',
		},
		{
			name: 'Anna-Lena Jansson',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/05/Anna-Lena-bild-scaled-e1620026182348.jpg',
			title: 'Digitalisering och affärsutveckling',
			email: 'anna-lena.jansson@affarskompetens.se',
			phone: '070 - 591 30 70',
		},
		{
			name: 'Mia Ulin',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Mia_200x200_2021.png',
			title: 'Kommunikation och marknadsföring',
			email: 'mia.ulin@affarskompetens.se',
			phone: '070 – 390 79 89',
		},
		{
			name: 'Milla Jansson',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Milla_-200x200_2021.jpg',
			title: 'Administration',
			email: 'milla.jansson@affarskompetens.se',
			phone: '',
		},
		{
			name: 'Jenny Ohlsson',
			image: 'https://portal.affarskompetens.se/wp-content/uploads/2021/02/Personalfoto_Jenny_-200x200_2021.jpg',
			title: 'Ekonomi',
			email: '',
			phone: '',
		},
	];
	constructor() {
	}

	ngOnInit(): void {
	}

}
