import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { CompanyInterface } from '@shared/interfaces/company-interface';
import { UserInterface } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-base-wiz',
	template: '',
	styles: [],
	animations: [FadeIn]
})
export class BaseWizComponent implements OnInit, OnDestroy {
	company: CompanyInterface;
	inModal = false;
	isLoading = false;
	user: UserInterface;
	//company: any = [];
	dialogRef: DynamicDialogRef;
	@Output() stepValidated:EventEmitter<void> = new EventEmitter();

	cities: any[] = [];
	all_cities: any[] = [];
	counties: any[] = [];
	all_counties: any[] = [];
	regions: any[];
	public subPage: string;

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public route: ActivatedRoute, public authService: AuthenticationService) {
	}


	baseUserSubmitStep(form: NgForm) {
		this.isLoading = true;

		return this.companiesService.saveUserStep(form).then((result: any) => {
			if (result.error == 0) {
				Object.assign(this.user, form.value);

				if (form.value['socialsecurityno']) {
					this.user.finishedProfile = 1;
					localStorage.setItem('currentUser', JSON.stringify(this.user));
				}
				//console.error(this.user, this.company.countyID, form.value);

				this.companiesService.user = this.user;
				this.stepValidated.next();

				if (this.inModal) {
					this.isLoading = false;
				}
			} else {
				this.isLoading = false;
			}

			return result;
		});
	}

	baseCompanySubmitStep(form: NgForm, finish: boolean = false) {

		console.error('baseCompanySubmitStep', finish);
		/*console.error(form.valid)
		if(!form.valid) {
			form.control.markAllAsTouched();
			return Promise.resolve();
		}*/

		this.isLoading = true;

		return this.companiesService.saveCompanyStep(form).then((result: any) => {
			if (result.error == 0) {

				Object.assign(this.company, form.value);

				//console.error(this.company.getCounty, this.company.countyID, form.value);

				if (form.value['regionID']) {
					//this.company.region_name = this.regions.find((i) => i.regionID == this.company.regionID).region_name;
				}

				if (form.value['companycounty']) {
					//this.company.getCounty = this.counties.find((i) => i.countyID == form.value['companycounty']['countyID']);
					//this.company.countyID = form.value['companycounty']['countyID'];
				}

				if (form.value['companycity']) {
					//this.company.getCity = this.cities.find((i) => i.cityID == form.value['companycity']['cityID']);
					//this.company.cityID = form.value['companycity']['cityID'];
				}

				this.companiesService.company = this.company;

				if(finish == false) {
					this.stepValidated.next();
				} else {
					this.companiesService.subFinished.next();
				}

				if (this.inModal) {
					this.isLoading = false;
				}
			} else {
				this.isLoading = false;
			}

			return result;
		});
	}

	ngOnDestroy() {
		if (this.dialogRef) {
			this.dialogRef.destroy();
		}
	}

	ngOnInit(): void {
		if (this.config && this.config.data) {
			this.inModal = this.config.data?.inModal;
		}

		this.subPage = '';

		this.route.paramMap.subscribe(params => {
			this.subPage = params.get('sub');
		});

		//this.isLoading = true;
		console.error(this.authService.currentAuth)

		if(this.authService.currentAuth) {
			this.user = this.authService?.currentAuth['person'];
			this.company = this.authService?.currentAuth['customer'];
		}
/*
		Promise.all([
			this.companiesService.fetchUser(),
			this.companiesService.fetchCompany(),
			this.companiesService.fetchRegions(),
			//this.companiesService.fetchKwikkData()
		]).then((values) => {
			this.user = values[0];
			this.company = values[1];
			this.regions = values[2];
			//this.companiesService.kwikkData = values[3];

			if (this.regions) {
				this.regions.map((r) => {
					this.counties = [...this.counties, ...r.countys];
				});
			}

			if (this.counties) {
				this.counties.map((c) => {
					this.cities = [...this.cities, ...c.citys];
				});
			}

			this.all_cities = JSON.parse(JSON.stringify(this.cities));
			this.all_counties = JSON.parse(JSON.stringify(this.counties));

			if (this.company.regionID) {
				this.counties = this.all_counties;
			}

			if (!this.company.getCounty) {
				this.company.getCounty = this.counties.find((i) => i.countyID == this.company.countyID);
			}

			if (!this.company.getCity) {
				this.company.getCity = this.cities.find((i) => i.cityID == this.company.cityID);
			}

			if (this.company.companycounty) {
				this.cities = this.all_cities.filter((i) => i.countyID == this.company.companycounty);
			}
			let position = this.user['position'] ? this.user['position'] : this.user['user_type'];

			this.companiesService.regionsSource.next([]);

			if (this.user.finishedProfile == 0 && position != 'employee' && this.authService.projectSettings['clearPsnOnFirst'] == true) {
				this.user.CivicRegistrationNumber = '';
			}

			this.isLoading = false;
		});

 */
	}
}
