<div class="sidenav-header">
    <!--<div (click)="toggleMenu()" class="btn px-4 text-white">
        <i class="fa fa-times"></i>
    </div>-->
</div>

<div class="d-flex h-100 w-100 mx-0">
    <!-- Sidebar wrapper -->
    <div class="container menu-container px-0 text-center">
        <div class="px-4 py-3">
            <img alt="Affärskompetens Sverige Logga" class="" src="./assets/images/Affarskompetens_Logotyp_sv.png"/>
        </div>
        <p-panelMenu [model]="items" styleClass="mt-2 big"></p-panelMenu>
        <p-panelMenu [model]="itemsSmall" styleClass="mt-3 small text-left"></p-panelMenu>

        <div class="pb-5">
            <img alt="EurSocfond" class="mt-4  mb-2" style="width: 110px" src="./assets/images/6d6378cf-c66d-45be-83e1-93661d91ad23.jpg" />
            <div *ddIfProject="'reform'">
                <div class="mb-3 text-center">
                    <img alt="gävle kommun" src="https://www.affarskompetens.se/wp-content/uploads/Gavle-kommun_RGB.png" style="height:60px"/>
                </div>
                <div class=" text-center">
                    <img alt="företagarna" src="https://www.affarskompetens.se/wp-content/uploads/9f45c1de819502e2_800x800ar1.jpg" style="height:18px"/>
                </div>
            </div>
        </div>
    </div>

</div>
