<p-dialog [(visible)]="display" [style]="{width: '600px'}">
    <p-header>
        Boka
    </p-header>
    <div class="" *ngIf="event && course">
        <div class="row">
            <div class="col-12">
                <h6 class="mb-3">{{course.CourseName}}</h6>
                <!--<p class="mb-0">{{head.course_categories}}</p>
                <p class="mb-0 font-weight-bold" *ngIf="course.locations">{{course.locations | join:" och "}} {{course.SingleEvent.StartDate | date:"d MMMM"}}</p>-->
                <ng-container *ngIf="!course.IsOnDemand">
                    <i class="far fa-calendar-alt"></i>
                    {{event.StartDate | date:"d MMM"}}

                    <i class="ml-3 far fa-clock"></i> {{ event.StartDate | date:"HH:mm" }} - {{ event.EndDate | date:"HH:mm" }}
                </ng-container>

                <div *ngIf="course.IsElearning" class="cursor-help d-inline"
                     pTooltip="Du har tillgång till kursen {{course.OnDemandAccessDays}} från bokningstillfället sedan går inte kursen att starta.">
                    <i class="fa-solid fa-globe"></i>
                    E-learning

                    <i class="fa-regular fa-circle-question"></i>
                </div>
                <div *ngIf="course.IsOnDemand && !course.IsElearning" class="cursor-help d-inline"
                     pTooltip="Du har tillgång till kursen {{course.OnDemandAccessDays}} från bokningstillfället sedan går inte kursen att starta.">
                    <i class="fa-solid fa-globe"></i>
                    Inspelad

                    <i class="fa-regular fa-circle-question"></i>
                </div>

                <!-- Old E-learning och OnDemand kurser (??)
                <div *ngIf="course.IsOnDemand" class="cursor-help d-inline"
                     pTooltip="Du har tillgång till kursen mellan den 1:a och 20:e den månad du är anmäld och du gör kursen på egen hand när det passar dig själv bäst."
                     tooltipPosition="bottom">
                    <i class="far fa-calendar-alt"></i>
                    {{event.StartDate | date:"d"}}-
                    {{event.EndDate | date:"d MMM"}}

                    <i class="fa-regular fa-circle-question"></i>
                </div>-->
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12 text-sm">
                Välj medarbetare att anmäla till denna kurs.
            </div>
        </div>

        <div class="row employee-list-header">
            <div class="col-5">
                <p class="mb-0">Namn</p>
            </div>
            <!--<div class="col-4">
                <p class="mb-0">Pers. nr</p>
            </div>-->
            <div class="col-7">
                <p class="mb-0">Anmäl</p>
            </div>
        </div>
        <div *ngIf="!statusLoaded" class="progress-wrapper my-3">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE"></p-progressSpinner>
            Hämtar dina medarbetare..
        </div>
        <div class="row employee-list employee-list-bg striped-rows" *ngIf="statusLoaded">
            <div *ngFor="let user of listEmployees" class="col-12 py-0" [class.highligt-warning]="user._approvementRequired == true">
                <div class="row mb-0 align-items-center">
                    <div class="col-5">
                        <p class="mb-0 ">{{ user.FirstName | titlecase }} {{ user.LastName | titlecase }}</p>
                        <p class="mt-2 mb-0 d-sm-none">{{ user.Email }}</p>
                    </div>
                    <!--<div class="col-4">
                        <p class="mb-0">{{ user.accessPosition }}</p>
                    </div>-->
                    <div class="col-7" *ngIf="user._approvementRequired == true">

                        <div class="align-items-center d-inline-flex text-sm font-italic text-gray-600">
                            <i class="pi pi-info-circle mr-2 text-gray-400" style="font-size: 1.1rem"></i>
                            Personen behöver logga in på nytt och godkänna våra villkor för att kunna bokas in.
                        </div>
                    </div>
                    <div class="col-7" *ngIf="user._approvementRequired == false">
                        <div class="p-field-checkbox flex" *ngIf="!user.subscription">
                            <p-checkbox
                                    [(ngModel)]="selectedUsers"
                                    [inputId]="'checkOption'+user.PersonId"
                                    name="selectedUsers"
                                    [value]="user.PersonId"
                            ></p-checkbox>
                            <label [for]="'checkOption'+user.PersonId" class="text-sm">Välj</label>
                        </div>
                        <span class="font-weight-bold text-sm text-success" *ngIf="user.subscription">
                            <i class="far fa-check-circle"></i> Anmäld
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer *ngIf="event && course">
        <button [disabled]="selectedUsers.length == 0 || isLoading" [label]="isLoading ? 'Skapar bokning..' : 'Skicka bokning'" class="w-100 p-button-lg btn-fw-700" pButton type="submit" (click)="sendSubmission()"
                ddGtagEvent [track]="{ on: 'click', action: 'create_booking', params: {'course':course.CourseName, 'eventDate': event.StartDate, 'eventId': event.EventId, 'countPersons': selectedUsers.length}}">
            <i *ngIf="isLoading" class="fas fa-cog fa-spin position-absolute"></i>
        </button>
    </p-footer>
</p-dialog>
