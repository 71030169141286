import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '@app/@core/auth';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to api url
		const currentUser = this.authenticationService.currentAuth;
		let token = '';

		if (currentUser) {
			token = this.authenticationService.currentAuth['token'];
		}
		//console.error('Token', token);

		request = request.clone({
			headers: request.headers.append('Authorization', token)
		});

		return next.handle(request);
	}
}
