import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpHeaders,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Clone the request to add the new header.
		const authReq = req.clone();
		// catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
		return next.handle(req).pipe(
			tap(
				(event: HttpResponse<any>) => {
					//success
					if (event.body) {
						if (event.body['error'] && event.body['error'] == 'unauthorized') {
							//navigate /delete cookies or whatever
							this.router.navigateByUrl(`/login`);
							// if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
							return of(EMPTY); // or EMPTY may be appropriate here
						}
					}
				},
				(err: any) => {
					//error
				}
			),
			catchError((err) => {
				if (err.status === 401) {
					// if you want to do sth. when 401 can code here
				} else if(err.status === 400) {
					//return of(err);
				}
				console.error('Error', err.status, err.message);
				return throwError(err);
			})
		);
	}

	private handleAuthError(err: HttpErrorResponse): Observable<any> {
		//handle your auth error or rethrow
	//console.error('handle auth error', err);

		if (err.status === 401 || err.status === 403) {
			//navigate /delete cookies or whatever
			this.router.navigateByUrl(`/login`);
			// if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
			return of(err.message); // or EMPTY may be appropriate here
		}
		return throwError(err);
	}
}
