import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '@core/auth';

@Directive({
	selector: '[ddIfProject]'
})
export class IfProjectDirective {
	private selectedProject: string;

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		public authenticationService: AuthenticationService) {
		this.selectedProject = this.authenticationService.getSelectedProject();
	}

	@Input() set ddIfProject(project: string) {
		if (project.toLowerCase() == this.selectedProject) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

}
