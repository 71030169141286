<div *ngIf="user" class="row d-flex justify-content-center h-75" @FadeIn>
    <div class="rounded mw-50 align-self-center" [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}">
        <div class="row mb-5">
            <div class="col" *ngIf="!inModal">
                <h1 class="text-center mb-4">Personuppgifter</h1>
            </div>
        </div>
        <form action="" method="post" (ngSubmit)="submitStep(form)" #form="ngForm">
            <div class="row justify-content-center" [class.pb-5]="!inModal">
                <div class="col px-0 justify-content-center" style="max-width: 350px">
                    <div class="row d-flex justify-content-between">
                        <div class="col-6">
                            <span class="p-float-label mb-4">
                                <input class="bg-white w-100" type="text" pInputText [ngModel]="user.FirstName" name="firstname" required/>
                                <label>Förnamn</label>
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label mb-4">
                                <input class="bg-white w-100" type="text" pInputText [ngModel]="user.LastName" name="surname" required/>
                                <label>Efternamn</label>
                            </span>
                        </div>
                    </div>
                    <span class="p-float-label mb-4">
                        <p-inputMask mask="999999-9999" [ngModel]="user.CivicRegistrationNumber" name="socialsecurityno" placeholder=""
                                     [autoClear]="false" styleClass="w-100 bg-white" [required]="true" [autoFocus]="true"></p-inputMask>
                        <label>Pers. nummer</label>
                    </span>
                    <span class="p-float-label mb-4">
                        <input class="w-100 bg-white" pInputText [ngModel]="user.Email" name="email" type="email" email required/>
                        <label>E-post</label>
                    </span>
                    <span class="p-float-label mb-4">
                        <input class="w-100 bg-white" type="text" pInputText [ngModel]="user.Mobile" name="mobilephone" required minlength="10" maxlength="10" pattern="[0-9]+"/>
                        <label>Mobiltelefon</label>
                    </span>
                    <div class="row justify-content-center pb-5" [class.pt-5]="!inModal">
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" label="Spara & gå vidare" [disabled]="!form.valid || isLoading" *ngIf="!inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" [label]="'Spara'" [disabled]="!form.valid || isLoading" *ngIf="inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
