import { Component, Input } from '@angular/core';
import { CourseEventInterface, CourseInterface } from '@shared/interfaces/course';
import { CoursesService } from '@shared/services/courses.service';

@Component({
	selector: 'dd-course-card-small',
	templateUrl: './course-card-small.component.html',
	styles: [`
		:host {
			width:100%;
		}
		.list-type-grid-header {
			height: 180px;
			background-position-x: center;
			background-position-y: top;
			background-repeat-x: no-repeat;
			background-repeat-y: no-repeat;
			background-size: cover;
		}

		::ng-deep p-card.p-element {
			min-width: 100%
		}

		:host ::ng-deep .p-card {
			
			overflow: hidden;
			height: 100%;
			border-radius: 1rem;
		}

		:host ::ng-deep .p-card .p-card-body {
			height: 100%;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}

		:host ::ng-deep .p-card .p-card-content {
			padding: 0;
		}

		::ng-deep .p-dataview .p-dataview-content {
			background: none;
		}
		.course-card-footer-dates { min-height: 46px;display: flex;    align-items: flex-end; }
		.course-card-new-badge {
			position: absolute;
			top: 0px;
			right: 0px;
			padding: 10px;
			border-radius: 0 0 0 20px;
			box-shadow: -1px 2px 5px rgba(0,0,0,0.5);
		}
	`]
})
export class CourseCardSmallComponent {
	futureEvents: CourseEventInterface[] = [];
	_isNewCourse: boolean;

	get course(): CourseInterface {
		return this._course;
	}

	@Input() set course(value: CourseInterface) {
		this._course = value;

		this.futureEvents = this.coursesService.getFutureEvents(this._course.Events)

		this._isNewCourse = false;//this.coursesService.userLastLogin <= this._course._CreatedTime;
	}

	private _course: CourseInterface;

	constructor(private coursesService: CoursesService) {
	}
}
