import { Component } from '@angular/core';
import { AuthenticationService } from '@core/auth';

@Component({
	selector: 'dd-project-name',
	template: `{{selected}}`,
	styles: []
})
export class ProjectNameComponent {
	selected: string;

	constructor(private authService: AuthenticationService) {
		let selected = this.authService.getSelectedProject();
		this.selected = selected.charAt(0).toUpperCase() + selected.slice(1);
	}

}
