import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'dd-supermario',
  templateUrl: './supermario.component.html',
  styles: [
  ]
})
export class SupermarioComponent {
	loginForm: any;
	isLoading = false;
	error: string | undefined;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private authenticationService: AuthenticationService,
		public dialogService: DialogService,
		private activatedRoute: ActivatedRoute
	) {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			remember: true,
		});
	}

	login() {
		this.isLoading = true;

		const login$ = this.authenticationService
			.loginSuperadmin(this.loginForm.value)
			.then(async (data) => {
				let result = data;
				console.error(data)

				if (result && !data.error) {
					await this.router.navigate(['/superadmin/booking']);
				} else {
					if(data.error)
						alert(data.error);
				}
			})
			.finally(() => {
				this.loginForm.markAsPristine();
				this.isLoading = false;
				//untilDestroyed(this);
			});
	}
}
