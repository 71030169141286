import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BookCourseDialog } from '@app/pages/courses/book-course/book-course.dialog';
import { BookingButtonButton } from '@app/pages/courses/book-course/booking-button/booking-button.button';
import { ListTypeGridComponent } from '@app/pages/courses/list-types/list-type-grid/list-type-grid.component';
import { CourseCardOverlayComponent } from '@shared/components/course-card-overlay/course-card-overlay.component';
import { ProjectSelectorComponent } from '@shared/components/project-selector/project-selector.component';
import { ClearInputDirective } from '@shared/directives/clear-input.directive';
import { DebounceClickDirective } from '@shared/directives/debounce-click.directive';
import { CourseFilterPipe } from '@shared/pipes/course-filter.pipe';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { MimeFontawesomePipe } from '@shared/pipes/mimefontawesome.pipe';
import { MissingDataPipe } from '@shared/pipes/missing-data.pipe';
import { ReplacePipe } from '@shared/pipes/replace.pipe';
import { SanitizeHtmlPipe } from '@shared/pipes/sanitize-html.pipe';
import { SortByAccessRolePipe } from '@shared/pipes/sort-by-access-role';
import { TruncateFilenamePipe } from '@shared/pipes/truncate-filename.pipe';
import { UcfirstPipe } from '@shared/pipes/ucfirst.pipe';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AttendingWidget } from '../pages/submissions/attending/attending.widget';
import { CancelSubmissionButton } from '../pages/submissions/cancel-submission/cancel-submission.button';
import { CancelSubmissionDialog } from '../pages/submissions/cancel-submission/cancel-submission.dialog';
import { ActionNeededButtonComponent } from './components/action-needed-button/action-needed-button.component';
import { ActionNeededComponent } from './components/action-needed/action-needed.component';
import { CategoryTagComponent } from './components/category-tag/category-tag.component';
import { CourseCardSmallComponent } from './components/course-card-small/course-card-small.component';
import { CourseDateComponent } from './components/course-date/course-date.component';
import { CourseTypeTagComponent } from './components/course-type-tag/course-type-tag.component';
import { EmptyListMsgComponent } from './components/empty-list-msg/empty-list-msg.component';
import { PersonsProjectTagComponent } from './components/persons-project-tag/persons-project-tag.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { GtagEventDirective } from './directives/gtag-event.directive';
import { LoaderComponent } from './loader/loader.component';
import { CourseTypeFilterPipe } from './pipes/course-type-filter.pipe';
import { EmployeePositionPipe } from './pipes/employee-position.pipe';
import { FilterOnCategoriesPipe } from './pipes/filter-on-categories.pipe';
import { FormatSsnrPipe } from './pipes/format-ssnr.pipe';
import { GradeToPercentPipe } from './pipes/grade-to-percent.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { IsEmployeePipe } from './pipes/is-employee.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { IfProjectDirective } from './directives/if-project.directive';
import { ProjectNameComponent } from './components/project-name/project-name.component';
import { DebounceInputDirective } from './directives/debounce-input.directive';
import { FilterOnLeaderPipe } from './pipes/filter-on-leader.pipe';

@NgModule({
	imports: [
		CommonModule,
		InputTextModule,
		PanelMenuModule,
		ButtonModule,
		TooltipModule,
		CardModule,
		PasswordModule,
		RadioButtonModule,
		FormsModule,
		DropdownModule,
		DialogModule,
		ConfirmDialogModule,
		CheckboxModule,
		ToastModule,
		ProgressSpinnerModule,
		InputTextareaModule,
		InputMaskModule,
		TableModule,
		ChartModule,
		AccordionModule,
		UiSwitchModule.forRoot({
			//size: 'small',
			color: '#84914D',
			checkedLabel: 'Ja',
			uncheckedLabel: 'Nej',
			checkedTextColor: '#fff',
			uncheckedTextColor: '#495057'
		}),
		RippleModule,
		CalendarModule,
		MultiSelectModule,
		MenuModule,
		OverlayPanelModule,
		SelectButtonModule,
		DataViewModule
	],
	declarations: [
		LoaderComponent,
		//BaseWizComponent,
		BookCourseDialog,
		BookingButtonButton,
		SortByPipe,
		SortByAccessRolePipe,
		ReplacePipe,
		UcfirstPipe,
		JoinPipe,
		AttendingWidget,
		CancelSubmissionButton,
		CancelSubmissionDialog,
		EmployeePositionPipe,
		FormatSsnrPipe,
		IsEmployeePipe,
		GradeToPercentPipe,
		SanitizeHtmlPipe,
		MimeFontawesomePipe,
		TruncateFilenamePipe,
		FileSizePipe,
		CourseFilterPipe,
		ClearInputDirective,
		DebounceClickDirective,
		MissingDataPipe,
		CategoryTagComponent,
		ActionNeededComponent,
		ActionNeededButtonComponent,
		GroupByPipe,
		FilterOnCategoriesPipe,
		CopyClipboardDirective,
		ProjectSelectorComponent,
		CourseCardSmallComponent,
		CourseCardOverlayComponent,
		CourseTypeTagComponent,
		CourseDateComponent,
		EmptyListMsgComponent,
		CourseTypeFilterPipe,
		GtagEventDirective,
		PersonsProjectTagComponent,
		IfProjectDirective,
		ProjectNameComponent,
		DebounceInputDirective,
		ListTypeGridComponent,
  FilterOnLeaderPipe
	],
	exports: [
		LoaderComponent,
		InputTextModule,
		PanelMenuModule,
		ButtonModule,
		DialogModule,
		TooltipModule,
		CardModule,
		PasswordModule,
		RadioButtonModule,
		ConfirmDialogModule,
		FormsModule,
		DropdownModule,
		SortByPipe,
		SortByAccessRolePipe,
		ReplacePipe,
		UcfirstPipe,
		FormatSsnrPipe,
		CheckboxModule,
		ToastModule,
		JoinPipe,
		EmployeePositionPipe,
		BookCourseDialog,
		BookingButtonButton,
		ProgressSpinnerModule,
		AttendingWidget,
		CancelSubmissionButton,
		CancelSubmissionDialog,
		InputTextareaModule,
		InputMaskModule,
		TableModule,
		ChartModule,
		AccordionModule,
		UiSwitchModule,
		RippleModule,
		SanitizeHtmlPipe,
		MimeFontawesomePipe,
		TruncateFilenamePipe,
		FileSizePipe,
		CourseFilterPipe,
		CalendarModule,
		MultiSelectModule,
		ClearInputDirective,
		DebounceClickDirective,
		MissingDataPipe,
		CategoryTagComponent,
		GroupByPipe,
		FilterOnCategoriesPipe,
		CopyClipboardDirective,
		ProjectSelectorComponent,
		CourseCardSmallComponent,
		CourseCardOverlayComponent,
		CourseTypeTagComponent,
		EmptyListMsgComponent,
		CourseTypeFilterPipe,
		GtagEventDirective,
		PersonsProjectTagComponent,
		IfProjectDirective,
		ProjectNameComponent,
		DebounceInputDirective,
		ListTypeGridComponent,
		FilterOnLeaderPipe
	],
})
export class SharedModule {
}
