import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'dd-esf',
	templateUrl: './esf.component.html',
	styles: []
})
export class EsfComponent implements OnInit {
	@Input('inModal') inModal: boolean = false;

	constructor() {
	}

	ngOnInit(): void {
	}

}
