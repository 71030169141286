import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { BaseWizComponent } from '@shared/components/base-wiz/base-wiz.component';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-step2-user',
	templateUrl: './step2-user.component.html',
	styles: [],
})
export class Step2UserComponent extends BaseWizComponent implements OnInit, OnDestroy {

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService) {
		super(companiesService, config, dialogService, route, authService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	submitStep(form: NgForm) {
		super.baseUserSubmitStep(form).then((result: any) => {
			if (this.ref) {
				this.ref.close()
			}
		});
	}

	ngOnDestroy() {

	}
}
