import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { ResponsiveService } from '@shared/services/responsize.service';

@Component({
	selector: 'dd-finished-survey',
	templateUrl: './finished-survey.component.html',
	styles: [`
		::ng-deep .p-card-body {
			width: 100%
		}
	`],
	animations: [FadeIn]
})
export class FinishedSurveyComponent implements OnInit {

	constructor(private responsiveService: ResponsiveService) {
	}

	ngOnInit(): void {
		this.responsiveService.forceOpenMenu.emit(true);
	}

}
