import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService, AuthUser } from '@app/@core/auth';
import { environment } from '@env/environment';
import { CompanyInterface } from '@shared/interfaces/company-interface';
import { Kwikk } from '@shared/interfaces/kwikk';
import { InvitesInterface, UserInterface } from '@shared/interfaces/user-interface';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CompaniesService {
	private regions: any;
	public kwikkData: Kwikk;

	public employeesSource = new BehaviorSubject<UserInterface[]>(null);
	public employees: Observable<UserInterface[]> = this.employeesSource.asObservable();

	public invitesSource = new BehaviorSubject<InvitesInterface[]>(null);
	public invites: Observable<InvitesInterface[]> = this.invitesSource.asObservable();

	public regionsSource = new BehaviorSubject<any[]>(null);
	public regions$: Observable<UserInterface[]> = this.regionsSource.asObservable();

	@Output() subFinished:EventEmitter<void> = new EventEmitter();

	constructor(private authService: AuthenticationService, private http: HttpClient) {

		if(!this.authService.isUser()) {
			return;
		}

		/** CLean up data on logout */
		this.authService.currentUserSubject.subscribe((data) => {
			if(data === null) {
				this.employeesSource.next(null);
			}
		});

		if (this.authService.currentUser) this.user = this.authService.currentUser;
		else {
			this.fetchUser().then((data) => {
				this.user = data;
			});
		}

		if (this.authService.currentCompany) this.company = this.authService.currentCompany;
		else {
			this.fetchCompany().then((data) => {
				this.company = data;
			});
		}

		//this.fetchRegions().then();
		if(!this.authService.isSuperAdmin())
			this.fetchEmployees();
	}

	private _user: UserInterface;

	get user(): UserInterface {
		return this._user;
	}

	set user(value: UserInterface) {
		this._user = value;
	}

	private _company: CompanyInterface;

	get company(): CompanyInterface {
		return this._company;
	}

	set company(value: CompanyInterface) {
		this._company = value;
	}



	acceptInvite(invite: InvitesInterface) {
		let formData = new FormData();
		formData.append('subaction', 'acceptInvite');
		formData.append('params', JSON.stringify(invite));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	saveOrderReturn(kwikkEvent: any)
	{
		let formData = new FormData();
		formData.append('subaction', 'saveOrderReturn');
		formData.append('params', JSON.stringify(kwikkEvent));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	finalizeCompanyWizard() {
		localStorage.setItem('finalizedCompanyWizard', 'true');
	}

	finalizeUserWizard() {
		localStorage.setItem('finalizedUserWizard', 'true');

		const form = <NgForm>{
			value: {
				finishedProfile: "1"
			}
		};

		this.saveUserStep(form).then();
	}

	saveCompanyStep(form: NgForm) {
		let formData = new FormData();
		formData.append('subaction', 'saveCompanyStep');
		formData.append('params', JSON.stringify(form.value));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	saveUserStep(form: NgForm) {
		let formData = new FormData();
		formData.append('subaction', 'saveUserStep');
		formData.append('params', JSON.stringify(form.value));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	createFortnoxInvoice() {
		let formData = new FormData();
		formData.append('subaction', 'createFortnoxInvoice');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((result: any) => {
				return result;
			});
	}

	deleteEmployee(userID: number) {
		let formData = new FormData();
		formData.append('subaction', 'deleteEmployee');
		formData.append('userID', userID.toString());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((result: any) => {
				if(!result.toastMessage.error) {
					let users = this.employeesSource.getValue();
					users.splice(users.findIndex(i => i.PersonId == userID), 1);
					this.employeesSource.next(users)
				}
				return result;
			});
	}

	addEmployee(form: NgForm) {
		let formData = new FormData();
		formData.append('subaction', 'inviteEmployee');
		formData.append('p46_ca_register_email', form.value.email);
		formData.append('p46_ca_register_user_type', form.value.accessRole);
		formData.append('p46_ca_register_project', this.authService.getSelectedProject());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data: any) => {
				if(!data.toastMessage.error) {
					//let invites = this.invitesSource.getValue();
					//invites.push(data.toastMessage.data.invites);
					this.invitesSource.next(data.invites)
				}
				return data;
			});
	}

	/**
	 * @todo convert to directive or pipe?
	 */
	isLeader() {
		if (this.user) return this.user.position != 'employee';
	}

	fetchRegions() {
		if (this.regions) return Promise.resolve(this.regions);

		return Promise.resolve(this.regions)

		let formData = new FormData();
		formData.append('subaction', 'getRegions');
		formData.append('getAll', '1');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data:any) => {
				this.regions = data;
				this.regionsSource.next(data);
				return data;
			});
	}

	fetchKwikkData() {
		if (this.kwikkData) return Promise.resolve(this.kwikkData);

		let formData = new FormData();
		formData.append('subaction', 'getKwikkData');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <Kwikk>res)
			.then((data: Kwikk) => {
				this.kwikkData = data;
				return data;
			});
	}

	fetchEmployees() {
		//if (this.employees) return Promise.resolve(this.employees);

		let formData = new FormData();
		formData.append('subaction', 'getCompanyUsers');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <any[]>res)
			.then((data: any[]) => {
				this.employeesSource.next(data['persons']);
				this.invitesSource.next(data['invites']);
			});
	}

	fetchUser() {
		if (this.user) return Promise.resolve(this.user);

		let formData = new FormData();
		formData.append('subaction', 'getUser');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <UserInterface>res)
			.then((data: UserInterface) => {
				this.user = data;
				return data;
			});
	}

	fetchCompany() {
		if (this.company) return Promise.resolve(this.company);

		let formData = new FormData();
		formData.append('subaction', 'getCompany');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <CompanyInterface>res)
			.then((data: CompanyInterface) => {
				this.company = data;
				return data;
			});
	}

	validateInvite(token: string) {

		let formData = new FormData();
		formData.append('subaction', 'validateInvite');
		formData.append('token', token);

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <InvitesInterface>res)
			.then((data: InvitesInterface) => {
				return data;
			});
	}

	saveUserProjects(personID: number, projects: any[]) {
		let formData = new FormData();
		formData.append('subaction', 'saveUserProjects');
		formData.append('personID', personID.toString());
		formData.append('projects', JSON.stringify(projects));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}
}
