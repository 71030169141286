<p-toast position="bottom-right"></p-toast>

<div class="container-fluid login-container h-100">

    <div class=" login-box">

        <div class="card-box bg-white text-center border-round-big p-5 shadow d-flex my-3">
            <div class="row md:px-5 py-3">

                <div class="col-12">

                    <div class="row mb-4 logo-container">
                        <div class="col-12 text-center">
                            <img src="./assets/images/Affarskompetens_Logotyp_sv.png" style="height:25px" />
                        </div>
                        <!--<div class="col-6 col-md-2 text-center">
                            <img alt="EurSocfond" src="./assets/images/SV-V-Medfinansieras-av-Europeiska-unionen_POS_POS.png" style="height:80px"/>
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <img alt="gävle kommun" src="https://www.affarskompetens.se/wp-content/uploads/Gavle-kommun_RGB.png" style="height:60px"/>
                        </div>
                        <div class="col-12 col-md-3 text-center">
                            <img alt="företagarna" src="https://www.affarskompetens.se/wp-content/uploads/9f45c1de819502e2_800x800ar1.jpg" style="height:18px"/>
                        </div>-->
                    </div>

                    <div class="">
                        <div class="card noborder">
                            <div class="card-body p-0">
                                <div class="flip-card" [class.flip]="isLoadingBankid && useQRCodeBankid">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <h2 class="mb-3 text-center">Välkommen till portalen </h2>
                                            <hr class="mb-4">
                                            <form (ngSubmit)="loginBankid()" novalidate class="text-center flex flex-column align-items-center">
                                                <div [hidden]="!error || isLoadingBankid" class="alert alert-danger">Fel användarnamn eller lösenord.</div>
                                                <div class="form-group mb-3">
                                                    <h5 class="text-center" style="font-size: 1.5rem;">Logga in</h5>
                                                </div>

                                                <div class="">
                                                    <button [disabled]="isLoadingBankid" class="p-button-rounded p-button-outlined p-button-secondary mx-2 mb-2" type="button"
                                                            (click)="loginBankid(true)"
                                                            *ngIf="!showLeaderForm" pButton
                                                            ddGtagEvent [track]="{ on: 'click', action: 'authMethod', params: {'method':'QR'}}">
                                                        <i *ngIf="isLoadingBankid && useQRCodeBankid" class="fas fa-cog fa-spin"></i>
                                                        <img class="mr-1" src="./assets/images/bankid_logo.svg" alt="BankID.">
                                                        <span>BankID med QR-kod</span>
                                                    </button>
                                                    <button [disabled]="isLoadingBankid" class="p-button-rounded p-button-outlined p-button-secondary mx-2 mb-2" type="button" (click)="loginBankid(false)"
                                                            *ngIf="!showLeaderForm" pButton
                                                            ddGtagEvent [track]="{ on: 'click', action: 'authMethod', params: {'method':'onDevice'}}">
                                                        <i *ngIf="isLoadingBankid && !useQRCodeBankid" class="fas fa-cog fa-spin"></i>
                                                        <img class="mr-1" src="./assets/images/bankid_logo.svg" alt="BankID.">
                                                        <span>BankID på denna enhet</span>
                                                    </button>
                                                </div>
                                                <div *ngIf="bankIDCanceled" class="mt-2 text-sm alert alert-danger">
                                                    <span class="pi pi-exclamation-circle"></span> Det tog för lång tid att logga in, försök igen..
                                                </div>
                                            </form>

                                        </div>

                                        <div class="flip-card-back flex flex-column">
                                            <div class="text-center mb-3">
                                                <h6 class="mb-2">
                                                    <img class="" style="height: 45px" src="./assets/images/bankid_logo.svg" alt="BankID.">Mobilt BankID med QR-kod
                                                </h6>
                                                <img style="width:200px;height:200px;cursor: none" [src]="qrCodeUrl" *ngIf="qrCodeUrl !== false"/>
                                            </div>
                                            <i [hidden]="qrCodeUrl !== false" class="fas fa-cog fa-spin fa-x3"></i>

                                            <div>
                                                <h6>Instruktioner</h6>
                                                <ol class="text-sm">
                                                    <li>&Ouml;ppna BankID-appen p&aring; den enhet du anv&auml;nde f&ouml;r att logga in</li>
                                                    <li>Tryck p&aring; QR-symbolen i BankID-appen <img src="./assets/images/qrcode-scan-white.svg" style="height:20px;margin-left:5px;"/></li>
                                                    <li>Rikta kameran mot QR-koden</li>
                                                </ol>
                                            </div>
                                            <button class="btn btn-primary noborder bg-white text-primary w-100 mt-2" type="button"
                                                    (click)="isLoadingBankid=false;bankIDStatusPending=false;bankIDCanceled=false">Avbryt
                                            </button>
                                        </div>

                                        <div class="flip-card-back" *ngIf="false">
                                            <form (ngSubmit)="loginLeader()" [formGroup]="loginFormLeader" novalidate>
                                                <div [hidden]="!error || isLoading" class="alert alert-danger">Fel användarnamn eller lösenord.</div>
                                                <div class="form-group text-left">
                                                    <label class="d-block infotext">
                                                        <span>E-post</span>
                                                        <input autocomplete="usernameEmail" pInputText class="form-control" formControlName="usernameEmail" required="true" type="email"
                                                               email/>
                                                        <small [hidden]="loginFormLeader.controls.usernameEmail.valid || loginFormLeader.controls.usernameEmail.untouched"
                                                               class="text-danger-login"
                                                               translate>
                                                            E-post krävs för inloggning
                                                        </small>
                                                    </label>
                                                    <label class="d-block infotext mt-2">
                                                        <span>Lösenord</span>
                                                        <input [placeholder]="'' | translate" autocomplete="current-password" class="form-control" formControlName="password" required
                                                               type="password"/>
                                                        <span hidden>Lösenord</span>
                                                        <small [hidden]="loginFormLeader.controls.password.valid || loginFormLeader.controls.password.untouched" class="text-danger-login"
                                                               translate>
                                                            Lösenord krävs för inloggning
                                                        </small>
                                                    </label>
                                                    <div class="">
                                                        <div class="p-field-checkbox pl-2 mt-2">
                                                            <p-checkbox binary="true" formControlName="remember" inputId="remember"></p-checkbox>
                                                            <label class="" for="remember">Kom ihåg mig</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button [disabled]="loginFormLeader.invalid || isLoading" class="btn btn-primary noborder bg-white text-primary w-100" type="submit"
                                                        *ngIf="showLeaderForm">
                                                    <i [hidden]="!isLoading" class="fas fa-cog fa-spin mr-2"></i>
                                                    <span *ngIf="!isLoading">Logga in</span>
                                                    <span *ngIf="isLoading">Loggar in..</span>
                                                </button>
                                                <span (click)="showForgotDialog()" class="small py-3 mt-2 d-block cursor-pointer float-left">Glömt ditt lösenord?</span>
                                                <span (click)="showLeaderForm=false" class="small py-3 mt-2 d-block cursor-pointer float-right">Deltagare?</span>
                                            </form>
                                        </div>
                                    </div>
                                    <p-accordion styleClass="mt-3 text-sm bg-transparent" *ngIf="!isLoadingBankid">
                                        <p-accordionTab header="Logga in med lösenord?" headerStyleClass="bg-transparent border-0 font-normal text-center" contentStyleClass="border-0">
                                            <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
                                                <div [hidden]="!error || isLoading" class="alert alert-danger">Fel användarnamn eller lösenord.</div>
                                                <div class="form-group text-left">
                                                    <label class="d-block infotext pb-0">
                                                        <span>Personnummer</span>
                                                        <p-inputMask [autoClear]="false" [required]="true"
                                                                     autocomplete="username" formControlName="username" mask="99999999-9999" placeholder="YYYYMMDD-NNNN"
                                                                     styleClass="form-control"></p-inputMask>
                                                        <small
                                                                [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                                                                class="text-danger-login"
                                                                translate
                                                        >
                                                            Personnummer krävs för inloggning
                                                        </small>
                                                    </label>
                                                    <label class="d-block infotext">
                                                        <span>Lösenord</span>
                                                        <input
                                                                [placeholder]="'' | translate"
                                                                autocomplete="current-password"
                                                                class="form-control"
                                                                formControlName="password"
                                                                required
                                                                type="password"
                                                        />
                                                        <span hidden>Lösenord</span>
                                                        <small
                                                                [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                                                                class="text-danger-login"
                                                                translate
                                                        >
                                                            Lösenord krävs för inloggning
                                                        </small>
                                                    </label>
                                                </div>
                                                <button [disabled]="loginForm.invalid || isLoading" class="p-button-rounded p-button-primary" type="submit" pButton
                                                        *ngIf="!showLeaderForm"
                                                        ddGtagEvent [track]="{ on: 'click', action: 'authMethod', params: {'method':'password'}}">
                                                    <i [hidden]="!isLoading" class="fas fa-cog fa-spin mr-2"></i>
                                                    <span *ngIf="!isLoading">Logga in</span>
                                                    <span *ngIf="isLoading">Loggar in..</span>
                                                </button>
                                                <!--<span (click)="showForgotDialog()" class="small py-3 mt-2 d-block cursor-pointer float-left">Glömt ditt lösenord?</span>
                                                <span (click)="showLeaderForm=true" class="small py-3 mt-2 d-block cursor-pointer float-right">Kursledare?</span>-->
                                            </form>
                                        </p-accordionTab>
                                    </p-accordion>

                                    <p class="text-sm mt-1 text-muted">Om du f&aring;r problem med att logga in kontakta: <a href="mailto:support@affarskompetens.se">support@affarskompetens.se</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--
    <div class="col-md-7 col-lg-8 login-right-col d-flex align-items-center login-right-col">
        <div class="row pb-5 d-none d-md-block w-100">
            <div class="col-10 offset-xl-2 pb-5">
                <div class="display-4" style="line-height: 1.5;font-weight: 700;color: #77262E;font-family: 'Merriweather',serif">
                    Vad trevligt att du kommit hit!<br /><br />
                    Vi vill ge dig insikt, kunskap och verktyg<br />
                    för att våga, växa, och vinna.<br />
                    Idag och i morgon.<br /><br />
                    Välkommen till <br />
                    <i>Företagsutveckling på riktigt</i>
                </div>
            </div>
        </div>

    </div>
    -->

</div>
