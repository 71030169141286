import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'courseFilter',
	pure: true
})
export class CourseFilterPipe implements PipeTransform {

	transform(value: any, categories: number[], types: string|number[], textSearch: string): unknown {
		// value = {data: value, key: idkey to check
		// value = form table list
		// filter valda kategorier
		//console.error(value, categories, types, textSearch);


		/*if (value === undefined || value === null ||
			(
				(categories.length == 0 || categories[0] == 0) && (types.length == 0 || types[0] == 0)
			)) {
			return value;
		}*/

		if (typeof value == 'string') {
			value = [value];
		}

		//console.error('filterOnCategories', value, categories, types);

		let filtered = value;

		if(types.length > 0 && types[0] !== 0) {
			filtered = filtered.filter(
				function (e: any) {
					return this.findIndex((i:any) => i.value == e.CourseType) !== -1
				},
				types
			);
		} else {
			//console.error('types filter empty', filtered);
			filtered = filtered.filter((c:any) => c.CourseType != 'Webinar (inspelat)');
			//console.error('types filter empty after', filtered);
		}

		if(categories.length > 0 && categories[0] !== 0) {
			filtered = filtered.filter(
				function (e: any) {
					return this.findIndex((i:any) => i.label == e.CategoryName.trim()) !== -1
					//return this.indexOf(e.CategoryId) > -1;
				},
				categories
			);
		}

		if(textSearch && textSearch != "" && textSearch.length > 3) {
			textSearch = textSearch.toLowerCase();
			filtered = filtered.filter((i:any) => i.CourseName.toLowerCase().includes(textSearch) || i.CourseDescriptionShort.toLowerCase().includes(textSearch));
		}

		//console.error('Filtered:', filtered);
		return filtered;
	}
}
