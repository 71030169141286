import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nModule } from '@app/i18n';
import { ForgotPasswordDialog } from '@app/pages/login/forgot-password/forgot-password.dialog';
import { LoginComponent } from '@app/pages/login/login.component';
import { SupermarioComponent } from '@app/pages/login/supermario/supermario.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, TranslateModule, NgbModule, I18nModule, AuthRoutingModule, CheckboxModule, MessageModule, FormsModule, ButtonModule, InputTextModule, ToastModule, InputMaskModule, AccordionModule, SharedModule, MessagesModule],
	declarations: [LoginComponent, ForgotPasswordDialog,
	SupermarioComponent
	],
	providers: [MessageService]
})
export class AuthModule {
}
