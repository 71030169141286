import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthenticationService, AuthUser } from '@core/auth';
import { environment } from '@env/environment';
import { CompanyInterface } from '@shared/interfaces/company-interface';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { retry, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AnalysisService {

	public questionTypes = [
		{id: 1, type: 'graded'},
		{id: 2, type: 'input'},
		{id: 3, type: 'single'},
		{id: 4, type: 'multiple'},
		{id: 5, type: 'prioritizeanswers'},
		{id: 6, type: 'preset'}
	];

	public analysesSource = new BehaviorSubject<any>(null);
	public analysis$: Observable<any> = this.analysesSource.asObservable();

	public analyseResultSource = new BehaviorSubject<any>(null);
	public analyseResult$: Observable<any> = this.analyseResultSource.asObservable();

	public analyseAverageSource = new BehaviorSubject<any>(null);
	public analyseAverage$: Observable<any> = this.analyseAverageSource.asObservable();

	public resultSource = new BehaviorSubject<any>(null);
	public result$: Observable<any> = this.resultSource.asObservable();

	@Output() steppedForward = new EventEmitter<boolean>();
	@Output() steppedBack = new EventEmitter<boolean>();

	@Output() collectAnswer = new EventEmitter<boolean>();

	public returnAnswerSource = new BehaviorSubject<any>(null);
	public returnAnswer$: Observable<any> = this.returnAnswerSource.asObservable();

	/**
	 * Spara analysresultat och hämta initierat resultat
	 */
	session: any;
	analyzeResultID: number = 0;

	/**
	 * Emitts { head: this.head , course: this.course }
	 */
	//public openBookingDialog: EventEmitter<any> = new EventEmitter<any>();

	subscriptions: Subscription[] = [];

	constructor(private authService: AuthenticationService, private http: HttpClient) {

		/** CLean up data on logout */
		this.authService.currentUserSubject.subscribe((data) => {
			if(data === null) {
				this.analyseResultSource.next(null);
				this.analysesSource.next(null);
				this.analyseAverageSource.next(null);
				this.resultSource.next(null);
				this.returnAnswerSource.next(null);
			}
		});
	}

	_clearSubscriptions()
	{
		this.subscriptions.forEach((subscription) => subscription.unsubscribe())
	}

	fetchAnalyseAverages() {
		let formData = new FormData();
		formData.append('subaction', 'getAnalyseAverages');

		if (this.analyseAverageSource.getValue()) {
			return new Promise(resolve => {
				resolve(this.analyseAverageSource.getValue());
			});
		}

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.analyseAverageSource.next(data);
				return data;
			});
	}

	getFileNameFromResponseContentDisposition(res: Response) {
		const contentDispositionHeaderResult = res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
		return contentDispositionHeaderResult.replace(/"/g, '');
	}

	downloadAnalyzeResult(analyzeID: number, resultID: number, currentUser: AuthUser, currentCompany: CompanyInterface) {
		///wp-admin/admin-ajax.php?action=p46_ca_exports_handler&reports_show_course=<?php echo $courseID; ?>&get_unsubscribed=0
		//https://portal.affarskompetens.se/wp-admin/admin-ajax.php?action=ng_ca_auth_actions

		let formData = new FormData();
		formData.append('subaction', 'downloadAnalyzeResult');
		formData.append('resultID', resultID.toString());
		formData.append('analyzeID', analyzeID.toString());
		formData.append('person', currentUser.FirstName+' '+currentUser.LastName);
		formData.append('customer', currentCompany.CustomerName);

		return this.http.post(environment.serverUrl, formData, {observe: 'response', responseType: 'blob'})
			.toPromise()
			.then((res) => res)
			.then((res:any) => {
				return {
					blob: new Blob([res.body], {type: res.headers.get('Content-Type')}),
					filename: this.getFileNameFromResponseContentDisposition(res)
				}
			});
	}

	fetchAnalyseResult(analyzeID: number, resultID: number) {
		let formData = new FormData();
		formData.append('subaction', 'getAnalyseResult');
		formData.append('analyzeID', analyzeID.toString());
		formData.append('resultID', resultID.toString());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.analyseResultSource.next(data);
				return data;
			});
	}

	fetchAnalyseResults() {
		let formData = new FormData();
		formData.append('subaction', 'getAnalyseResults');

		if (this.analyseResultSource.getValue()) {
			return new Promise(resolve => {
				resolve(this.analyseResultSource.getValue());
			});
		}

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.analyseResultSource.next(data);

				return data;
			});
	}

	fetchAnalyses() {
		let formData = new FormData();
		formData.append('subaction', 'getAnalyses');

		if (this.analysesSource.getValue()) {
			return new Promise(resolve => {
				resolve(this.analysesSource.getValue());
			});
		}

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.analysesSource.next(data);

				return data;
			});
	}

	saveAnswer(answer: any)
	{
		//console.error('saveAnswer')
		let formData = new FormData();
		formData.append('subaction', 'saveAnalysisAnswer');
		formData.append('params', JSON.stringify(answer));

		return this.http
			.post(environment.serverUrl, formData)
			.pipe(retry(1))
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.session = data['session']['p46_ca_answers_session'];
				return data;
			});
	}

	updateDevNeeds(answer: any, finish: boolean = false)
	{
		//console.error('updateDevNeeds')
		let formData = new FormData();
		formData.append('subaction', 'updateAnalysisAnswer');
		formData.append('params', JSON.stringify(answer));
		formData.append('finish', finish ? '1' : '0');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				//this.session = data['session']['p46_ca_answers_session'];
				return data;
			});
	}

	initResult(analyzeID: number) {
		let formData = new FormData();
		formData.append('subaction', 'analyzeActions');
		formData.append('analyze-action', 'start');
		formData.append('analyzeID', analyzeID.toString());

		/*if (this.resultSource.getValue()) {
			return new Promise(resolve => {
				resolve(this.resultSource.getValue());
			});
		}*/

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.resultSource.next(data);

				if(data['getAnalyses']) {
					console.error(data['getAnalyses']);
					let getAll = this.analysesSource.getValue();
					getAll[getAll.findIndex((i:any) => i.analyzeID == analyzeID)] = data['getAnalyses'];
					this.analysesSource.next(getAll);
				}

				return data;
			});
	}
}
