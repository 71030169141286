<p-toast position="bottom-right"></p-toast>
<div
        *ngIf="!started"
        @FadeIn
        class="position-fixed p-4 justify-content-center min-vw-100 min-vh-100 bg-grey d-flex align-items-center start-screen"
>
    <div class="row text-center">
        <div class="col-xs-5">
            <ng-container *ngIf="!companiesService.isLeader() || hasPayedFee">
                <h1 class="text-center mb-4">Välkommen till <br/>Affärskompetens Sverige</h1>
                <p class="text-center mb-3">
                    Vi kommer nu ta dig igenom ett par steg för att säkerställa att vi har rätt information om dig som användare.
                </p>
                <div class="mt-5">
                    <button
                            (click)="start()"
                            class="px-5 p-button-lg btn-fw-700 mb-5 w-100"
                            label="Nu kör vi!"
                            pButton
                            type="button"
                            style="max-width: 350px"
                    ></button>
                </div>
            </ng-container>

            <ng-container *ngIf="companiesService.isLeader() && !hasPayedFee">
                <h1 class="text-center mb-4">Nu är du halvvägs!</h1>
                <p class="text-center mb-3">
                    När du nu blivit lite varm i kläderna tar vi dig igenom några steg om dig som användare.
                </p>
                <div class="mt-5">
                    <button
                            (click)="start()"
                            class="px-5 p-button-lg btn-fw-700 mb-5 w-100"
                            label="Nu kör vi igen!"
                            pButton
                            type="button"
                            style="max-width: 350px"
                    ></button>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="started && !finished" @FadeIn class="container-fluid bg-grey justify-content-center pt-4 pt-sm-0">
    <div class="row justify-content-center mb-5 d-none d-sm-flex">
        <div class="col-xs-12 col-sm-10 col-xl-6 justy-content-center pt-5">
            <p-steps [activeIndex]="realActiveIndex()" [model]="items" [readonly]="true" *ngIf="items.length > 0"></p-steps>
        </div>
    </div>

    <ng-container [ngSwitch]="activeIndex">
        <dd-step1-user *ngSwitchCase="0" (stepValidated)="nextStep(1)"></dd-step1-user>
        <dd-step2-user *ngSwitchCase="1" (stepValidated)="nextStep(2)"></dd-step2-user>
        <dd-step3-user *ngSwitchCase="2" (stepValidated)="nextStep(3)"></dd-step3-user>
        <dd-step4-user *ngSwitchCase="3" (stepValidated)="nextStep(4)"></dd-step4-user>
        <dd-step5-user *ngSwitchCase="4" (stepValidated)="nextStep(5)"></dd-step5-user>
    </ng-container>
</div>

<div
        *ngIf="finished"
        @FadeIn
        class="position-fixed justify-content-center min-vw-100 min-vh-100 bg-grey d-flex align-items-center start-screen"
>
    <div class="row text-center px-3 px-sm-0">
        <div class="col-12">
            <h1 class="text-center mb-4">Härligt!</h1>
            <p class="text-center">Nu är du redo att använda portalen och påbörja din utvecklingsresa.</p>
            <div class="mt-5">
                <button
                        pButton
                        type="submit"
                        class="w-100  p-button-lg btn-fw-700 mb-5"
                        label="Till portalen"
                        (click)="gotoHome()"
                ></button>
            </div>
        </div>
    </div>
</div>
