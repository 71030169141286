import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/auth';
import { AnalysisService } from '@shared/services/analysis.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';

@Component({
	selector: 'dd-analyse-results',
	templateUrl: './analyse-results.component.html',
	styles: [`
		.th-heading {
			text-align: center !important;
		}

		.td-index {
			width: 40px;
			background: #f8f9fa;
			border-right: 1px solid #e9ecef !important;
			text-align: center !important;
		}

		.td-scale {
			width: 40%;
			padding: 0 !important;
		}

		.badge {
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
			min-width: 40px;
			margin-left: 20px;
		}

		::ng-deep .compact-table {
			border: 1px solid #ececec;
		}
		::ng-deep .compact-table .p-datatable-tbody .td-scale {
			padding: 0 6px !important;
		}
		::ng-deep .compact-table .p-datatable-tfoot .td-scale {
			padding-left: 1px !important;
		}
		::ng-deep .compact-table .p-datatable-thead > tr > th,
		::ng-deep .compact-table .p-datatable-tbody > tr > td {
			padding: 0.5rem 1rem;
			font-size: 13px;
		}
		/*::ng-deep .compact-table .p-datatable-tbody > tr:last-child > td:nth-child(3) { border-bottom: 0 !important; }*/
		::ng-deep .compact-table .p-datatable-tbody > tr:hover > td {
			background: #edf5cd;
		}
		::ng-deep .compact-table .p-datatable-tfoot > tr > td {
			background: #fff;
		}
		::ng-deep .p-card-content { padding:0 !important; }
		::ng-deep .p-accordion-tab .p-accordion-tab .p-accordion-header-link { font-size: 85% !important; }

		@media screen and (max-width: 768px) {  
			::ng-deep .p-accordion p-accordiontab .p-accordion-content {
				padding:0;
			}
			.compact-table { border: 0; }
		}
	`]
})
export class AnalyseResultsComponent implements OnInit {
	isLoading: boolean = false;
	isLoadingPrio: boolean;
	result: any[];
	averages: any[];
	analysisList: any[];

	mainBarColor: string = '#ED7D31';
	averageBarColor: string = '#c0bebb';
	@Input() showDMI: boolean = false;

	isDmiFinished: boolean = true;

	// Order: 8, 3, 5, 6
	/*
		0: {analyzeID: "6", name: "UTBILDNINGSBEHOV",…}
		1: {analyzeID: "8", name: "10 SNABBA",…}
		2: {analyzeID: "5", name: "UTVECKLINGSOMRÅDEN",…}
		3: {analyzeID: "3", name: "DIGITAL MOGNADSANALYS",…}
	 */

	devNeeds: any = { //0: {analyzeID: "6", name: "UTBILDNINGSBEHOV",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
	};

	quick: any = {    //1: {analyzeID: "8", name: "10 SNABBA",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
	};

	devAreas: any = { //2: {analyzeID: "5", name: "UTVECKLINGSOMRÅDEN",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
	};

	dmi: any = {      //3: {analyzeID: "3", name: "DIGITAL MOGNADSANALYS",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
		data: {
			labels: ['VARFÖR', 'HUR', 'VAD'],
			datasets: [
				{
					label: 'Din verksamhet',
					backgroundColor: this.mainBarColor,
					borderColor: '#fff',
					barThickness: '10',
					data: []
				},
				{
					label: 'DMI (%) per område',
					backgroundColor: this.averageBarColor,
					borderColor: '#fff',
					barThickness: '10',
					data: []
				}
			]
		},
		chartOptions: {
			responsive: true,
			tooltips: {enabled: true},
			legend: {display: true},
			scales: {
				yAxes: [{
					display: true,
					ticks: {
						display: true,
						fontSize: 12,
						lineHeight: 1.5,
						padding: 5,
						fontStyle: 'bold',
					}
				}],
				xAxes: [{
					ticks: {
						display: true,
						min: 0,
						max: 6,
						fontSize: 12,
						lineHeight: 1.5,
						padding: 5,
						fontStyle: 'bold',
						stepSize: 1.2,
						beginAtZero: true,
						callback: function (value: any) {
							return (value / 6 * 100).toFixed(0) + '%'; // convert it to percentage
						},
					},
					gridLines: {
						display: true,
						lineWidth: 2,
						drawBorder: false,
						zeroLineWidth: 3,
						tickMarkLength: 0,
						zeroLineColor: 'rgba(0, 0, 0, 0)'
					}
				}],
			},
		}
	};

	dmiOverall: any = { // DMI overall
		groups: [{}],
		answers: [{}],
		averages: [{}],
		data: {
			labels: [],
			datasets: [
				{
					label: 'Din verksamhet',
					backgroundColor: this.mainBarColor,
					borderColor: '#fff',
					barThickness: '15',
					data: []
				},
				{
					label: 'Digilo index',
					backgroundColor: this.averageBarColor,
					borderColor: '#fff',
					barThickness: '15',
					data: []
				},
			]
		},
		chartOptions: {
			responsive: true,
			tooltips: {enabled: true},
			legend: {display: false},
			scales: {
				yAxes: [{
					display: true,
					ticks: {
						display: true,
						fontSize: 12,
						lineHeight: 1.5,
						padding: 5,
						fontStyle: 'bold',
					}
				}],
				xAxes: [{
					ticks: {
						display: true,
						min: 0,
						max: 6,
						fontSize: 12,
						lineHeight: 1.5,
						padding: 5,
						fontStyle: 'bold',
						stepSize: 2,
						beginAtZero: true,
						callback: function (value: any) {
							return (value / 6 * 100).toFixed(0) + '%'; // convert it to percentage
						},
					},
					gridLines: {
						display: true,
						lineWidth: 2,
						drawBorder: false,
						zeroLineWidth: 3,
						tickMarkLength: 0,
						zeroLineColor: 'rgba(0, 0, 0, 0)'
					}
				}],
			},
		}
	};

	dmiPerGroup: any = {      //3: {analyzeID: "3", name: "DIGITAL MOGNADSANALYS",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
		data: {
			labels: [],
			datasets: [
				{
					label: 'Din verksamhet',
					backgroundColor: this.mainBarColor,
					borderColor: '#fff',
					barThickness: '10',
					data: []
				},
				{
					label: 'Digilo index',
					backgroundColor: this.averageBarColor,
					borderColor: '#fff',
					barThickness: '10',
					data: []
				}
			]
		},
		chartOptions: {
			responsive: true,
			tooltips: {enabled: true},
			legend: {
				display: true,
				position:'top',
			},
			scales: {
				yAxes: [{
					display: true,
					ticks: {
						display: true,
						fontSize: 12,
						lineHeight: 1.3,
						padding: 5,
						fontStyle: 'bold',
					}
				}],
				xAxes: [{
					ticks: {
						display: true,
						min: 0,
						max: 6,
						fontSize: 12,
						lineHeight: 1.5,
						padding: 5,
						fontStyle: 'bold',
						stepSize: 2,
						beginAtZero: true,
						callback: function (value: any) {
							return (value / 6 * 100).toFixed(0) + '%'; // convert it to percentage
						},
					},
					gridLines: {
						display: true,
						lineWidth: 2,
						drawBorder: false,
						zeroLineWidth: 3,
						tickMarkLength: 0,
						zeroLineColor: 'rgba(0, 0, 0, 0)'
					}
				}],
			},
		}
	};

	dmiPerGroupComplete: any[] = [];

	summary: any = {      //9: {analyzeID: "3", name: "SAMMANFATTNING",…}
		groups: [{}],
		answers: [{}],
		averages: [{}],
	};
	percentages: any = {
		1: '0%',
		2: '20%',
		3: '40%',
		4: '60%',
		5: '80%',
		6: '100%'
	};

	data: any;
	dataAlt: any;
	dataFooter: any;

	answersData: any;

	barOptionsAlt: any = {
		responsive: true,
		maintainAspectRatio: false,
		tooltips: {
			enabled: false
		},
		legend: {
			display: false
		},
		scales: {
			yAxes: [{
				display: false,
			}],
			xAxes: [{
				ticks: {
					display: false,
					min: 0,
					max: 6,
					stepSize: 2,
					beginAtZero: true
				},
				gridLines: {
					display: true,
					lineWidth: 2,
					drawBorder: false,
					zeroLineWidth: 3,
					tickMarkLength: 0,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)'
				}
			}],
		},
	};

	barOptionsFooter: any = {
		responsive: true,
		legend: {
			display: false
		},
		scales: {
			yAxes: [{
				display: false,
			}],
			xAxes: [{
				ticks: {
					display: true,
					min: 0,
					max: 6,
					stepSize: 2,
					beginAtZero: true
				},
				gridLines: {
					tickMarkLength: 0,
					lineWidth: 2,
					drawBorder: false,
					zeroLineWidth: 2,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)'
				}
			}],
		},
	};


	constructor(private analysisService: AnalysisService, private authenticationService: AuthenticationService, public dialogService: DialogService, private messageService: MessageService) {

		this.dataFooter = {
			//labels: [],
			labels: [],
			datasets: [
				{
					label: 'My First dataset',
					backgroundColor: '#ED7D31',
					borderColor: '#fff',
					data: []
				},
				{
					label: 'My Second dataset',
					backgroundColor: '#00B050',
					borderColor: '#fff',
					data: []
				}
			]
		};
	}

	ngOnInit(): void {
		this.isLoading = true;

		Promise.all([
			this.analysisService.fetchAnalyses(),
			this.analysisService.fetchAnalyseResults(),
			this.analysisService.fetchAnalyseAverages()
		]).then(() => {
			this.analysisService.analysis$.pipe(take(1)).subscribe((list: any[]) => {
				this.analysisList = list;

				this.devNeeds.groups = this.getByAnalyzeID(6, this.analysisList).groups;
				this.quick.groups = this.getByAnalyzeID(8, this.analysisList).groups;
				this.devAreas.groups = this.getByAnalyzeID(5, this.analysisList).groups;
				this.dmi.groups = this.getByAnalyzeID(3, this.analysisList).groups;
				this.dmiPerGroup.groups = this.getByAnalyzeID(3, this.analysisList).groups;
				this.summary.groups = this.getByAnalyzeID(9, this.analysisList).groups;
			});

			this.analysisService.analyseResult$.pipe(take(1)).subscribe((result: any[]) => {
				this.result = result;

				this.devNeeds.answers = this.getByAnalyzeID(6, this.result);
				this.quick.answers = this.getByAnalyzeID(8, this.result);
				this.devAreas.answers = this.getByAnalyzeID(5, this.result);
				this.dmi.answers = this.getByAnalyzeID(3, this.result);

				if(this.dmi.answers === undefined) {
					this.isDmiFinished = false;
				}

				if(this.isDmiFinished)
					this.dmiPerGroup.answers = this.getByAnalyzeID(3, this.result);

				this.summary.answers = this.getByAnalyzeID(9, this.result);
				if(!this.summary.answers)
					this.summary.answers = {};
			});

			this.analysisService.analyseAverage$.pipe(take(1)).subscribe((result: any[]) => {
				this.averages = result;

				this.devNeeds.averages = this.getByAnalyzeID(6, this.averages);
				this.quick.averages = this.getByAnalyzeID(8, this.averages);
				this.devAreas.averages = this.getByAnalyzeID(5, this.averages);

				if(this.isDmiFinished) {
					this.dmi.averages = this.getByAnalyzeID(3, this.averages);
					this.dmiPerGroup.averages = this.getByAnalyzeID(3, this.averages);
				}

				this.sortAnswers();
			});

			this.isLoading = false;

			//console.error('this.summary', this.summary)
			//console.error('groups', this.dmiPerGroup);
			//console.error('answers', this.dmiPerGroup.answers);
			//console.error('averages', this.dmiPerGroup.averages);*/
			////console.error('groups', this.dmiPerGroup.groups[0].questions[1]);
			////console.error('answers', this.devNeeds.answers.results[71]);
		});
	}

	async saveDevNeeds(answers:any)
	{
		this.isLoading = true;
		this.devNeeds.answers.results[59] = answers;
		//console.error('saveDevNeeds', answers, this.devNeeds.answers);
		this.analysisService.updateDevNeeds(this.devNeeds.answers).then((result) => {
			this.isLoading = false;
		});
	}

	async savePrioNeeds(answers:any)
	{
		this.isLoadingPrio = true;
		this.devNeeds.answers.results[71] = typeof answers == "string" ? [answers] : answers;
		//console.error('savePrioNeeds', answers, this.devNeeds.answers);

		this.analysisService.updateDevNeeds(this.devNeeds.answers).then((result) => {
			this.isLoadingPrio = false;
		});
	}

	async saveSummary()
	{
		//this.analysisService.collectAnswer.emit(true);

		this.isLoading = true;

		let a:any = {};

		Object.keys(this.summary.answers.results).map((key:any) => {
			a[key] = this.summary.answers.results[key];
		});

		this.summary.answers.results = a;
		this.analysisService.updateDevNeeds(this.summary.answers, true).then((result) => {
			this.isLoading = false;
			this.messageService.add({severity: 'success', summary: 'Sparad!', detail: 'Nu är din behovskartläggning och summering klar.', life: 8000});
		});
	}

	sortAnswers() {
		this.sortGradedAnswers(this.devAreas);
		this.sortGradedAnswers(this.quick);

		if(this.isDmiFinished) {
			this.sortGradedAnswersCombined(this.dmiPerGroup);

			this.dmiPerGroup.groups.forEach((g: any) => {

				let newGroup: any = {    //1: {analyzeID: "8", name: "10 SNABBA",…}
					groups: [Object.assign({}, g)],
					answers: this.dmiPerGroup.answers,
					averages: this.dmiPerGroup.averages,
				};

				this.sortGradedAnswers(newGroup);
				this.dmiPerGroupComplete.push(newGroup);
			});

			//console.error('this.dmiPerGroupComplete', this.dmiPerGroupComplete);

			this.sortDmiGroups(this.dmi);
		}

		this.sortAll();
	}

	sortAll()
	{
		//this.devAreas
		//this.quick
		//this.dmiPerGroup
		//this.dmi
		//console.error('devAreas', this.devAreas);
		//console.error('quick', this.quick);
		//console.error('dmiPerGroup', this.dmiPerGroup);
		//console.error('dmi', this.dmi);

		//let average = 0;
		//let count = 0;

		let questions = [
			...Object.values(this.devAreas.answers.results),
			...Object.values(this.quick.answers.results),
			...Object.values(this.dmiPerGroup.answers?.results || []),
			...Object.values(this.dmi.answers?.results || [])
		];
		let questionsAverages = [
			...this.devAreas.averages.questions,
			...this.quick.averages.questions,
			...this.dmiPerGroup.averages.questions || [],
			...this.dmi.averages.questions || []
		];

		let average: number = questionsAverages.map(a => a.average).reduce(function(a, b) {
			return a + b;
		});

		let grade: number = questions.map((a:any) => parseInt(a.present)).reduce(function(a, b) {
			return a + b;
		});
		let countQuestions = questions.length;

		this.dmiOverall.data.datasets[0].data.push(
			Math.round(
				( grade/countQuestions + Number.EPSILON ) * 100
			) / 100
		);
		this.dmiOverall.data.datasets[1].data.push(
			Math.round(
				( average/countQuestions + Number.EPSILON ) * 100
			) / 100
		);
		let dmi = Math.round(((this.dmiOverall.data.datasets[0].data[0] / 6) * 100) * 10) / 10
		this.dmiOverall.data.labels[0] = ['Ditt DMI:', dmi+'%'];

		//console.error('questions', grade, average, countQuestions)
	}

	sortDmiGroups(list: any) {
		// VARFÖR = groupID = 3
		// hur = groupID = 4,5,6,7,8
		// vad = groupID = 9 10 11

		let groups = {
			why: { group: 0, average: 0, countQuestions: 0},
			how: { group: 0, average: 0, countQuestions: 0},
			what: { group: 0, average: 0, countQuestions: 0}
		};
		list.groups.forEach((group: any) => {

			let groupAverage = 0;
			let totalAverage = 0;
			group.questions.forEach((q: any) => {
				q.answer = this.findAnswer(parseInt(q.questionID), list.answers.results);
				groupAverage += parseInt(q.answer.present, 10);
				let average = this.findAverage(parseInt(q.questionID), list.averages);
				totalAverage += average.average;
			});

			if(group.groupID == 3) {
				groups.why.group += groupAverage;
				groups.why.average += totalAverage;
				groups.why.countQuestions += group.questions.length;
			} else if([4,5,6,7,8].includes(parseInt(group.groupID))) {
				groups.how.group += groupAverage;
				groups.how.average += totalAverage;
				groups.how.countQuestions += group.questions.length;
			} else if([9,10,11].includes(parseInt(group.groupID))) {
				groups.what.group += groupAverage;
				groups.what.average += totalAverage;
				groups.what.countQuestions += group.questions.length;
			}
		});

		list.data.datasets[0].data.push( Math.round(( groups.why.group/groups.why.countQuestions + Number.EPSILON ) * 100 ) / 100 );
		list.data.datasets[0].data.push( Math.round(( groups.how.group/groups.how.countQuestions + Number.EPSILON ) * 100 ) / 100 );
		list.data.datasets[0].data.push( Math.round(( groups.what.group/groups.what.countQuestions + Number.EPSILON ) * 100 ) / 100 );

		list.data.datasets[1].data.push( Math.round(( groups.why.average/groups.why.countQuestions + Number.EPSILON ) * 100 ) / 100 );
		list.data.datasets[1].data.push( Math.round(( groups.how.average/groups.how.countQuestions + Number.EPSILON ) * 100 ) / 100 );
		list.data.datasets[1].data.push( Math.round(( groups.what.average/groups.what.countQuestions + Number.EPSILON ) * 100 ) / 100 );
	}

	sortGradedAnswersCombined(list: any) {
		list.groups.forEach((group: any) => {


			let name = group.name.split('&');
			if(name.length > 1)
				name[0] = name[0]+'&';
			list.data.labels.push(name);

			let groupAverage = 0;
			let totalAverage = 0;
			group.questions.forEach((q: any) => {
				q.answer = this.findAnswer(parseInt(q.questionID), list.answers?.results);
				groupAverage += parseInt(q.answer.present, 10);
				let average = this.findAverage(parseInt(q.questionID), list.averages);
				totalAverage += average.average;
			});

			list.data.datasets[0].data.push(
				Math.round(
					( groupAverage/group.questions.length + Number.EPSILON ) * 100
				) / 100
			);
			list.data.datasets[1].data.push(
				Math.round(
					( totalAverage/group.questions.length + Number.EPSILON ) * 100
				) / 100
			);
			//list.data.datasets[1].data.push(); // dynamic fill from average
		});
//		//console.error(list);
	}

	sortGradedAnswers(list: any) {
		list.groups.forEach((group: any) => {
			group.questions.forEach((q: any) => {
				q.answer = this.findAnswer(parseInt(q.questionID), list.answers.results);
				q.average = this.findAverage(parseInt(q.questionID), list.averages);

				q.chartData = {
					labels: [],
					datasets: [
						{
							label: 'Din verksamhet',
							backgroundColor: this.mainBarColor,
							borderColor: '#fff',
							barThickness: 'flex',
							data: [q.answer.present]
						},
						{
							label: 'Digilo index',
							backgroundColor: this.averageBarColor,
							borderColor: '#fff',
							barThickness: 'flex',
							data: [q.average.average]
						}
					]
				};
			});
		});
	}

	findAverage(questionID: number, list: any) {
		return list.questions.find((i:any) => i.questionID == questionID);
	}

	findAnswer(questionID: number, list: any[]) {
		let key = Object.keys(list).find(key => parseInt(key) == questionID);
		return list[key];
	}

	getByAnalyzeID(analyzeID: number, list: any[]) {
		return list.find((i) => i.analyzeID == analyzeID);
	}

}
