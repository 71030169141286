<div class="row" style="user-select: none;margin:0 -8px" #prio>
    <div class="col-md-6 mb-4 d-none d-md-block">
        <h5 class="mb-2">Utbildningar</h5>
        <p-card>
            <p class="text-primary text-sm" *ngIf="!summary">
                <i class="fa fa-info-circle"></i> Välj bland områden i denna lista och dra över till den högra listan.
            </p>
            <div cdkDropList
				 #complete="cdkDropList"
                 [cdkDropListData]="completeList"
                 [cdkDropListConnectedTo]="[selected]"
                 class="example-list"
                 cdkDropListSortingDisabled
                 (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of completeList" cdkDrag [cdkDragDisabled]="draggableDisabled || summary">
                    <div>{{item.sub}}</div>
                    <small>{{item.top}}</small>
                </div>
            </div>
        </p-card>
    </div>
    <div class="col-md-6 mb-4">
        <h5 class="mb-2">Här läggs dina prioriterade val (max {{max}}st)</h5>

        <p-card [class.listfull]="draggableDisabled">

            <div class="d-md-none mb-2">
                <p class="text-primary text-sm">
                    <i class="fa fa-hand-pointer fa-rotate-180"></i> I den här listan hittar du dina valda områden
                </p>
                <p-multiSelect [options]="completeList" [(ngModel)]="selectedList" optionLabel="sub" defaultLabel="test"
                               [style]="{'width': '100%'}" [selectionLimit]="max" [showToggleAll]="false" [filter]="false" scrollHeight="80vh" (onChange)="validate()">
                    <ng-template let-selected pTemplate="selectedItems">
                        {{selected?.length}} av {{max}} st valda
                    </ng-template>
                    <ng-template let-item let-i="index" pTemplate="item">
                        <div class="example-box-select">
                            <div>{{item.value.sub}}</div>
                            <small>{{item.value.top}}</small>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>

            <label class="d-md-none">Prioriterade:</label>

            <p class="text-primary text-sm" *ngIf="!summary">
                <i class="fas fa-sort-numeric-down"></i> Här läggs dina prioriterade val. Du kan även ändra prioriteringsordning genom att dra till önskad position.
            </p>
            <div cdkDropList
				 #selected="cdkDropList"
                 [cdkDropListData]="selectedList"
                 [cdkDropListConnectedTo]="[complete]"
                 [cdkDropListDisabled]="summary"
                 class="example-list"
                 (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of selectedList; let i = index" cdkDrag>
                    <div><span class="badge badge-pill badge-primary p-d-inline mr-1">{{i+1}}</span> {{item.sub}}</div>
                    <small>{{item.top}}</small>
                </div>
            </div>
            <span *ngIf="draggableDisabled && !summary" class="text-success mt-4 small d-none d-md-block">
                <i class="fas fa-check-circle"></i> Du har nu valt max antal kompetensområden, om du vill ändra ditt val kan du alltid ta bort ett område och lägga till ett annat.
            </span>
            <span *ngIf="selectedList.length >= max && !summary" class="text-success mt-3 small d-block d-md-none">
                <i class="fas fa-check-circle"></i> Du har nu valt max antal kompetensområden, om du vill ändra ditt val kan du alltid ta bort ett område och lägga till ett annat.
            </span>
        </p-card>
    </div>
</div>

