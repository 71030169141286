import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-forgot-password',
	templateUrl: './forgot-password.dialog.html',
	styles: []
})
export class ForgotPasswordDialog implements OnInit {
	isLoading = false;
	constructor(private http: HttpClient, private msgService: MessageService, public ref: DynamicDialogRef) {
	}

	ngOnInit(): void {
	}

	requestPassword(form: NgForm) {
		if(form.valid) {
			this.isLoading = true;
			let formData = new FormData();
			formData.append('action', 'p46_ca_public_new_password');
			formData.append('p46_ca_password_socialsecurity', form.value['p46_ca_password_socialsecurity']);
			formData.append('p46_ca_password_email', form.value['p46_ca_password_email']);

			this.http
				.post(environment.serverUrl, formData)
				.toPromise()
				.then((res) => res)
				.then((data) => {
					//return data;
					if (data['error'] == 0) {
						this.msgService.add({
							severity: 'success',
							summary: "Nytt lösenord skickat.",
							detail: '',
							sticky: false
						});
						this.ref.close();
					} else {
						this.msgService.add({
							severity: 'error',
							summary: "Något blev fel..",
							detail: data['error'],
							sticky: false
						});
					}
					this.isLoading = false;
				});
		}
	}
}
