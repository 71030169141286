import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthInterceptor } from '@core/http/auth.interceptor';
import { JwtInterceptor } from '@core/http/jwt.interceptor';
import { TranslateModule } from '@ngx-translate/core';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';

@NgModule({
	imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useFactory: function (router: Router) {
				return new AuthInterceptor(router);
			},
			multi: true,
			deps: [Router],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiPrefixInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
		},
		/* @bug: Redirectar inte till hem korrekt när nedan används, behövs det?  */
		/*{
			provide: RouteReuseStrategy,
			useClass: RouteReusableStrategy,
		},*/
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		// Import guard
		if (parentModule) {
			throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
		}
	}
}
