import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationGuard, NewAuthGuard } from '@app/@core/auth';
import { extract } from '@app/i18n';
import { FinishedSurveyComponent } from '@app/pages/analyzes/finished-survey/finished-survey.component';
import { OpenAnalyzeComponent } from '@app/pages/analyzes/open-analyze/open-analyze.component';
import { CommunicationKitComponent } from '@app/pages/communication-kit/communication-kit.component';
//import { CompanyWizardComponent } from '@app/pages/company/company-wizard/company-wizard.component';
import { ContactComponent } from '@app/pages/contact/contact.component';
import { EsfComponent } from '@app/pages/esf/esf.component';
import { FaqComponent } from '@app/pages/faq/faq.component';
import { GdprComponent } from '@app/pages/gdpr/gdpr.component';
import { ProjectSelectorComponent } from '@app/@shared/components/project-selector/project-selector.component';
import { TipsComponent } from '@app/pages/tips/tips.component';
import { InviteComponent } from '@app/pages/user/invite/invite.component';
import { UserStepwizardComponent } from '@app/pages/user/user-stepwizard/user-stepwizard.component';
import { ShellService } from '@app/shell/shell.service';

const routes: Routes = [
	{path: '', redirectTo: 'hem', pathMatch: 'full'},
	ShellService.childRoutes([{path: 'hem', loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule)}]),
	ShellService.childRoutes([{path: 'faq', component: FaqComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'kommunikations-kit', component: CommunicationKitComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'kontakt', component: ContactComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'esf', component: EsfComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'gdpr', component: GdprComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'tips', component: TipsComponent, canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'profil', loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'företagsprofil', data: {role: 'leader'}, loadChildren: () => import('./pages/company/company.module').then((m) => m.CompanyModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'medarbetare', data: {role: 'leader'}, loadChildren: () => import('./pages/employees/employees.module').then((m) => m.EmployeesModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'analyser', loadChildren: () => import('./pages/analyzes/analyzes.module').then((m) => m.AnalyzesModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'enkat/:analyzeID/:courseID', component: OpenAnalyzeComponent}]),
	ShellService.childRoutes([{path: 'enkat/klar/:analyzeID/:courseID', component: FinishedSurveyComponent}]),
	ShellService.childRoutes([{path: 'aktiviteter', loadChildren: () => import('./pages/courses/courses.module').then((m) => m.CoursesModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'bokade', loadChildren: () => import('./pages/submissions/submissions.module').then((m) => m.SubmissionsModule), canActivate: [NewAuthGuard]}]),
	ShellService.childRoutes([{path: 'showcase', loadChildren: () => import('./pages/showcase/showcase.module').then((m) => m.ShowcaseModule)}]),

	ShellService.childRoutes([{path: 'kursledare', loadChildren: () => import('./pages/@course-leader/course-leader.module').then((m) => m.CourseLeaderModule)}]),
	ShellService.childRoutes([{path: 'boka', loadChildren: () => import('./pages/booking/booking.module').then((m) => m.BookingModule), canActivate: [NewAuthGuard]}]),

	//{path: 'företagswizard', component: CompanyWizardComponent, data: {title: 'Inställningsguide', role: 'leader'}, canActivate: [NewAuthGuard]},
	//{path: 'företagswizard/:sub', component: CompanyWizardComponent, data: {title: 'Inställningsguide', role: 'leader'}, canActivate: [NewAuthGuard]},
	{path: 'användarwizard', component: UserStepwizardComponent, data: {title: 'Inställningsguide'}, canActivate: [NewAuthGuard]},
	{path: 'projektväljare', component: ProjectSelectorComponent, data: {title: 'Projekt'}, canActivate: [NewAuthGuard]},
	{path: 'inbjudan/:token', component: InviteComponent, data: {title: 'Inbjudan'}},

	ShellService.childRoutes([{path: 'superadmin', loadChildren: () => import('./pages/superadmin/superadmin.module').then((m) => m.SuperadminModule), canActivate: [NewAuthGuard]}]),

	// Fallback when no prior route is matched
	{path: '**', redirectTo: 'hem', pathMatch: 'full'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {
}
