import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionNeededButton } from '@shared/components/action-needed/action-needed.interface';

@Component({
	selector: 'dd-action-needed-button',
	templateUrl: './action-needed-button.component.html',
	styles: [`
		.p-button { font-size: 0.875rem; }
		.p-button-link { font-size: 0.775rem; }
	`]
})
export class ActionNeededButtonComponent implements OnInit {

	@Input() btn: ActionNeededButton;
	@Output() onClick = new EventEmitter();

	constructor() {
	}

	ngOnInit(): void {
		//console.error('btn', this.btn);
	}

	emitOnClick(event: any) {
		this.onClick.emit(event)
	}
}
