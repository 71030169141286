import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from '@shared/animations/animations';
import { BaseWizComponent } from '@shared/components/base-wiz/base-wiz.component';
import { InvitesInterface } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'dd-invite-step2',
	templateUrl: './invite-step2.component.html',
	styles: [],
	animations: [FadeIn],
})
export class InviteStep2Component implements OnInit, OnDestroy {
	inModal: boolean = false;
	isLoading: any;
	@Input() user: InvitesInterface;
	@Output() stepValidated:EventEmitter<void> = new EventEmitter();

	constructor(public companiesService: CompaniesService, public config: DynamicDialogConfig, public dialogService: DialogService, public ref: DynamicDialogRef, public route: ActivatedRoute, public authService: AuthenticationService) {

	}

	ngOnInit(): void {

	}

	submitStep(form: NgForm) {
		this.stepValidated.next();
	}

	ngOnDestroy() {

	}
}
