import { Component, Input } from '@angular/core';
import { CourseEventInterface, CourseInterface } from '@shared/interfaces/course';
import { CoursesService } from '@shared/services/courses.service';

@Component({
	selector: 'dd-course-card-overlay',
	templateUrl: './course-card-overlay.component.html',
	styles: [`
		:host {
			width:100%;
		}
		.bg-image {
			background-position-x: center;
			background-position-y: top;
			background-repeat-x: no-repeat;
			background-repeat-y: no-repeat;
			background-size: cover;
			margin:0 10px;
			overflow:hidden;
		}
		.content {
			width: 100%;
			display: flex;
			min-height: 317px;
			-webkit-backdrop-filter: brightness(0.7);
			backdrop-filter: contrast(0.4);
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding:25px
		}

		.course-name {
			min-height:58px;
		}
		.course-card-footer-dates { min-height: 46px;display: flex;    align-items: flex-end; }
	`]
})
export class CourseCardOverlayComponent {
	futureEvents: CourseEventInterface[] = [];

	get course(): CourseInterface {
		return this._course;
	}

	@Input() set course(value: CourseInterface) {
		this._course = value;

		this.futureEvents = this.coursesService.getFutureEvents(this._course.Events)
	}

	private _course: CourseInterface;

	constructor(private coursesService: CoursesService) {

	}
}
