import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmployee'
})
export class IsEmployeePipe implements PipeTransform {

	transform(value: string) {
		return value == 'employee';
	}
}
