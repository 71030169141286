import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordDialog } from '@app/pages/login/forgot-password/forgot-password.dialog';
import { BankidAuthInit, BankIDCollectStatus } from '@core/auth/bankid.interface';
import { untilDestroyed } from '@core/until-destroyed';

import { environment } from '@env/environment';
import { FadeIn } from '@shared/animations/animations';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

import { AuthenticationService, AuthUser } from '../../@core/auth/authentication.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [FadeIn]
})
export class LoginComponent implements OnInit, OnDestroy {
	version: string | null = environment.version;
	error: string | undefined;
	loginForm!: FormGroup;
	loginFormLeader: FormGroup;
	isLoading = false;
	showLeaderForm: boolean = false;
	isLoadingBankid: boolean;
	useQRCodeBankid: boolean;
	qrCodeUrl: string|boolean = './assets/images/Spinner-1s-200px.svg';//false;//'https://banksign-test.azurewebsites.net/qr/408e8c0b-ddda-4857-9502-9a8be5653855';//
	bankIDCanceled: boolean = false;
	bankIDStatusPending: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private authenticationService: AuthenticationService,
		public dialogService: DialogService,
		private activatedRoute: ActivatedRoute
	) {
		this.createForm();
	}

	ngOnInit() {
		console.info('::: AKSE version ' + this.version);

		/**
		 * Någon autologin feature?! oklart
		 */
		this.activatedRoute.queryParams.subscribe(async (params: Observable<AuthUser>) => {
			if(!params['token']) {
				return;
			}

			this.isLoading = true;
			console.error('token', params['token'])
			/*let auth: AuthUser {
				return {
					token: params['token']
				}
			};
			auth.token = params['token'];
			this.authenticationService.auth = <AuthUser> {
				token: params['token']
			};
			const authU: AuthUser = ({} as any) as AuthUser;
			authU.token = params['token'];*/

			//this.authenticationService.auth = <Observable<AuthUser>>;
			this.authenticationService.auth = params;
			this.authenticationService.currentUserSubject.next(<AuthUser><unknown>params);

			console.error(this.authenticationService.auth);
			await this.authenticationService.validateAuth().then((data:any) => {
				console.error('auto login true!', data);
				this.isLoading = false;
				if(this.authenticationService.projectSettings['startPage'] != '/hem' && data) {
					this.router.navigate([this.authenticationService.projectSettings['startPage']]);
					return;
				}
				//if (data['access'] == true) {

				//}
			});
		});
	}

	ngOnDestroy() {
	}

	delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms))
	}

	callBankidQR() {
		const login$ = this.authenticationService
			.authBankID(true)
			.then((result:BankidAuthInit) => {
				if(result.authResponse.Success) {
					this.authenticationService.bankIDAuthInit = result;

					//if(result.qrImage)
						//this.qrCodeUrl = result.qrImage;

					console.error(result);

					const getQrCode = async () => {

						if(!this.isLoadingBankid || this.bankIDCanceled) {
							return;
						}

						console.error('getQrCode..');

						await this.authenticationService.collectBankIDQRCode().then(async (data: BankIDCollectStatus) => {
							if(data.apiCallResponse.Success && data.apiCallResponse.qrImage) {
								this.qrCodeUrl = data.apiCallResponse.qrImage;
							}

							setTimeout(getQrCode, 4000);
						});
					};
					//const timerGetQrImage = setInterval(getQrCode, 5000);
					const timerAuthStatus = async () => {

						if(!this.isLoadingBankid && !this.bankIDStatusPending) {
							return;
						}

						await this.authenticationService.poolBankIDStatus().then((data: BankIDCollectStatus) => {

							const donePoolStatus = () => {
								console.error('donePoolStatus')
								//clearInterval(timerAuthStatus);
								this.isLoadingBankid = false;
								this.bankIDStatusPending = false;
								this.qrCodeUrl = './assets/images/Spinner-1s-200px.svg';
								return;
							};

							if (!data?.apiCallResponse || data?.apiCallResponse?.StatusMessage === null) {
								donePoolStatus();
								return;
							}

							console.error(data.apiCallResponse.StatusMessage);

							if (data.authResponse.Success) {
								let StatusMessage = data.apiCallResponse.StatusMessage;
								switch (StatusMessage) {
									case 'complete':
										this.completedBankIDStatus(data);
										donePoolStatus();
										break;
									case 'failed':
										donePoolStatus();
										this.bankIDCanceled = true;
										break;
									case 'pending': this.bankIDStatusPending = true; break;
									default: donePoolStatus(); break;
								}
							}
						})
						.finally(() => untilDestroyed(this));

						setTimeout(timerAuthStatus, 3000);
					}

					getQrCode().then(() => {
						///auth status?
						console.error('image loaded, pool auth status');
						timerAuthStatus().then();
					});

				} else {
					this.isLoadingBankid = false;
				}
			})
			.finally(() => {
				console.error('finally Auth');
				untilDestroyed(this);
			});
	}

	callBankidDesktop() {
		const login$ = this.authenticationService
			.authBankID()
			.then((result:BankidAuthInit) => {
				if(result.authResponse.Success) {
					this.authenticationService.bankIDAuthInit = result;

					window.location.href = `bankid:///?autostarttoken=${result.apiCallResponse.Response.AutoStartToken}&redirect=`
					console.error(result, `bankid:///?autostarttoken=${result.apiCallResponse.Response.AutoStartToken}&redirect=`);

					const timerAuthStatus = async () => {

						if(!this.isLoadingBankid)
							return;

						await this.authenticationService.poolBankIDStatus().then(async (data: BankIDCollectStatus) => {

							const done = () => {
								this.isLoadingBankid = false;
							};

							if (!data?.apiCallResponse || data?.apiCallResponse?.StatusMessage === null) {
								done();
								return;
							}

							console.error(data.apiCallResponse.StatusMessage);

							if (data.authResponse.Success) {
								let StatusMessage = data.apiCallResponse.StatusMessage;

								switch (StatusMessage) {
									case 'complete':
										await this.completedBankIDStatus(data);
										done();
										break;
									case 'failed': done(); break;
									case 'pending': break;
									default: done(); break;
								}
							}

							setTimeout(timerAuthStatus, 2000);
						})
						.finally(() => {
							untilDestroyed(this);
						});
					};

					timerAuthStatus().then();

				} else {
					this.isLoadingBankid = false;
				}
			})
			.finally(() => {
				console.error('finally Auth');
				untilDestroyed(this);
			});
	}

	private async completedBankIDStatus(data: BankIDCollectStatus) {

		console.error('completedBankIDStatus', data);
		this.authenticationService.bankIDAuthCompletion = data.apiCallResponse.Response;

		let result = data.ngAuth;
		if (result.error == 0) {


			this.authenticationService.loginFromBankID(result);

			let finalizedUserWizard = localStorage.getItem('finalizedUserWizard');

			if (finalizedUserWizard == null && result.finishedProfile == 0) {
				await this.router.navigate(['/användarwizard']);
				return;
			}

			const returnUrl: string = this.route.snapshot.queryParamMap.get('returnUrl');

			let browseProject = localStorage.getItem('project.browse');

			if (browseProject == null) {
				let availableProjects = this.authenticationService.getAvailableProjects();
				if (availableProjects.length > 1) {
					await this.router.navigate(['/projektväljare', {'returnUrl' : returnUrl}]);
					return;
				} else {
					localStorage.setItem('project.browse', availableProjects[0]);
				}
			}

			//if (returnUrl == null || this.authenticationService.projectSettings['startPage'] != '/hem') {
			console.error('returnUrl', returnUrl);
			//this.authenticationService.actionNeededTrigger();

			if (returnUrl == null) {
				await this.router.navigate(['/hem']);
				return;
			} else {
				console.error('route2', returnUrl)
				await this.router.navigate([returnUrl]);
				return;
			}
		} else {
			alert(result.error);
		}
	}

	loginBankid(useQR: boolean = false) {
		this.isLoadingBankid = true;
		this.bankIDCanceled = false;
		this.useQRCodeBankid = useQR;

		if(useQR) {
			this.callBankidQR();
			return;
		}
		this.callBankidDesktop();

		/**
		   let ua = navigator.userAgent.toLowerCase();
		   let isAndroid = ua.indexOf("android") > -1; // android check
		   let isIphone = ua.indexOf("iphone") > -1; // ios check
		   if (isIphone == true) {
		    let app = {
		      launchApp: function() {
		       setTimeout(function() {
		         window.location.href = "https://itunes.apple.com/us/app/appname/appid";
		       }, 25);
		       window.location.href = "bundlename://linkname"; //which page to open(now from mobile, check its authorization)
		      },
		      openWebApp: function() {
		       window.location.href = "https://itunes.apple.com/us/app/appname/appid";
		      }
		  };
		  app.launchApp();
		 } else if (isAndroid== true) {
		    let app = {
		      launchApp: function() {
		        window.location.replace("bundlename://linkname"); //which page to open(now from mobile, check its authorization)
		        setTimeout(this.openWebApp, 500);
		      },
		      openWebApp: function() {
		        window.location.href =  "https://play.google.com/store/apps/details?id=packagename";
		      }
		  };
		  app.launchApp();
		 }else{
		  //navigate to website url
		 }
	    */
	}

	login() {
		this.isLoading = true;
		const login$ = this.authenticationService
			.login(this.loginForm.value)
			.then(async (data) => {
				let result = data;
				console.error(data)
				if (result && !data.error) {
					this.authenticationService.loginFromBankID(result);

					let finalizedUserWizard = localStorage.getItem('finalizedUserWizard');
					if (finalizedUserWizard == null && result.finishedProfile == 0) {
						await this.router.navigate(['/användarwizard']);
						return;
					}

					const returnUrl: string = this.route.snapshot.queryParamMap.get('returnUrl');

					let browseProject = localStorage.getItem('project.browse');
					if (browseProject == null) {
						let availableProjects = this.authenticationService.getAvailableProjects();
						if(availableProjects.length > 1) {
							await this.router.navigate(['/projektväljare', {'returnUrl' : returnUrl}]);
							return;
						} else {
							localStorage.setItem('project.browse', availableProjects[0]);
						}
					}

					//if (returnUrl == null || this.authenticationService.projectSettings['startPage'] != '/hem') {
					if (returnUrl == null) {
						await this.router.navigate(['/hem']);
					} else {
						await this.router.navigate([returnUrl]);
					}
				} else {
					if(data.error)
						alert(data.error);
				}
			})
			.finally(() => {
				this.loginForm.markAsPristine();
				this.isLoading = false;
				//untilDestroyed(this);
			});
	}

	loginLeader() {
		this.isLoading = true;
		const login$ = this.authenticationService
			.loginLeader(this.loginFormLeader.value)
			.then((result) => {
				if (result.error == 0) {
					const returnUrl: string = this.route.snapshot.queryParamMap.get('returnUrl');

					if (returnUrl == null) {
						this.router.navigate(['/kursledare/hem']);
					} else {
						this.router.navigate([returnUrl]);
					}
				} else {
					alert(result.error);
				}
			})
			.finally(() => {
				this.loginFormLeader.markAsPristine();
				this.isLoading = false;
				untilDestroyed(this);
			});
	}

	showForgotDialog() {
		const ref = this.dialogService.open(ForgotPasswordDialog, {
			header: 'Glömt ditt lösenord?',
			width: '400px'
		});
	}

	private createForm() {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			remember: true,
		});
		this.loginFormLeader = this.formBuilder.group({
			usernameEmail: ['', Validators.email],
			password: ['', Validators.required],
			remember: true,
		});
	}

}
