import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FadeIn } from '@shared/animations/animations';
import { AnalysisService } from '@shared/services/analysis.service';

@Component({
	selector: 'dd-open-analyze',
	templateUrl: './open-analyze.component.html',
	styles: [`
		::ng-deep .p-card-content { padding:0 }
		::ng-deep .p-divider .p-divider-content {
			background: #f7f5f3
		}
	`],
	animations: [FadeIn]
})
export class OpenAnalyzeComponent implements OnInit {

	isLoading: boolean = false;
	analyzeID: number = 0;
	analyze: any;
	isSurvey: boolean = false;
	isCharts: boolean = false;
	courseID: number = 0;

	constructor(private analysisService: AnalysisService, private route: ActivatedRoute, private router: Router) {
	}

	ngOnInit(): void {

		this.isLoading = true;
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0)
		});

		this.route.paramMap.subscribe(params => {
			this.analyzeID = +params.get('analyzeID');
			this.isSurvey = false;


			if(params.get('courseID')) {
				this.courseID = +params.get('courseID');
				this.isSurvey = true;
			}


			this.analysisService.fetchAnalyses().then(() => {
				this.analysisService.analysis$.subscribe((analyses:any) => {
					if(analyses) {
						this.analyze = analyses.find((x: any) => x.analyzeID == this.analyzeID);
						this.isCharts = this.analyze.resultType == 'charts';
					}

					this.isLoading = false;
					//console.error(this.analyze);
				})
			});

		});
	}

}
