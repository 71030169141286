<p-toast position="bottom-right"></p-toast>

<ng-container *ngIf="!invalidToken">
    <div
            *ngIf="!started"
            @FadeIn
            class="position-fixed p-4 justify-content-center min-vw-100 min-vh-100 d-flex align-items-center start-screen"
    >
        <div class="row text-center">
            <div class="col-xs-5">
                <h1 class="text-center mb-4">Välkommen till <br/>Affärskompetens Sverige</h1>
                <p class="text-center mb-3">
                    Vi kommer nu ta dig igenom ett par steg för att säkerställa att vi har rätt information om dig som användare.
                </p>
                <div class="mt-5">
                    <button
                            (click)="start()"
                            class="px-5 p-button-lg btn-fw-700 mb-5 w-100"
                            label="Nu kör vi!"
                            pButton
                            type="button"
                            style="max-width: 350px"
                    ></button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="started && !finished" @FadeIn class="container-fluid justify-content-center pt-4 pt-sm-0">
        <div class="row justify-content-center mb-5 d-none d-sm-flex">
            <div class="col-xs-12 col-sm-10 col-xl-6 justy-content-center pt-5">
                <p-steps [activeIndex]="activeIndex" [model]="items" [readonly]="false" *ngIf="items.length > 0" (activeIndexChange)="activeIndex=$event"></p-steps>
            </div>
        </div>

        <ng-container [ngSwitch]="activeIndex" *ngIf="invite">
            <dd-invite-step1 *ngSwitchCase="0" [user]="invite" (stepValidated)="nextStep(1)"></dd-invite-step1>
            <dd-invite-step2 *ngSwitchCase="1" [user]="invite" (stepValidated)="nextStep(2)"></dd-invite-step2>
            <dd-invite-step3 *ngSwitchCase="2" [user]="invite" (stepValidated)="nextStep(3)" (userFailed)="activeIndex=0;"></dd-invite-step3>

        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="invalidToken">
    <div class="position-fixed p-4 justify-content-center min-vw-100 min-vh-100 d-flex align-items-center start-screen flex-column">
        <h1 class="text-center mb-4">Fel!</h1>
        <p class="text-center mb-3">
            Vi kunde tyvärr inte verifiera din inbjudan.
        </p>
    </div>
</ng-container>
