<div class="d-flex align-items-center justify-content-center" *ngIf="show">
    <div class="flex align-items-center border-round-md p-3 bg-white mb-2 shadow-sm w-auto border-left-3 border-primary text-muted">
        <div class="mr-3 pl-2">
            <i class="{{icon}}" style="font-size: 2rem"></i>
        </div>
        <div class="py-2">
            <div class="display-5" *ngIf="title != ''">{{title}}</div>
            <div class="font-weight-bold mt-2">{{message}}</div>
        </div>
    </div>

</div>
