<div class="container pb-4">
    <div class="card-box border-round-big px-5 py-4 shadow bg-white">
        <h2>Projektet Perfekt – Korta och konkreta kurser.</h2>
        <h3>Att ing&aring; i ett projekt som finansieras med medel fr&aring;n Europeiska Socialfonden</h3>
        <div class="row mt-3">
            <div class="col-6">
                <p>I projektet Perfekt &ndash; Korta och konkreta kurser har f&ouml;retagsledare och medarbetare m&ouml;jlighet att p&aring; olika s&auml;tt kompetensutveckla sig. Med avstamp i vilka behov f&ouml;retaget och individen har s&aring; finns det ett stort urval av kurser inom omr&aring;den vi kallar f&ouml;retagar&auml;mnen. Exempel &auml;r ledarskap, personlig utveckling, f&ouml;rs&auml;ljning och marknadsf&ouml;ring. Vi lever i en f&ouml;r&auml;nderlig tid d&auml;r fortbildning verkligen beh&ouml;vs, men det &auml;r b&aring;de sv&aring;rt att hinna med och &auml;ven kostnaden kan vara ett hinder. Vi &auml;r d&auml;rf&ouml;r glada &ouml;ver att kunna erbjuda dig kurser inom en rad olika &auml;mnen som hj&auml;lper f&ouml;retaget att forts&auml;tta vara konkurrenskraftigt. F&ouml;r att s&aring; m&aring;nga som m&ouml;jligt ska kunna delta, n&auml;r det passar dig sj&auml;lv b&auml;st, har vi &auml;ven inspelade kurser och e-learning som ett komplement till de digitala livekurserna.</p>
            </div>
            <div class="col-6">
                <ul>
                    <li>Projektet har plats f&ouml;r 700 f&ouml;retag och 1750 deltagare som alla har m&ouml;jlighet att delta i kompetensh&ouml;jande insatser.</li>
                    <li>P&aring;g&aring;r mellan 23-02-01-26-03-31 (aktiviteter tom. december -25)&nbsp;</li>
                    <li>Alla insatser sker digitalt.&nbsp;</li>
                    <li>Projektet &auml;r kostnadsfritt och det &auml;r m&ouml;jligt eftersom det finansieras av ESF (Europeiska socialfonden) och i samarbete med F&ouml;retagarna.&nbsp;</li>
                </ul>
            </div>
        </div>
    </div>
</div>
