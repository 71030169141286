<app-loader [isLoading]="isLoading||isLoadingPrio"></app-loader>

<div *ngIf="result" class="container-fluid p-0 px-sm-3">

    <div class="row mb-4 mt-2" *ngIf="isDmiFinished && showDMI">
        <div class="col-12 col-md-5">
            <p-card styleClass="mb-3">
                <h5 class="mb-2">Digital Mognads Index (DMI)</h5>
                <p-chart type="horizontalBar" [options]="dmiOverall.chartOptions" [data]="dmiOverall.data" height="110"></p-chart>
            </p-card>
            <p-card styleClass="mb-3">
                <h5 class="mb-2 mt-2">DMI (%) VARFÖR HUR VAD</h5>
                <p-chart type="horizontalBar" [options]="dmi.chartOptions" [data]="dmi.data" height="200"></p-chart>
            </p-card>
        </div>
        <div class="col-12 col-md-7">
            <p-card>
                <h5 class="mb-2">DMI Områden</h5>
                <p-chart type="horizontalBar" [options]="dmiPerGroup.chartOptions" [data]="dmiPerGroup.data" height="400"></p-chart>
            </p-card>
        </div>
    </div>

    <p-accordion [multiple]="true">

        <p-accordionTab header="Digital Mognad" *ngIf="isDmiFinished && showDMI" [selected]="showDMI">
            <div class="mt-3 text-center">
                <img src="https://portal.affarskompetens.se/wp-content/uploads/2021/02/DMI_-_faser_-_varfor_hur_vad-min.png" alt="varför hur vad" style="width:100%;max-width: 900px" />
            </div>

            <div class="mt-4 px-2 px-md-0">
                <div class="row">
                    <div class="col-12 px-sm-5">
                        <div style="font-size: 120%;">
                            <p><strong>Digital&nbsp;</strong><strong>Mognadsanalys</strong>, &auml;r skapad av <a href="https://digjourney.com/digitalt-mognadstest">DigJourney</a> i samarbete med Aff&auml;rskompetens Sverige och &auml;r baserad p&aring; den popul&auml;ra metodiken f&ouml;r digital transformation, <a href="https://digitaltransformation.net/sv/digital-maturity-matrix/">Digital&nbsp;Maturity&nbsp;Matrix</a>, och boken <a href="https://digitaltransformation.net/sv/boken/">Att leda digital transformation</a>. &nbsp;</p>
                            <p><strong>Digitalt Mognadsindex (</strong><strong>DMI</strong><strong>)</strong> &ndash; Digitalt mognadsindex (DMI) &auml;r ett m&aring;tt p&aring; en verksamhets digitala mognad och beskriver hur digitaliserad verksamheten &auml;r visavi sin omv&auml;rld.&nbsp;DMI m&auml;ts mellan 0-100% och &auml;r allts&aring; relativ sin omgivning vilket beror p&aring; att den digitala utvecklingen hela tiden g&aring;r fram&aring;t.</p>
                        </div>
                        <ul>
                            <li>
                                <p><strong style="font-size: 120%;">Mobiliseringsfas</strong> &ndash; Den f&ouml;rsta fasen i Den Digitala Mognadsmatrisen (Digital&nbsp;Maturity&nbsp;Matrix) och som syftar p&aring; bolag som har l&aring;g digital mognad gentemot sin omv&auml;rld. Att kunna besvara varf&ouml;r den &auml;r viktig och ta fram en vision om vart f&ouml;retaget ska ta sig med hj&auml;lp av satsningen &auml;r viktiga delar i denna fas. Ofta &auml;r det n&ouml;dv&auml;ndigt att g&aring; till botten med varf&ouml;r f&ouml;retaget &ouml;verhuvudtaget existerar. Genom svaren p&aring; varf&ouml;r och vart skapas engagemang och kraft fram&aring;t. I denna fas &auml;r satsningen ofta begr&auml;nsad med n&aring;gon eller n&aring;gra personer som driver den.&nbsp;</p>
                            </li>
                            <li><strong style="font-size: 120%;">Koordinationsfas</strong> &ndash; I denna den andra fasen skapas en djupare f&ouml;rst&aring;else f&ouml;r vad f&ouml;retaget g&ouml;r digitalt och vad som fungerar. H&auml;r l&auml;ggs extra stort fokus p&aring; hur f&ouml;retaget ska jobba med digitalisering och dessa fr&aring;gor g&aring;r framf&ouml;r allt in&aring;t i f&ouml;retaget. En djupare strategi f&ouml;r den digitala satsningen tas ofta fram och i denna fas allokeras mer resurser. Ofta byggs en specifik avdelning upp f&ouml;r satsningen. Detta &auml;r den sv&aring;raste mognadsfasen och den st&auml;ller h&ouml;ga krav p&aring; gott ledarskap. Anledningen &auml;r att den digitala satsningen har v&auml;xt och blir allt viktigare samtidigt som den befintliga aff&auml;ren minskar. F&ouml;ljden blir att verksamheterna tydligare beh&ouml;ver koordineras, och det &auml;r vanligt med konflikter mellan den traditionella och den digitala verksamheten. Mycket kraft g&aring;r d&auml;rmed in&aring;t i organisationen f&ouml;r att koordinera den digitala verksamheten med den befintliga och maximal kraft fram&aring;t uppn&aring;s inte. <br/><br/></li>
                            <li><strong style="font-size: 120%;">Accelerationsfas</strong> &ndash; I denna fas skiljs inte p&aring; vad som &auml;r strategin f&ouml;r den digitala satsningen och vad som &auml;r strategin f&ouml;r den befintliga verksamheten. Det finns s&aring;ledes en vision och en strategi som genomsyrar hela verksamheten, digitalt eller ej. Bolaget har en verksamhet som &auml;r en blandning av den digitala satsningen och den tidigare befintliga verksamheten. P&aring; s&aring; s&auml;tt f&aring;r satsningen ett starkare f&auml;ste i verksamheten och all kraft riktas fram&aring;t (snarare &auml;n in&aring;t i organisation som i Koordinationsfasen), vilket medf&ouml;r att s&aring;v&auml;l f&ouml;r&auml;ndring som resultat accelererar. F&ouml;retaget klarar nu ocks&aring; av att se de f&ouml;r&auml;ndringar som sker i omv&auml;rlden och l&ouml;pande anpassa sig efter dem. Det &ouml;kar m&ouml;jligheterna till att utveckla ett l&aring;ngsiktigt framg&aring;ngsrikt f&ouml;retag.</li>
                        </ul>
                        <div class="text-center">
                            <img src="https://portal.affarskompetens.se/wp-content/uploads/2021/02/Forklaring_Dig_Mog_-_faser.jpg" alt="digjourney logo" width="300"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mt-4">
                <h5 class="mb-3">Samtliga frågor i Digital Mognad:</h5>
                <div class="row justify-content-center align-items-center">
                    <div *ngFor="let group of dmiPerGroup.groups" class="col-12 mb-5">
                        <div class="shadow-sm">
                            <p-table [autoLayout]="true" [value]="group.questions" tableStyleClass="compact-table">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="th-heading" colspan="2">{{group.name}}</th>
                                        <th class="th-heading font-weight-normal">
                                            <span class="badge badge-orange">&nbsp;</span> <span class="text-sm">Din verksamhet</span>
                                            <span class="badge badge-grey">&nbsp;</span> <span class="text-sm">Digilo index</span>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template let-question let-rowIndex="rowIndex" pTemplate="body">
                                    <tr>
                                        <td class="td-index">{{rowIndex + 1}}</td>
                                        <td>{{question.question}}</td>
                                        <td class="td-scale">
                                            <p-chart type="horizontalBar" [options]="barOptionsAlt" [data]="question.chartData" height="30px"></p-chart>
                                            <div class="d-block d-md-none">
                                                <p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr class="d-none d-md-table-row">
                                        <td></td>
                                        <td></td>
                                        <td class="td-scale">
                                            <!--<p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>-->
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>

            </div>
        </p-accordionTab>

        <p-accordionTab *ngIf="!showDMI" header="Nuläge - 10 snabba">
            <div class="row justify-content-center align-items-center">
                <div *ngFor="let group of quick.groups" class="col-12 mb-5">
                    <div class="shadow-sm">
                        <p-table [autoLayout]="true" [value]="group.questions" tableStyleClass="compact-table">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="th-heading" colspan="2">{{group.name}}</th>
                                    <th class="th-heading font-weight-normal">
                                        <span class="badge badge-orange">&nbsp;</span> <span class="text-sm">Din verksamhet</span>
                                        <span class="badge badge-grey">&nbsp;</span> <span class="text-sm">Digilo index</span>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-question let-rowIndex="rowIndex" pTemplate="body">
                                <tr>
                                    <td class="td-index">{{rowIndex + 1}}</td>
                                    <td>{{question.question}}</td>
                                    <td class="td-scale">
                                        <p-chart type="horizontalBar" [options]="barOptionsAlt" [data]="question.chartData" height="30px"></p-chart>
                                        <div class="d-block d-md-none">
                                            <p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr class="d-none d-md-table-row">
                                    <td></td>
                                    <td></td>
                                    <td class="td-scale">
                                        <p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </p-accordionTab>

        <p-accordionTab *ngIf="!showDMI" header="Utvecklingsområden">
            <div class="row justify-content-center align-items-center">
                <div *ngFor="let group of devAreas.groups" class="col-12 mb-5">
                    <div class="shadow-sm">
                        <p-table [autoLayout]="true" [value]="group.questions" tableStyleClass="compact-table">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="th-heading" colspan="2">{{group.name}}</th>
                                    <th class="th-heading font-weight-normal">
                                        <span class="badge badge-orange">&nbsp;</span> <span class="text-sm">Din verksamhet</span>
                                        <span class="badge badge-grey">&nbsp;</span> <span class="text-sm">Digilo index</span>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-question let-rowIndex="rowIndex" pTemplate="body">
                                <tr>
                                    <td class="td-index">{{rowIndex + 1}}</td>
                                    <td>{{question.question}}</td>
                                    <td class="td-scale">
                                        <p-chart type="horizontalBar" [options]="barOptionsAlt" [data]="question.chartData" height="30px"></p-chart>
                                        <div class="d-block d-md-none">
                                            <p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr class="d-none d-md-table-row">
                                    <td></td>
                                    <td></td>
                                    <td class="td-scale">
                                        <p-chart type="horizontalBar" [options]="barOptionsFooter" [data]="dataFooter" height="30px"></p-chart>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </p-accordionTab>

        <p-accordionTab *ngIf="!showDMI" header="Utbildningsbehov">
            <dd-type-multiple #multiComp [question]="devNeeds.groups[0].questions[0]" [sessionAswers]="devNeeds.answers.results"></dd-type-multiple>
            <button (click)="saveDevNeeds(multiComp.getAnswers())" icon="pi pi-save" iconPos="right" label="Spara utbildningsbehov" pButton type="button" class="w-100 mt-4 mb-2"></button>
        </p-accordionTab>

        <p-accordionTab *ngIf="!showDMI" header="Utbildningsbehov - topp 5">
            <ng-container *ngIf="!isLoading">
                <dd-type-prioritizeanswers #prioComp [question]="devNeeds.groups[0].questions[1]" [sessionAswers]="devNeeds.answers.results" [summary]="false"></dd-type-prioritizeanswers>
                <button (click)="savePrioNeeds(prioComp.getAnswers())" icon="pi pi-save" iconPos="right" label="Spara topp 5" pButton type="button" class="w-100 mt-2 mb-2"></button>
            </ng-container>
        </p-accordionTab>

        <p-accordionTab *ngIf="!showDMI" header="Sammanfattning">
            <div *ngFor="let question of summary.groups[0].questions" class="mb-2 mt-2">

                <div class="row">
                    <div class="col-12 mb-2 pl-4">
                        <h6>{{question.question}}</h6>
                        <div *ngIf="question.description != ''" [innerHTML]="question.description" class="pt-2"></div>
                    </div>
                </div>

                <!--<dd-type-input [question]="question" [sessionAswers]="summary.answers.results"></dd-type-input>-->
                <textarea [rows]="1" [cols]="30" pInputTextarea autoResize="autoResize" [(ngModel)]="summary.answers.results[question.questionID]" name="question_{{question.questionID}}" class="w-100 bg-white mx-2"></textarea>

            </div>

            <button (click)="saveSummary()" icon="pi pi-save" iconPos="right" label="Spara sammanfattning" pButton type="button" class="w-100 mt-2 mb-2"></button>
        </p-accordionTab>

    </p-accordion>
</div>
