import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ShellService } from '@app/shell/shell.service';
import { NotFoundMessageInterface } from '@shared/interfaces/not-found';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'dd-not-found',
	templateUrl: './not-found.component.html',
	styles: []
})
export class NotFoundComponent {

	title: string = '';
	message: string = '';
	icon: string = '';
	show: boolean = false;

	routerSub: Subscription;

	constructor(private shellService: ShellService, private router: Router,) {
		this.show = false;

		this.shellService.showNotFoundComp.subscribe((data: NotFoundMessageInterface) => {
			this.title = data.title
			this.message = data.message
			this.icon = data.icon

			if(this.icon == "" || !this.icon)
				this.icon = 'pi pi-exclamation-circle';

			this.show = true;

			this.routerSub = router.events.subscribe((val) => {
				if (val instanceof NavigationStart) {
					this.show = false;
					this.routerSub.unsubscribe();
				}
			});
		});


	}

}
