import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalysisService } from '@shared/services/analysis.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
	selector: 'dd-base-question',
	template: '',
	styles: []
})
export class BaseQuestionComponent implements OnInit, OnDestroy {
	@Input() question: any;
	@Input() typeData: any;
	@Input() sessionAswers: any;


	// Behövs denna ? ._valid sätts på frågan istället (just nu)
	@Output() validatedQuestion = new EventEmitter<boolean>();

	constructor(public analysisService: AnalysisService) {
	}

	addSub(sub: Subscription)
	{
		this.analysisService.subscriptions.push(sub);
	}

	ngOnInit(): void {
		this.typeData = this.question.type_data;
	}

	findAnswer(questionID:number)
	{
		if(this.sessionAswers && this.sessionAswers[questionID]) {
			return this.sessionAswers[questionID];
		} else
			return [];
	}

	setValidQuestion(state:boolean) {
		this.question._valid = state;
	}

	_questionObject(questionID: number, value: any) {
		return {
			'p46_ca_answer': { [questionID] : value}
		};
	}

	ngOnDestroy() {
		//console.error('ngOnDestroy baseQ');
		this.analysisService.subscriptions.forEach((subscription) => subscription.unsubscribe())
	}
}
