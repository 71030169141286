import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FadeIn } from '@shared/animations/animations';
import { CourseEventInterface, CourseInterface } from '@shared/interfaces/course';
import { CompaniesService } from '@shared/services/companies.service';
import { CoursesService } from '@shared/services/courses.service';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
	selector: 'dd-book-course-dialog',
	templateUrl: './book-course.dialog.html',
	styles: [],
	animations: [FadeIn]
})
export class BookCourseDialog implements OnInit, OnDestroy {
	display: any = false;
	@Input() event: CourseEventInterface;
	@Input() course: CourseInterface;
	listEmployees: any = [];
	public isLoading: boolean;
	selectedUsers: any[] = [];
	statusLoaded = false;

	_openBookingSubscription: Subscription;
	_getEmployeesSubscription: Subscription;

	constructor(public coursesService: CoursesService, public companiesService: CompaniesService) {
	}

	ngOnDestroy() {
		console.error('openBookingDialog desroy')
		this._openBookingSubscription.unsubscribe();

		if(this._getEmployeesSubscription)
			this._getEmployeesSubscription.unsubscribe();
	}

	async ngOnInit() {
console.error('BookCourseDialog init');

		this._openBookingSubscription = this.coursesService.openBookingDialog.subscribe(async (data: any) => {

			console.error('BookCourseDialog openBookingDialog', data);
			this.selectedUsers = [];
			this.event = data.event;
			this.course = data.course;
			this.isLoading = true;
			this.statusLoaded = false;
			this.display = true;

			//await this.companiesService.fetchEmployees();

			this._getEmployeesSubscription = await this.companiesService.employees.pipe(take(2)).subscribe((async result => {
				console.error('employee ', result);
				if (result && result.length) {
					this.isLoading = false;
					this.listEmployees = result;

					this.listEmployees.map((e: any) => {
						e.subscription = null;
					});
					this.coursesService.getSubmissionStatus(this.event.EventId, this.listEmployees).then((status: any) => {
						status.forEach((s: any) => {
							let findUser = this.listEmployees.find((u: any) => {
								return u.PersonId == s.PersonId;
							});
							if (findUser && !s.Canceled) {
								findUser.subscription = s;
							}
						});
						this.statusLoaded = true;

					}).finally(() => this.isLoading = false);

				} else {
					this.isLoading = false;

					this.companiesService.fetchEmployees();
				}
			}));

		});


	}

	async sendSubmission() {
		if (this.selectedUsers.length > 0) {
			this.isLoading = true;

			//this.selectedUsers.forEach(async (userID:number) => {
			let userID = this.selectedUsers[0];
			if (userID) {
				await this.coursesService.sendSubmission(userID, this.event).then((result:any) => {
					console.error(result);
					this.selectedUsers.splice(0,1);

					let findUser = this.listEmployees.find((u: any) => {
						return u.PersonId == userID;
					});

					if(!result.error) {
						findUser.subscription = true;
					} else if(result?.constant == 'personAlreadyBooked') {
						findUser.subscription = true;
					}

					if(this.selectedUsers.length > 0)
						this.sendSubmission();
					else {
						this.isLoading = false;
						//this.coursesService.openBookingDialog.emit({event: this.event, course: this.event});
					}
					/*this.isLoading = false;
					this.selectedUsers = [];
					this.coursesService.openBookingDialog.emit({event: this.event, course: this.event});*/
				});
			} else {
				this.isLoading = false;
				//this.coursesService.openBookingDialog.emit({event: this.event, course: this.event});
			}
			//});
		}
	}
}
