import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatSsnr'
})
export class FormatSsnrPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		if(!value)
			return '';

		if(value.includes('-')) {
			//return value;
			return value.substr(0, 8)+'-xxxx';
		}

		//return value.substr(0, 6)+'-'+value.substr(6);
		return value.substr(0, 8)+'-xxxx';
	}

}
