import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { MessageService } from "primeng/api";
import { Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

	constructor(public msgService: MessageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			retry(1),
			tap(evt => {
				if (evt instanceof HttpResponse) {
					if(evt.body && evt.body.toastMessage && evt.body.toastMessage.canIgnore == false) {
						setTimeout(() => {
							this.createNotice(evt.body.toastMessage.noticeResponse);
						}, 300);
					}
				}
			}),
			catchError((error) => this.errorHandler(error)),
		);
	}

	public createNotice(response: any) {

		if(typeof response.title == "object")
			response.title = response.title.msg;

		if (response.html && false) {
			let html = `
                <div>
                    <div class="sn-title">${response.title}</div>
                    <div class="sn-content">${response.content}</div>          
                </div>`;
			//<div class="icon">${this.msgService.icons.success}</div>
			//this.msgService.html(html, response.type);
		} else {
			const ttl = 5000;
			switch (response.type) {
				case 'success':
					this.msgService.add({severity:'success', summary: response.title, detail: response.content, life: ttl});
					break;
				case 'info':
					this.msgService.add({severity:'info', summary: response.title, detail: response.content, life: ttl});
					break;
				case 'warn':
					this.msgService.add({severity:'warn', summary: response.title, detail: response.content, life: ttl*3});
					break;
				case 'alert':
					this.msgService.add({severity:'alert', summary: response.title, detail: response.content, life: ttl});
					break;
				case 'error':
					this.msgService.add({severity:'error', summary: response.title, detail: response.content, life: ttl*5});
					break;
			}
		}
	}

	// Customize the default error handler here if needed
	private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
		if (!environment.production) {
			// Do something with the error
			console.error('Request error', response);
		}
		throw response;
	}
}
