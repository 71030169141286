<div *ngIf="user" class="row d-flex justify-content-center h-75" @FadeIn>
    <div [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}" class="rounded mw-50 align-self-center">
        <div class="row mb-4">
            <div *ngIf="!inModal" class="col">
                <h1 class="text-center mb-4">Anpassningar</h1>
                <p class="text-center">
                    Finns det något hos dig som innebär att vi behöver anpassa vår lokal, information eller utbildning?
                </p>
            </div>
        </div>
        <form #form="ngForm" (ngSubmit)="submitStep(form)" action="" method="post">
            <div [class.pb-5]="!inModal" class="row justify-content-center">
                <div class="col px-0 justify-content-center" style="max-width: 450px">
                    <div *ngIf="inModal" class="col-12">
                        <p class="font-weight-bold">Finns det något hos dig som innebär att vi behöver anpassa vår lokal, information eller utbildning?</p>
                    </div>
                    <div class="col-12">
                        <p class="infotext">Kryssa i ett eller flera alternativ</p>
                    </div>
                    <div class="col-12" *ngFor="let option of options;let i = index">
                        <div class="p-field-checkbox">
                            <p-checkbox
                                    (onChange)="evaluateValues($event)"
                                    [(ngModel)]="selectedValues"
                                    [inputId]="'checkOption'+i"
                                    name="disabilitydetails"
                                    [value]="option"
                            ></p-checkbox>
                            <label [for]="'checkOption'+i">{{option}}</label>
                        </div>
                    </div>
                    <div class="col-12 pt-4" *ngIf="showOther">
                        <span class="p-float-label mb-4">
                            <input [(ngModel)]="other" name="other" class="w-100 bg-white" pInputText type="text" required=""/>
                            <label for="">Om annat, skriv gärna här</label>
                        </span>
                    </div>
                    <div class="col-12">
                        <p class="infotext">
                            Om du har kryssat i Ja på någon fråga så kan vi behöva kontakta dig för att få veta mer om hur vi skulle
                            kunna möta upp dina behov och hjälpa till på bästa sätt.
                        </p>
                    </div>
                    <div class="row justify-content-center pb-5" [class.pt-5]="!inModal">
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" label="Spara & gå vidare" [disabled]="!form.valid || isLoading || !selectedValues || selectedValues?.length == 0" *ngIf="!inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" [label]="'Spara'" [disabled]="!form.valid || isLoading || !selectedValues || selectedValues?.length == 0" *ngIf="inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
