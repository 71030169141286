
<div @FadeIn class="d-flex align-items-center p-justify-center" style="height: 80vh">
    <p-card  [style]="{width: '600px',height:'auto', 'max-width': '90vw'}" styleClass="ui-card-shadow d-flex align-items-center">
        <div class="p-text-center">
            <h1 class="text-primary" style="font-size: 400% !important;">
                <i class="fas fa-check-double"></i>
            </h1>
            <h2 class="text-primary mb-3">
                Nu är du klar.
            </h2>
            <h5 class="mb-4">Tack för att du hjälper oss att bli bättre!</h5>
        </div>
    </p-card>
</div>
