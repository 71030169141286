export const clientConfig = {
	appTitle: 'Affärskompetens Sverige',
	appName: 'AKSE',
	logoAlt: 'Affärskompetens Sverige Logga',
	logoUrl: 'https://portal.affarskompetens.se/wp-content/uploads/2021/08/logo-white-transp_small-min.png',
	phone: '010-641 01 10',
	email: 'support@affarskompetens.se',
	settings: {

	},
	eduadmin: {
		fields: {
			accessRoleA: {
				id: 7680,
				title: 'Behörighet A',
				subTitle: 'Fullständig behörighet.',
				desc: `
Kan boka och avboka kurser till sig själv och till övriga medarbetare i företaget. Ser även vilka kurser medarbetare har bokat, avbokat och genomfört.<br />
Endast företagsledare eller av denne vald kontaktperson ska ges denna behörighet vid inbjudan.				
				`
			},
			accessRoleB: {
				id: 7681,
				title: 'Behörighet B',
				subTitle: 'Begränsad behörighet.',
				desc: `
Behörighet att själv boka och avboka kurser. Ser endast sina egna bokade, avbokade och genomförda kurser.<br /> 
Alla medarbetare ska ges denna behörighet.				
				`
			},
			personAccessPositionList: [
				{'label': 'VD/Högst ansvarig', value: 7746},
				{'label': 'Ledare/Chef', value: 7747},
				{'label': 'Medarbetare', value: 7748},
			],
			foodPref: {
				metatype: 'foodPref',
				id: 6561
			}
		}
	},

	projects: [
		{
			constant: 'perfekt',
			title: 'Perfekt',
			descSmall: 'Korta och konkreta kurser'
		},
		{
			constant: 'reform',
			title: 'Reform',
			descSmall: 'Effektiva team'
		}
	],

	courseTypeBadge: [

		/**
		 * Bara reform
		 */
		{
			type: 'Webinar Reform', color: '#55AF92',
			title: 'Webinar',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Utforska ämnen inom ledarskap, HR och hållbarhet. Var med live eller titta på inspelningen i efterhand.
			`,
			descFull: `
<p>✔ 1 timme l&aring;ngt, g&aring;r p&aring; olika tider under dagen och spelas alltid in.<br />✔ Du m&aring;ste anm&auml;la dig innan f&ouml;r att vara med live eller se i efterhand.<br />✔ Om du inte &auml;r med live, men &auml;r anm&auml;ld, f&aring;r du automatiskt ett mejl med en l&auml;nk till det inspelade webinaret. Du startar det genom att klicka p&aring; l&auml;nken eller inne i Portalen under &rdquo;Bokade aktiviteter&rdquo;<br />✔ Du som ser i efterhand har tillg&aring;ng till webinaret i 3 dagar (72 timmar) efter du har f&aring;tt l&auml;nken, sedan &auml;r den inte giltig l&auml;ngre. <br />✔ Du n&auml;rvarosignerar (ett krav fr&aring;n ESF) efter du har varit med live eller n&auml;r du har sett det inspelade webinaret.</p>
`
		},
		{
			type: 'Behovskartläggning', color: '#E9A752',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsFilter: false,
			descSmall: `
			Har ditt företag fyra eller fler anställda då har ni möjlighet att göra en behovskartläggning.
			`,
			descFull: `
<p>F&ouml;r dig med fyra eller fler anst&auml;llda</p>
<p>Vill du g&ouml;ra en behovskartl&auml;ggning? Den kan hj&auml;lpa dig som st&aring;r inf&ouml;r att g&ouml;ra f&ouml;r&auml;ndringar i verksamheten, men &auml;r os&auml;ker p&aring; hur du ska g&aring; vidare.</p>
<p><strong>Det h&auml;r g&ouml;r vi tillsammans</strong></p>
<p>✓ Nul&auml;get i verksamheten<br />
✓ Inventerar den befintliga kompetensen<br />
✓ Jobbar fram en handlingsplan<br />
✓ Kurser/webinar inom omv&auml;rlds-bevakning/analys, ledarskap och HR<br />
✓ Uppf&ouml;ljning och coaching</p>
<p>F&ouml;r mer information mejla <a title="mailto:sofia.frogren@affarskompetens.se" href="mailto:sofia.frogren@affarskompetens.se" target="_blank" rel="noreferrer noopener" aria-label="Link sofia.frogren@affarskompetens.se">sofia.frogren@affarskompetens.se</a></p>
`
		},
		/* /bara reform */


		{
			type: 'Webinar (inspelat)', color: '#55AF92',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsFilter: false,
			descSmall: ``,
			descFull: ``,
		},
		{
			type: 'Webinar', color: '#55AF92',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Dyk in i en timmes kunskapsäventyr! Utforska aktuella ämnen med experter live, eller titta på inspelningen i efterhand. Flexibelt lärande väntar på dig!
			`,
			descFull: `
<p>✔ 1 timme l&aring;ngt, g&aring;r p&aring; olika tider under dagen och spelas alltid in.<br />✔ Du m&aring;ste anm&auml;la dig innan f&ouml;r att vara med live eller se i efterhand.<br />✔ Om du inte &auml;r med live, men &auml;r anm&auml;ld, f&aring;r du automatiskt ett mejl med en l&auml;nk till det inspelade webinaret. Du startar det genom att klicka p&aring; l&auml;nken eller inne i Portalen under &rdquo;Bokade aktiviteter&rdquo;<br />✔ Du som ser i efterhand har tillg&aring;ng till webinaret i 3 dagar (72 timmar) efter du har f&aring;tt l&auml;nken, sedan &auml;r den inte giltig l&auml;ngre. <br />✔ Du n&auml;rvarosignerar (ett krav fr&aring;n ESF) efter du har varit med live eller n&auml;r du har sett det inspelade webinaret.</p>
`
		},
		{
			type: 'Livekurs', color: '#E9A752',
			canCancelTimeBefore: {time: 72, unit: 'hour'},
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Väck din nyfikenhet och låt den blomstra. Med våra lärarledda kurser utforskar du en mångfald av ämnen. Under tre engagerande timmar får du kunskapspåfyllning direkt från experterna.		
			`,
			descFull: `
<p>✔ 3 timmar l&aring;ng.<br />✔ Du kan endast vara med live och anm&auml;lan kr&auml;vs alltid (spelas inte in).<br />✔ Du n&auml;rvarosignerar (ett krav fr&aring;n ESF) efter att du har varit med p&aring; kursen. </p>
`
		},
		{
			type: 'E-learning', color: '#EE2763',
			canCancelTimeBefore: true, // ska gå fram till slutdatum för kursen!
			canCancelDuring: true,
			showAsFilter: true,
			descSmall: `
				Mästra nya färdigheter på dina villkor med vår flexibla e-learning! Utforska kurser helt i din egen takt, när och var du vill. Starta din kunskapsresa nu!	
			`,
			descFull: `
<p>✔ Du bokar dig p&aring; kursen. <br />✔ Du f&aring;r en bokningsbekr&auml;ftelse med l&auml;nk till kursen och kan d&aring; starta den direkt. Du kan &auml;ven starta kursen inne i Portalen under &rdquo;Bokade aktiviteter&rdquo;.<br />✔ Du har tillg&aring;ng till kursen 3 dagar (72 timmar) fr&aring;n bokningstillf&auml;llet sedan g&aring;r inte kursen att starta.</p>
`
		},
		{
			type: 'Inspelad föreläsning', color: '#7C2BE8',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Lyssna och lär! Ge dig själv och företaget ny energi genom våra inspirerande föreläsningar. Få expertråd och insikter för att nå oanade höjder.		
			`,
			descFull: `<p>✔ Planering av dessa p&aring;g&aring;r &ndash; mer information kommer&nbsp;</p>`
		},
		{
			type: 'Möten', color: '#7C2BE8',
			canCancelTimeBefore: false,
			canCancelDuring: false,
			showAsActivities: false,
			showAsFilter: false,
			descSmall: ``,
			descFull: ``
		},
		{
			type: 'Liveföreläsning', color: '#7C2BE8',
			canCancelTimeBefore: {time: 72, unit: 'hour'},
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Lyssna och lär! Ge dig själv och företaget ny energi genom våra inspirerande föreläsningar. Få expertråd och insikter för att nå oanade höjder.		
			`,
			descFull: `<p>✔ Planering av dessa p&aring;g&aring;r &ndash; mer information kommer&nbsp;</p>`
		},
		{
			type: 'Inspelad kurs', color: '#4C6E94',
			canCancelTimeBefore: true, // oklart när det ska gå
			canCancelDuring: true,
			showAsFilter: true,
			descSmall: `
				Fånga ögonblicket att lära och växa med våra inspelade kurser! Du får massor av konkreta tips och verktyg och du gör dem när det passar dig som bäst!
			`,
			descFull: `
<p>✔ Du bokar dig p&aring; kursen. <br />✔ Du f&aring;r en bokningsbekr&auml;ftelse med l&auml;nk till kursen och kan d&aring; starta den direkt. Du kan &auml;ven starta kursen inne i Portalen under &rdquo;Bokade aktiviteter&rdquo;.<br />✔ Du har tillg&aring;ng till kursen 3 dagar (72 timmar) fr&aring;n bokningstillf&auml;llet sedan g&aring;r inte kursen att starta.</p>
`
		},
		{
			type: 'Workshop', color: '#026254',
			canCancelTimeBefore: {time: 72, unit: 'hour'},
			canCancelDuring: false,
			showAsFilter: true,
			descSmall: `
				Låt lärandet komma till liv! Bygg vidare på det du lärt dig i de inspelade kurserna. Förvandla kunskap till skicklighet i våra workshops som leds av erfarna kursledare.
			`,
			descFull: `
<p>✔ 3 timmar l&aring;ng.<br />✔ Du kan endast delta i en workshop efter du har genomf&ouml;rt den inspelade kursen som workshopen bygger vidare p&aring;. <br />✔ Du kan endast vara med live och anm&auml;lan kr&auml;vs alltid. <br />✔ Vi &ouml;ppnar anm&auml;lan till v&aring;ra workshops 3 m&aring;nader i taget. Detta f&ouml;r att &auml;ven de som kommer in senare i projektet ska ha m&ouml;jlighet att f&aring; vara med live. <br />✔ Du n&auml;rvarosignerar (ett krav fr&aring;n ESF) efter du har varit med p&aring; en workshop.</p>			
`
		}
	]
};
