<div class="row d-flex justify-content-center h-75" @FadeIn>
    <div class="rounded mw-50 align-self-center" [ngClass]="{'col-10 col-xl-3 pb-5 align-self-center': !inModal, 'col-12 align-self-top': inModal}">
        <div class="row mb-2">
            <div class="col" *ngIf="!inModal">
                <h1 class="text-center mb-2">Anpassningar & kostpreferenser</h1>
            </div>
        </div>
        <form action="" method="post" (ngSubmit)="submitStep(form)" #form="ngForm">
            <div class="row justify-content-center" [class.pb-5]="!inModal">
                <div class="col px-0 justify-content-center" style="max-width: 350px">

                    <div class="col-12">
                        <p class="infotext">Det kan komma aktiviteter då det erbjuds mat/fika. Har du kostpreferenser skriver du i dessa här</p>
                    </div>

                    <span class="p-float-label mb-4">
                        <input [(ngModel)]="user.foodPref" name="other" class="w-100 bg-white" pInputText type="text"/>
                        <label for="">Ange kostpreferenser</label>
                    </span>

                    <div class="col-12">
                        <p class="infotext">Är du i behov av särskilt stöd eller anpassning för att gå kurs hos oss? (om ja kommer vi att kontakta dig separat)</p>
                    </div>

                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton name="disabilities" [value]="true" [(ngModel)]="user.disabilities" inputId="ingredient2" required></p-radioButton>
                            <label for="ingredient2" class="ml-2">Ja</label>
                        </div>

                        <div class="flex align-items-center">
                            <p-radioButton name="disabilities" [value]="false" [(ngModel)]="user.disabilities" inputId="ingredient1" required></p-radioButton>
                            <label for="ingredient1" class="ml-2">Nej</label>
                        </div>
                    </div>

                    <div class="row justify-content-center pb-5" [class.pt-5]="!inModal">
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" label="Spara & gå vidare" [disabled]="!form.valid || isLoading" *ngIf="!inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                        <button class="w-100 mx-3 p-button-lg btn-fw-700" pButton type="submit" [label]="'Spara'" [disabled]="!form.valid || isLoading" *ngIf="inModal">
                            <i class="fas fa-cog fa-spin position-absolute" *ngIf="isLoading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
