<ng-container *ngFor="let t of loadType">
    <ng-container *ngIf="t">
        <span class="course-type-tag align-items-center" [class.minimal]="minimal" *ngIf="t" (click)="visible=true;alert(1);" [class.cursor-pointer]="!minimal&&showReadMore">
            <span class="badge badge-pill" [style]="{'background-color': t.color}">&nbsp;</span>
            <ng-container *ngIf="t.title">{{t.title}}</ng-container>
            <ng-container *ngIf="!t.title">{{t.type}}</ng-container>
        </span>

        <div class="d-flex" *ngIf="showDesc">
            <div class="text-sm mt-3 cursor-pointer" (click)="visible=true">
                {{t.descSmall}}
                <span *ngIf="showReadMore" class="font-italic font-bold pl-1">
                    Läs mer <i class="fa-solid fa-arrow-right"></i>
                </span>
            </div>
        </div>

        <ng-container *ngIf="showReadMore">
            <p-dialog [(visible)]="visible" [style]="{width: '600px'}" [modal]="true" [dismissableMask]="true" appendTo="body">
                <ng-template pTemplate="header">
                    <span>
                        <span class="badge badge-pill badge-courstype mr-1" [style]="{'background-color': t.color}">&nbsp;</span>
                        <span class="font-lg font-bold">
                            <ng-container *ngIf="t.title">{{t.title}}</ng-container>
                            <ng-container *ngIf="!t.title">{{t.type}}</ng-container>
                        </span>
                    </span>
                </ng-template>
                <div [innerHTML]="t.descFull" class="tag-desc-full text-left text-sm"></div>
            </p-dialog>
        </ng-container>

    </ng-container>
</ng-container>

