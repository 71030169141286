import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth';
import { FadeIn } from "@shared/animations/animations";
import { CustomMenuItem } from '@shared/interfaces/user-interface';
import { CompaniesService } from '@shared/services/companies.service';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'dd-user-stepwizard',
	templateUrl: './user-stepwizard.component.html',
	styles: [],
	animations: [FadeIn],
})
export class UserStepwizardComponent implements OnInit {
	items: CustomMenuItem[] = [];
	activeIndex: number = 0;
	started: boolean = false;
	finished: boolean = false;
	hasPayedFee: boolean = false;
	private storageKey = 'userWizardCurrentStep';

	constructor(private router: Router, public companiesService: CompaniesService, private authService: AuthenticationService) {
	}

	finalize() {
		this.companiesService.finalizeUserWizard();
		this.finished = true;
		window.scroll(0, 0);
		//this.router.navigateByUrl(`/`);

		if(this.companiesService.isLeader())
			this.companiesService.fetchEmployees();
	}

	start()
	{
		this.started = !this.started;
		localStorage.setItem(this.storageKey, '0');
	}

	nextStep(nextIndex: number = 0) {
		let findCurrentIndex = this.findWithIndex(this.activeIndex);

		if(this.findWithIndex(nextIndex) == -1 && !this.items[findCurrentIndex+1]) {
			this.finalize();
			return;
		}
		this.activeIndex = this.items[findCurrentIndex+1].index;

		localStorage.setItem(this.storageKey, this.activeIndex.toString());
		window.scroll(0, 0);
	}

	findWithIndex(index: number) {
		return this.items.findIndex((i) => i.index == index);
	}

	realActiveIndex() {
		return this.items.findIndex((i) => i.index == this.activeIndex);
	}

	async gotoHome() {
		await this.router.navigate([this.authService.projectSettings['startPage']]);
		return;
	}

	ngOnInit(): void {

		let getStorage = localStorage.getItem(this.storageKey);

		if(getStorage == null) {
			localStorage.setItem(this.storageKey, '-1');
		} else {
			let index  = parseInt(getStorage);
			if(index > -1)
				this.started = true;

			if(index > 0)
				this.activeIndex = index;
		}

		this.items = [];
		let nextIndex = 0;

		this.companiesService.fetchCompany().then((c) => {
			this.hasPayedFee = c['payed_fee'] == "1";
		}).then(() => {
			if ((!this.companiesService.isLeader() || this.hasPayedFee) && this.authService.projectSettings['requireChangePassword'] == true) {
				this.items.push({label: 'Lösenord', index: nextIndex, command: (event: any) => {this.activeIndex = nextIndex}});
			}
			nextIndex++;

			if(this.authService.projectSettings['requireVeryfyUserinfo'] == true)
				this.items.push({label: 'Personuppgifter', index: nextIndex, command: (event: any) => {this.activeIndex = nextIndex}});

			nextIndex++;

			if(this.authService.projectSettings['requireAllergies'] == true)
				this.items.push({label: 'Kostpreferenser', index: nextIndex, command: (event: any) => {this.activeIndex = nextIndex}});

			nextIndex++;

			if(this.authService.projectSettings['requireDisabilities'] == true)
				this.items.push({label: 'Anpassningar', index: nextIndex, command: (event: any) => {this.activeIndex = nextIndex}});

			nextIndex++;

			this.items.push({label: 'Villkor', index: nextIndex, command: (event: any) => {this.activeIndex = nextIndex}});

			console.error(this.items, this.activeIndex, this.items[0].index);

			if(this.activeIndex == 0)
				this.activeIndex = this.items[0].index;

			nextIndex++;
		});
	}
}
