<!-- går inte att öppna programaticly when multiple
<div class="text-right mb-2">
    <button pButton type="button" icon="pi pi-plus" class="p-1" (click)="openAll()"></button>
    <button pButton type="button" icon="pi pi-minus" class="p-1" (click)="closeAll()"></button>
</div>
-->

<p-accordion [multiple]="true" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
    <ng-container *ngFor="let top of values">
    <p-accordionTab [header]="top.label" *ngIf="top.children.length > 0" [selected]="top?.hasSubSelection">
        <div class="p-grid p-justify-start">
            <div class="mr-2 mb-2 text-sm checkbox-holder" *ngFor="let child of top.children">
                <p-checkbox name="groupname" value="{{top.label}}:{{child}}" [label]="child" [(ngModel)]="selectedValues" (onChange)="validate()" [inputId]="child" ></p-checkbox>
            </div>

            <div class="p-col-12 mt-3">
                <span class="p-float-label p-float-label-sm">
                    <input [ngModel]="selectedFreetext[top.label]" name="freeText_{{top.label}}"  class="w-100 bg-white" (ngModelChange)="addFreeText($event, top.label)" pInputText type="text" />
                    <label for="">Annat önskemål på utbildning</label>
                </span>
            </div>
        </div>
    </p-accordionTab>
    </ng-container>
</p-accordion>
