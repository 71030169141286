
<div class="container">
    <div class="row justify-content-center align-items-center mb-3">
        <div class="col-lg-12 col-xl-10 m2 b0 text-center rounded">
            <h3 class="my-3">Frågor & svar</h3>
        </div>
    </div>

    <p-accordion styleClass="faq-page bg-transparent">
        <p-accordionTab [header]="group.heading" contentStyleClass="border-0 bg-transparent py-0 mb-3" headerStyleClass="border-round-md bg-white text-lg mb-2"  *ngFor="let group of groups">
            <p-accordion styleClass="bg-transparent">
                <p-accordionTab [header]="item.heading" headerStyleClass="bg-transparent border-0 text-md" contentStyleClass="border-0 bg-transparent py-0" *ngFor="let item of group.items">
                    <div class="border-round-md px-3 pt-3 bg-white shadow-sm mb-3 d-inline-block" style="max-width:800px;" [innerHTML]="item.content"></div>
                </p-accordionTab>
            </p-accordion>
        </p-accordionTab>
    </p-accordion>

</div>
